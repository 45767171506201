;(function () {
  'use strict'

  function onDocumentReady() {
    const contactPhoneNumbers = document.querySelectorAll('.cmp-contact__phone')
    const contactEmails = document.querySelectorAll('.cmp-contact__email')

    for (const contactPhone of contactPhoneNumbers) {
      contactPhone.addEventListener('click', function () {
        window.adobeDataLayer.push({
          event: 'cmp:click',
          eventInfo: {
            reference: 'contact',
            requestType: 'Clicked phone number',
          },
        })
      })
    }

    for (const contactEmail of contactEmails) {
      contactEmail.addEventListener('click', function () {
        window.adobeDataLayer?.push({
          event: 'cmp:click',
          eventInfo: {
            reference: 'contact',
            requestType: 'Clicked mail to',
          },
        })
      })
    }
  }

  if (document.readyState !== 'loading') {
    onDocumentReady()
  } else {
    document.addEventListener('DOMContentLoaded', onDocumentReady)
  }
})()
