;(function () {
  'use strict'

  function onDocumentReady() {
    const downloadListItems = document.querySelectorAll('.cmp-download-list__action-label')
    const downloadLanguagesListItems = document.querySelectorAll('.cmp-download-list__action-language-link')

    for (const downloadListItem of downloadListItems) {
      downloadListItem.addEventListener('click', function () {
        window.adobeDataLayer?.push({
          event: 'download file',
          eventInfo: {
            reference: 'download list item',
            fileName: this.getAttribute('href').split('/').pop(),
          },
        })
      })
    }

    for (const downloadLLanguagesListItem of downloadLanguagesListItems) {
      downloadLLanguagesListItem.addEventListener('click', function () {
        window.adobeDataLayer?.push({
          event: 'download file',
          eventInfo: {
            reference: 'download languages list item',
            fileName: this.getAttribute('href').split('/').pop(),
          },
        })
      })
    }
  }

  if (document.readyState !== 'loading') {
    onDocumentReady()
  } else {
    document.addEventListener('DOMContentLoaded', onDocumentReady)
  }
})()
