import React from 'react'
import Select, { GroupBase, OptionsOrGroups } from 'react-select'

interface DropdownProperties {
  options: OptionsOrGroups<any, GroupBase<any>>
  value: { value: string; label: string } | null
  placeholder: string
  label: string
  name: string
  isClearable?: boolean
  onChange: (newValue: { value: string; label: string }) => void
}

const Dropdown: React.FC<DropdownProperties> = ({
  options,
  value,
  onChange,
  placeholder,
  label,
  name,
  isClearable = false,
}) => {
  return (
    <div className="cmp-dropdown">
      <label className="cmp-dropdown__label">{label}</label>
      <Select
        className="cmp-dropdown__select"
        classNamePrefix="cmp-dropdown"
        isClearable={isClearable}
        isSearchable={true}
        name={name}
        options={options}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
      />
    </div>
  )
}

export default Dropdown
