import React from 'react'
import sanitizeHtml from 'sanitize-html'
interface FileUploadInstructionProperties {
  instructionText: string
}

const FileUploadInstruction: React.FC<FileUploadInstructionProperties> = ({
  instructionText,
}: FileUploadInstructionProperties): JSX.Element => {
  return (
    <div
      className="cmp-file-upload-instruction"
      dangerouslySetInnerHTML={{
        __html: sanitizeHtml(instructionText, {
          allowedTags: ['ul', 'li', 'p'],
        }),
      }}
    ></div>
  )
}

export default FileUploadInstruction
