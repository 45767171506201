/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { NavigationSectionModel } from '../Navigation.d.ts'
import NavigationAccordionContent from '../NavigationAccordion/NavigationAccordionContent'
import NavigationAccordionIcon from '../NavigationAccordion/NavigationAccordionIcon'

interface NavigationItemProperties {
  navigationItem: NavigationSectionModel
  open: boolean
  onToggle: (clickedAccordionKey: string) => void
  sectionId: null | string
}

const NavigationSection: React.FC<NavigationItemProperties> = ({
  navigationItem,
  open,
  onToggle,
  sectionId,
}: NavigationItemProperties) => {
  function handleTitleKeypress(event: React.KeyboardEvent<HTMLElement>, clickedAccordionKey: string) {
    if (event.key === 'Enter') {
      onToggle(clickedAccordionKey)
    }
  }

  return (
    <>
      {navigationItem.sectionLabel ? (
        <p className="cmp-navigation__section-title">{navigationItem.sectionLabel}</p>
      ) : null}
      <div className="cmp-navigation__section">
        <h2 className="cmp-navigation__division-title">
          {navigationItem.onePagerLink ? (
            <a className="cmp-navigation__title" href={navigationItem.onePagerLink.externalizedURL}>
              {navigationItem.divisionPageLabel}
              <NavigationAccordionIcon open={open} useArrow={true} />
            </a>
          ) : (
            <span
              onClick={() => onToggle(navigationItem)}
              onKeyPress={(event) => handleTitleKeypress(event, navigationItem.id)}
              role="button"
              tabIndex={0}
              className={`cmp-navigation__title ${open ? 'cmp-navigation__title--is-opened ' : ''}`}
            >
              {navigationItem.divisionPageLabel}
              <NavigationAccordionIcon open={open} useArrow={false} />
            </span>
          )}
        </h2>
        {navigationItem.navigationSubLinks ? (
          <NavigationAccordionContent open={open} items={navigationItem.navigationSubLinks} sectionId={sectionId} />
        ) : null}
      </div>
    </>
  )
}

export default NavigationSection
