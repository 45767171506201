import React from 'react'
import ResponsiveImage from '@components/ResponsiveImage'

import { ColorVariant } from '../ProductExplorerTypes'

interface ProductListItemProperties {
  title: string
  ariaLabel?: string
  link: string
  image: string
  colors: ColorVariant[]
  missingImageAltText?: string
  onClick?: () => void
  onFocus?: () => void
  hasMultipleVariants?: boolean
  productVariantLabel?: string
}

const ProductListItem: React.FC<ProductListItemProperties> = ({
  title,
  link,
  image,
  colors,
  ariaLabel,
  missingImageAltText,
  onClick,
  onFocus,
  hasMultipleVariants,
  productVariantLabel,
}: ProductListItemProperties): JSX.Element => {
  return (
    <li className="cmp-product-list-item" role="presentation" onClick={onClick} onFocus={onFocus}>
      <a aria-label={ariaLabel || title} href={link}>
        <div className="cmp-product-list-item__image">
          <ResponsiveImage
            alt={image ? title : missingImageAltText || ''}
            src={
              image
                ? image
                : '/etc.clientlibs/franke-aem/clientlibs/clientlib-site/resources/images/placeholder_alta.png'
            }
          />
        </div>

        {hasMultipleVariants ? (
          <div className="cmp-product-list-item__variant">
            <i className="ri-information-line"></i>
            <span className="cmp-product-list-item__label">{productVariantLabel}</span>
          </div>
        ) : (
          <div className="cmp-product-list-item__variant cmp-product-list-item__variant--is-hidden">
            <i className="ri-information-line"></i>
          </div>
        )}

        <ul className="cmp-product-list-item__colors-list">
          {colors.map((color, index) => (
            <li key={color.name} className="cmp-product-list-item__color" data-index={index}>
              <ResponsiveImage alt={color.name} src={color.imageUrl} />
            </li>
          ))}
          <li className="cmp-product-list-item__color cmp-product-list-item__color--more">
            <i className="ri-add-line"></i>
          </li>
        </ul>
        <div className="cmp-product-list-item__title">{title}</div>
      </a>
    </li>
  )
}

export default ProductListItem
