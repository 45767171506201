import React, { useMemo, useState } from 'react'
import GlobalSearchSections from '@components/GlobalSearch/GlobalSearchSections'
import { TabsConfig } from '@components/GlobalSearch/GlobalSearchTypes'
import Tabs from '@components/GlobalSearch/Tabs'
import { MoreResultsView } from '@components/Header/Search'
import ErrorWrapper from '@components/UI/ErrorWrapper'
import { cls } from '@utils/classnames'

import GlobalSearchMoreResultsView from '../GlobalSearchMoreResultsView'

interface GlobalSearchLayoutProperties {
  tabs: TabsConfig[]
  defaultActiveTab: string
  labels: {
    errorApiTitle: string
    errorApiDescription: string
    noResultsTitle: string
    noResultsDescription: string
    showAllLabel: string
    showMoreLabel: string
    backlinkLabel: string
  }
  resultLimits: {
    previewResultLimit: string
    showAllResultLimit: string
  }
  searchTerm: string
  moreResultsView: MoreResultsView
  setMoreResultsView: (data: MoreResultsView) => void
}

const GlobalSearchLayout: React.FC<GlobalSearchLayoutProperties> = ({
  tabs,
  defaultActiveTab,
  labels,
  resultLimits,
  searchTerm,
  moreResultsView,
  setMoreResultsView,
}): JSX.Element => {
  const [activeTabId, setActiveTabId] = useState(defaultActiveTab || tabs[0]?.id)
  const selectedTab = useMemo(() => tabs.find((tab) => tab?.id === activeTabId), [activeTabId, tabs])

  return (
    <ErrorWrapper>
      <div className="cmp-global-search-layout">
        <div
          className={cls({
            'cmp-global-search-layout__view--active': moreResultsView.endpoint,
            'cmp-global-search-layout__view--hidden': !moreResultsView.endpoint,
          })}
        >
          <GlobalSearchMoreResultsView
            labels={labels}
            showAllResultLimit={resultLimits.showAllResultLimit}
            searchTerm={searchTerm}
            moreResultsView={moreResultsView}
            setMoreResultsView={setMoreResultsView}
          />
        </div>
        <div
          className={cls({
            'cmp-global-search-layout__view--active': !moreResultsView.endpoint,
            'cmp-global-search-layout__view--hidden': moreResultsView.endpoint,
          })}
        >
          <Tabs tabs={tabs} activeTab={activeTabId} onSwitchTab={setActiveTabId} />
          <GlobalSearchSections
            labels={labels}
            items={selectedTab?.searchSections || []}
            division={selectedTab?.label || ''}
            previewResultLimit={resultLimits.previewResultLimit}
            searchTerm={searchTerm}
            setMoreResultsView={setMoreResultsView}
          />
        </div>
      </div>
    </ErrorWrapper>
  )
}

export default GlobalSearchLayout
