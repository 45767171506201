import React, { useEffect, useMemo, useState } from 'react'
import { registerComponent } from 'react-register-dom'
import LoadingIndicator from '@components/GlobalSearch/LoadingIndicator'
import { PDF_CMF_KEY } from '@constants/localStorage'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { PdfLocalStorageProperties } from '@constants/localStorage.d.ts'
import { usePOSTFormData } from '@hooks/fetch'
import { useDebounce } from '@hooks/useDebounce'
import { getLocalStorageData } from '@utils/getLocalStorageData'

interface SendByEmailFormData {
  recaptchaToken: string
  documentUrl: string
  emailAddress: string
  [field: string]: string
}

const ThankYouPageCtaButtons: React.FC<{
  jsonData: string
  id: string
}> = ({ jsonData, id }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [urlParameters, setUrlParameters] = useState('')
  const [resultPageUrl, setResultPageUrl] = useState('')
  const [pdfExportUrl, setPdfExportUrl] = useState('')
  const [email, setEmail] = useState('')
  const {
    downloadButtonLabel,
    backButtonLabel,
    enableSendPdfByEmail,
    emailButtonLabel,
    emailSentButtonLabel,
    sendEmailEndpoint: { sendPdfToEmailUrl },
  } = useMemo(() => JSON.parse(jsonData), [jsonData])
  const localStorageData: PdfLocalStorageProperties | Record<string, unknown> = getLocalStorageData(PDF_CMF_KEY, {})

  useEffect(() => {
    setResultPageUrl(localStorageData.resultPageUrl)
    setUrlParameters(localStorageData.urlParams)
    setPdfExportUrl(localStorageData.pdfExportUrl)
    setEmail(localStorageData.email)
  }, [jsonData])

  const [sendEmailState, sendEmail] = usePOSTFormData<unknown, SendByEmailFormData>({ url: sendPdfToEmailUrl })

  function handleSendEmailClick() {
    const pdfFileUrl = `${pdfExportUrl}?${urlParameters}`
    if (sendEmailState.status === 'NEVER_EXECUTED') {
      const mainElement = document.querySelector<HTMLElement>(`#${id}`)
      if (!id || !mainElement) {
        console.warn('id is missing or wrong, cannot get token')

        return
      }

      const token = mainElement.dataset.recaptchaToken

      if (!token || token.length <= 0) {
        console.warn('token is missing or empty, cannot send email')

        return
      }

      sendEmail({
        documentUrl: pdfFileUrl,
        emailAddress: email,
        recaptchaToken: token,
      })
    }
  }

  function handleDownloadPDFClick() {
    if (pdfExportUrl && urlParameters && !isLoading) {
      setIsLoading(true)
      const pdfFileUrl = `${pdfExportUrl}?${urlParameters}`
      try {
        // https://www.geeksforgeeks.org/how-to-download-pdf-file-in-reactjs/
        fetch(pdfFileUrl).then((response) => {
          const filename = response.headers
            ?.get('content-disposition')
            ?.match(/filename=.+/g)?.[0]
            .replace(/filename=/g, '')

          response.blob().then((blob) => {
            const fileURL = window.URL.createObjectURL(blob)
            const alink = document.createElement('a')
            alink.href = fileURL
            alink.download = filename || 'cmf.pdf'
            alink.click()
            setIsLoading(false)
          })
        })
      } catch (error) {
        setIsLoading(false)
        console.error('xxx PDF export', error)
      }
    }
  }

  const debouncedOnClickHandler = useDebounce(handleDownloadPDFClick, 500)

  return (
    <>
      {isLoading ? (
        <div className="cmp-thank-you-page-cta-buttons__overlay">
          <LoadingIndicator />
        </div>
      ) : null}
      <div>
        <button onClick={debouncedOnClickHandler} className="cmp-thank-you-page-cta-buttons__download-button">
          {downloadButtonLabel}
        </button>
        {enableSendPdfByEmail && (
          <button onClick={handleSendEmailClick} className="cmp-thank-you-page-cta-buttons__send-to-email-button">
            {sendEmailState.status === 'SUCCESS' ? emailSentButtonLabel : emailButtonLabel}
          </button>
        )}
      </div>
      <a href={resultPageUrl} className="cmp-thank-you-page-cta-buttons__back-button">
        {backButtonLabel}
      </a>
    </>
  )
}

registerComponent('ThankYouPageCtaButtons', ThankYouPageCtaButtons)

export default ThankYouPageCtaButtons
