import { useMemo } from 'react'

export const DOTS = '..'
const NUMBER_OF_TARGET_SIBLINGS_EACH_SIDE = 1

const range = (start, end) => {
  const length = end - start + 1
  return Array.from({ length }, (_, index) => index + start)
}

export function usePagination(
  totalElements: number,
  pageSize: number,
  maxDisplayedElementCount: number,
  currentPage: number,
) {
  return useMemo(() => {
    const totalPageCount = Math.ceil(totalElements ? totalElements / pageSize : 0)
    const minPageToHaveDots = maxDisplayedElementCount - NUMBER_OF_TARGET_SIBLINGS_EACH_SIDE * 2

    if (totalPageCount <= maxDisplayedElementCount) {
      return range(1, totalPageCount)
    }

    if (currentPage < minPageToHaveDots) {
      return [...range(1, minPageToHaveDots), DOTS, totalPageCount]
    }

    if (currentPage > totalPageCount - minPageToHaveDots) {
      return [1, DOTS, ...range(totalPageCount - (minPageToHaveDots - 1), totalPageCount)]
    }

    return [
      1,
      DOTS,
      ...range(currentPage - NUMBER_OF_TARGET_SIBLINGS_EACH_SIDE, currentPage + NUMBER_OF_TARGET_SIBLINGS_EACH_SIDE),
      DOTS,
      totalPageCount,
    ]
  }, [totalElements, pageSize, maxDisplayedElementCount, currentPage])
}
