import React from 'react'

interface ResultTeaserContentProperties {
  result: string
  resultsTitle: string
  resultsDescription: string
  resultsButtonLink: Record<string, string>
  resultsButtonLabel: string
}

const ResultTeaserContent: React.FC<ResultTeaserContentProperties> = ({
  result,
  resultsTitle,
  resultsDescription,
  resultsButtonLink,
  resultsButtonLabel,
}) => {
  return (
    <>
      <p className="coffee-machine-finder__results-text">{result}</p>
      <h1 className="coffee-machine-finder__results-title">{resultsTitle}</h1>
      <p className="coffee-machine-finder__results-description">{resultsDescription}</p>

      {resultsButtonLink ? (
        <a href={resultsButtonLink.externalizedURL} className="coffee-machine-finder__results-button">
          {resultsButtonLabel}
        </a>
      ) : null}
    </>
  )
}

export default ResultTeaserContent
