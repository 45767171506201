import * as React from 'react'
import { DismissButton, FocusScope, mergeProps, useDialog, useModal, useOverlay } from 'react-aria'

interface PopoverProperties {
  title: JSX.Element
  isOpen: boolean
  onClose: () => void
  children: JSX.Element | string
  className?: string
  style?: React.CSSProperties
}

const MultiSelectFilterPopover = React.forwardRef<HTMLDivElement, PopoverProperties>(
  ({ title, children, isOpen, className, style, onClose, ...otherProperties }, reference) => {
    const dismissReference = React.useRef<HTMLDivElement>(null)
    // Handle interacting outside the dialog and pressing
    // the Escape key to close the modal.
    const { overlayProps } = useOverlay(
      {
        onClose,
        isOpen,
        isDismissable: true,
      },
      reference as React.RefObject<HTMLElement>,
    )

    // Hide content outside the modal from screen readers.
    const { modalProps } = useModal()

    // Get props for the dialog and its title
    const { dialogProps, titleProps } = useDialog({}, reference as React.RefObject<HTMLElement>)

    React.useEffect(() => {
      if (isOpen) {
        dismissReference.current?.focus()
      }
    }, [isOpen])

    function handleDismissKeyDown(event: React.KeyboardEvent) {
      if (event.key === ' ' || event.key === 'Enter') {
        event.preventDefault()
        onClose()
      }
    }

    return (
      <FocusScope restoreFocus>
        <div
          {...mergeProps(overlayProps, dialogProps, otherProperties, modalProps)}
          ref={reference}
          className={className}
          style={{
            ...style,
          }}
        >
          <div className="cmp-multi-select-filter-popover__header">
            <div
              className="cmp-multi-select-filter-popover__dismiss"
              tabIndex={0}
              onClick={onClose}
              onKeyDown={handleDismissKeyDown}
              role="button"
              ref={dismissReference}
            >
              <i className="ri-close-line" />
            </div>
            <div className="cmp-multi-select-filter-popover__title" {...titleProps}>
              {title}
            </div>
          </div>
          {children}
          <DismissButton onDismiss={onClose} />
        </div>
      </FocusScope>
    )
  },
)

export default MultiSelectFilterPopover
