import { useEffect, useState } from 'react'

export const useKeyPress = (): {
  isToggled: boolean
  isThemeToggled: boolean
} => {
  const [toggle, setToggle] = useState(false)
  const [toggleTheme, setToggleTheme] = useState(false)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onKeydownHandle = (_event: any) => {
    const event = _event || window.event

    // ctrl + . (Windows/Mac) OR shift + a (Mac)
    if ((_event.keyCode === 190 && event.ctrlKey) || (_event.keyCode === 65 && event.shiftKey)) {
      setToggle(!toggle)
    }

    // shift + t
    if (_event.keyCode === 84 && event.shiftKey) {
      setToggleTheme(!toggleTheme)
    }
  }

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      document.addEventListener('keydown', onKeydownHandle)
    }
    return () => document.removeEventListener('keydown', onKeydownHandle)
  })

  return {
    isToggled: toggle,
    isThemeToggled: toggleTheme,
  }
}
