// https://lightrains.com/blogs/using-localstorage-hook-react/
import { useEffect, useState } from 'react'
import { getLocalStorageData } from '@utils/getLocalStorageData'

export function useLocalStorage<T>({ key, defaultValue }: { key: string; defaultValue?: T }) {
  const [value, setValue] = useState(() => getLocalStorageData(key, defaultValue))

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value))
  }, [value, key])

  return [value, setValue] as const
}
