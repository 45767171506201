import React from 'react'

const ResultTabs: React.FC<{
  tabs: string[]
  selectedTab: number
  onSelect: (index: number) => void
}> = ({ tabs, selectedTab, onSelect }) => {
  return (
    <div className="coffee-machine-finder__result-tabs">
      {tabs.map((t, index) => (
        <button
          type="button"
          key={t}
          onClick={() => onSelect(index)}
          className={`coffee-machine-finder__result-tab ${
            selectedTab === index ? 'coffee-machine-finder__result-tab--selected' : ''
          }`}
        >
          {t}
        </button>
      ))}
    </div>
  )
}

export default ResultTabs
