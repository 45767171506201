import { useEffect, useState } from 'react'

import { useDebounce } from './useDebounce'

interface ElementSizes {
  width: number
  height: number
}

interface UseElementObserver {
  referenceElement: React.MutableRefObject<HTMLDivElement | null>
  inView: boolean
}

interface UseElementObserverReturnValues {
  sizes: { width: number; height: number }
  referenceElement: React.MutableRefObject<HTMLDivElement | null>
}

const initialElementSizes = {
  width: 0,
  height: 0,
}

export const useElementObserver = ({
  referenceElement,
  inView,
}: UseElementObserver): UseElementObserverReturnValues => {
  const [sizes, setSizes] = useState<ElementSizes>(initialElementSizes)
  const debouncedSetElementSizes = useDebounce(setSizes)
  useEffect(() => {
    if (!referenceElement?.current) {
      return
    }

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const entryBoundingClientRect = entry.target.getBoundingClientRect()
        debouncedSetElementSizes({
          width: entryBoundingClientRect.width,
          height: entryBoundingClientRect.height,
        })
      }
    })

    // we want to re-fetch the image src only for the inView elements
    resizeObserver[inView ? 'observe' : 'unobserve'](referenceElement.current)

    return () => {
      if (referenceElement?.current) {
        resizeObserver.unobserve(referenceElement.current)
      }
    }
  }, [inView, referenceElement.current, debouncedSetElementSizes])

  return {
    sizes,
    referenceElement,
  }
}
