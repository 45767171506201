import React, { useEffect } from 'react'
import { searchUrlParameters } from '@components/CoffeeMachineFinder/constants'
import { cls } from '@utils/classnames'

import { CoffeeMachineFinderTexts, QuestionConfig } from '../CoffeeMachineFinderTypes'
import QuestionnaireOption from '../QuestionnaireOption'

const QuestionnaireSlide: React.FC<{
  config: QuestionConfig
  displayedStep: number
  current: boolean
  totalSteps: number
  selectedOptions: { [optionId: string]: boolean }
  first: boolean
  last: boolean
  texts: CoffeeMachineFinderTexts
  isForwardDisabled: boolean
  onOptionToggle: (id: string) => void
  onBack: () => void
  onForward: () => void
}> = ({
  config,
  current,
  first,
  last,
  displayedStep,
  totalSteps,
  selectedOptions,
  texts,
  isForwardDisabled,
  onOptionToggle,
  onBack,
  onForward,
}) => {
  useEffect(() => {
    if (current) {
      window.adobeDataLayer?.push({
        event: 'coffee machine finder',
        eventInfo: {
          reference: 'coffee machine finder',
          step: displayedStep,
          title: config.question,
          answer: null,
        },
      })
    }
  }, [current])

  const numberOfSelectedOptions: boolean[] = []

  config.options.map((option) => {
    if (selectedOptions[option.id]) {
      numberOfSelectedOptions.push(true)
    }
  })

  return (
    <div
      key={config.id}
      className={cls({
        'coffee-machine-finder__slide': true,
        'coffee-machine-finder__slide--current': current,
        'coffee-machine-finder__slide--is-rating': config.paramName === searchUrlParameters.WEIGHTS,
      })}
    >
      <p className="coffee-machine-finder__slide-steps-number">
        {texts.step} {displayedStep} / {totalSteps}
      </p>
      {config?.question ? <h3 className="coffee-machine-finder__slide-question">{config.question}</h3> : null}
      {config?.subline ? <p className="coffee-machine-finder__slide-subline">{config.subline}</p> : null}
      <div
        className={`${
          displayedStep === totalSteps ? 'coffee-machine-finder__option-list--is-last-step' : ''
        } coffee-machine-finder__option-list`}
      >
        {config.options.map((option) => (
          <QuestionnaireOption
            key={option.id}
            onToggle={onOptionToggle}
            selectedOptions={selectedOptions}
            option={option}
            maxAnswers={config?.maxAnswers || config.options?.length || 1}
            numberOfSelectedOptions={numberOfSelectedOptions.length}
            isDisabled={option.isDisabledBy?.some((id) => selectedOptions[id])}
          />
        ))}
      </div>
      <div className="coffee-machine-finder__actions">
        {!first && (
          <button type="button" onClick={onBack} className="coffee-machine-finder__actions__previous">
            {texts.back}
          </button>
        )}
        <button
          type="button"
          onClick={onForward}
          className={cls({
            'coffee-machine-finder__actions__next': true,
            'coffee-machine-finder__actions__next--is-disabled': isForwardDisabled,
          })}
        >
          {last ? texts.toResults : texts.forward}
        </button>
      </div>
    </div>
  )
}

export default QuestionnaireSlide
