import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useRef, useState } from 'react'
import Modal from '@components/Modal/Modal'
import { cls } from '@utils/classnames'
import { isMobile, useWindowResize } from '@utils/mediaQueries'

import { ProductImage } from '../PdpTypes'

interface ProductCarouselZoomModalProperties {
  modalLabel: string
  isOpen: boolean
  imageList: ProductImage[]
  galleryAccessibilityLabelButtonDown: string
  galleryAccessibilityLabelButtonUp: string
  selectedImageIndex: number
  setSelectedImageIndex: Dispatch<SetStateAction<number>>
  onModalClose: () => void
}

type ZoomMode = 'out' | 'in'

export const ProductCarouselZoomModal: FC<ProductCarouselZoomModalProperties> = ({
  modalLabel,
  isOpen,
  imageList,
  galleryAccessibilityLabelButtonDown,
  galleryAccessibilityLabelButtonUp,
  selectedImageIndex,
  setSelectedImageIndex,
  onModalClose,
}) => {
  const zoomedImageReference = useRef<HTMLImageElement>(null)
  const containerReference = useRef<HTMLElement>(null)
  const thumbnailContainerReference = useRef<HTMLDivElement>(null)
  const [zoomMode, setZoomMode] = useState<ZoomMode>('in')
  const [zoomCoordinates, setZoomCoordinates] = useState({ x: 0, y: 0 })
  const isMobileScreen = useWindowResize(isMobile)
  const [showBottomScroller, setShowBottomScroller] = useState(imageList.length > 5)
  const [showTopScroller, setShowTopScroller] = useState(false)
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 })

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      for (const {} of entries) {
        centerSmallerImage()
      }
    })
    if (zoomedImageReference.current) {
      observer.observe(zoomedImageReference.current)
    }
    return () => {
      observer.disconnect()
    }
  }, [isOpen])

  const setScrollersVisibility = useCallback(
    (scrollableContainer: HTMLDivElement) => {
      if (isMobileScreen) {
        const width = Math.round(scrollableContainer.getBoundingClientRect().width)
        const maxScroll = scrollableContainer.scrollWidth - width
        const currentScroll = scrollableContainer.scrollLeft
        setShowTopScroller(currentScroll > 0)
        setShowBottomScroller(currentScroll < maxScroll)
      } else {
        const height = Math.round(scrollableContainer.getBoundingClientRect().height)
        const maxScroll = scrollableContainer.scrollHeight - height
        const currentScroll = scrollableContainer.scrollTop
        setShowTopScroller(currentScroll > 0)
        setShowBottomScroller(currentScroll < maxScroll)
      }
    },
    [setShowTopScroller, setShowBottomScroller],
  )

  useEffect(() => {
    if (thumbnailContainerReference.current) {
      const height = thumbnailContainerReference.current.getBoundingClientRect().height
      const width = thumbnailContainerReference.current.getBoundingClientRect().width
      const scrollHeight = thumbnailContainerReference.current.scrollHeight
      const scrollWidth = thumbnailContainerReference.current.scrollWidth

      const isScrollOnMobile = scrollHeight > height && !isMobileScreen
      const isScrollOnDesktop = scrollWidth > width && isMobileScreen

      if (isScrollOnDesktop || isScrollOnMobile) {
        setScrollersVisibility(thumbnailContainerReference.current)
      }
    }
  }, [thumbnailContainerReference.current, setScrollersVisibility])

  function handleMove(event: React.MouseEvent<HTMLElement>): void {
    if (!zoomedImageReference.current || zoomMode !== 'in' || !containerReference.current) {
      return
    }

    const coordinates = calculateMarginCoordinatesBasedOnMousePosition(
      event.clientX,
      event.clientY,
      zoomedImageReference.current,
      containerReference.current,
    )

    setZoomCoordinates(coordinates)
  }

  function handleClickOnThumbnail(index: number) {
    setSelectedImageIndex(index)
    setZoomMode('in')
  }

  function toggleZoomMode() {
    setZoomMode((old) => (old === 'in' ? 'out' : 'in'))
  }

  function centerSmallerImage() {
    if (containerReference.current && zoomedImageReference.current) {
      const coords = getCoordinatesOfCenterdImage(zoomedImageReference.current, containerReference.current)
      setZoomCoordinates(coords)
    }
  }

  function handleMouseEnterOnThumbnail(index) {
    setSelectedImageIndex(index)
  }
  function scrollToTop() {
    if (thumbnailContainerReference.current) {
      thumbnailContainerReference.current.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    }
  }
  function scrollToBottom() {
    if (thumbnailContainerReference.current) {
      const maxTopScroll = thumbnailContainerReference.current.scrollHeight
      const maxLeftSCroll = thumbnailContainerReference.current.scrollWidth
      thumbnailContainerReference.current.scrollTo({
        [isMobileScreen ? 'left' : 'top']: isMobileScreen ? maxLeftSCroll : maxTopScroll,
        behavior: 'smooth',
      })
    }
  }
  function handleNextClick() {
    setZoomMode('out')
    const hasNext = selectedImageIndex < imageList.length - 1
    setSelectedImageIndex(hasNext ? selectedImageIndex + 1 : 0)
  }

  function handlePreviousClick() {
    setZoomMode('out')
    const hasPrevious = selectedImageIndex > 0
    setSelectedImageIndex(hasPrevious ? selectedImageIndex - 1 : imageList.length - 1)
  }

  function setImageSizeToNatural() {
    if (!zoomedImageReference.current || !containerReference.current) {
      return
    }
    const naturalWidth = zoomedImageReference.current.naturalWidth
    const naturalHeight = zoomedImageReference.current.naturalHeight

    let width = naturalWidth
    let height = naturalHeight

    if (naturalHeight === 0 || naturalWidth === 0) {
      const max = Math.max(
        containerReference.current.getBoundingClientRect().width,
        containerReference.current.getBoundingClientRect().height,
      )
      width = max
      height = max
    }

    setImageSize({
      width,
      height,
    })
  }

  return (
    <Modal
      ariaLabel={modalLabel}
      isFullSize={true}
      isShowing={isOpen}
      closeModal={onModalClose}
      hideAnimation={true}
      className="cmp-product-carousel-zoom-modal"
    >
      {isMobileScreen ? (
        <div className="cmp-product-carousel-zoom-modal__modal-mobile-layout">
          <div className="cmp-product-carousel__number-slides">
            {selectedImageIndex + 1} / {imageList.length}
          </div>
          <div className="cmp-product-carousel-zoom-modal__modal-mobile-buttons-wrapper">
            <button
              onClick={handleNextClick}
              className="cmp-product-carousel__scroller cmp-product-carousel__scroller--bottom"
              aria-label={galleryAccessibilityLabelButtonDown}
            >
              <i className="ri-arrow-down-line" />
              <i className="ri-arrow-right-line" />
            </button>
            <button
              onClick={handlePreviousClick}
              className="cmp-product-carousel__scroller cmp-product-carousel__scroller--top"
              aria-label={galleryAccessibilityLabelButtonUp}
            >
              <i className="ri-arrow-up-line" />
              <i className="ri-arrow-left-line" />
            </button>
          </div>
        </div>
      ) : (
        <div className="cmp-product-carousel__thumbnail-layout cmp-product-carousel__thumbnail-layout--is-in-modal">
          <div
            className="cmp-product-carousel__thumbnail-container"
            ref={thumbnailContainerReference}
            onScroll={(event) => setScrollersVisibility(event.currentTarget)}
          >
            {imageList.map((image, index) => (
              <button
                key={index}
                onClick={() => handleClickOnThumbnail(index)}
                onMouseEnter={() => handleMouseEnterOnThumbnail(index)}
                className={cls({
                  'cmp-product-carousel__thumbnail': true,
                  'cmp-product-carousel__thumbnail--is-selected': selectedImageIndex === index,
                })}
              >
                <img
                  className="cmp-product-carousel__thumbnail-image"
                  alt={image.thumbnail.altText}
                  src={image.thumbnail.url}
                />
              </button>
            ))}
          </div>

          {showBottomScroller ? (
            <button
              className="cmp-product-carousel__scroller cmp-product-carousel__scroller--bottom"
              aria-label={galleryAccessibilityLabelButtonDown}
              onClick={scrollToBottom}
            >
              <i className="ri-arrow-down-line" />
              <i className="ri-arrow-right-line" />
            </button>
          ) : null}
          {showTopScroller ? (
            <button
              className="cmp-product-carousel__scroller cmp-product-carousel__scroller--top"
              aria-label={galleryAccessibilityLabelButtonUp}
              onClick={scrollToTop}
            >
              <i className="ri-arrow-up-line" />
              <i className="ri-arrow-left-line" />
            </button>
          ) : null}
        </div>
      )}
      <div
        className={cls({
          'cmp-product-carousel-zoom-modal__zoom-area': true,
          'cmp-product-carousel-zoom-modal__zoom-area--is-zoomed-out': zoomMode === 'out',
        })}
      >
        <figure
          className={cls({
            'cmp-product-carousel-zoom-modal__modal-image-wrapper': true,
            'cmp-product-carousel-zoom-modal__modal-image-wrapper--is-zoomed-out': zoomMode === 'out',
          })}
          onMouseMove={handleMove}
          onClick={toggleZoomMode}
          onKeyDown={toggleZoomMode}
          role="presentation"
          ref={containerReference}
        >
          <img
            onLoad={setImageSizeToNatural}
            style={{
              marginTop: zoomCoordinates.y,
              marginLeft: zoomCoordinates.x,
              width: zoomMode === 'in' ? imageSize.width : undefined,
              height: zoomMode === 'in' ? imageSize.height : undefined,
            }}
            src={imageList[selectedImageIndex].primary.url}
            ref={zoomedImageReference}
            alt={imageList[selectedImageIndex].primary.altText}
          />
        </figure>
      </div>
    </Modal>
  )
}

function calculateMarginCoordinatesBasedOnMousePosition(
  mouseX: number,
  mouseY: number,
  zoomedElement: HTMLElement,
  containerElement: HTMLElement,
) {
  const xCoord = mouseX
  const yCoord = mouseY

  const maxWidth = containerElement.getBoundingClientRect().width
  const maxHeight = containerElement.getBoundingClientRect().height

  const imageWidth = zoomedElement.getBoundingClientRect().width
  const imageHeight = zoomedElement.getBoundingClientRect().height

  let x = 0
  let y = 0

  if (maxWidth < imageWidth) {
    const mouseRelativePositionX = xCoord / maxWidth
    const maxMarginLeft = -1 * (imageWidth - maxWidth)
    const minMarginLeft = 0
    const marginLeft = (maxMarginLeft - minMarginLeft) * mouseRelativePositionX + minMarginLeft

    x = Math.max(Math.min(marginLeft, 0), maxMarginLeft)
  } else {
    x = Math.round((maxWidth - imageWidth) / 2)
  }

  if (maxHeight < imageHeight) {
    const mouseRelativePositionY = yCoord / maxHeight
    const maxMarginTop = -1 * (imageHeight - maxHeight)
    const minMarginTop = 0
    const marginTop = (maxMarginTop - minMarginTop) * mouseRelativePositionY + minMarginTop

    y = Math.max(Math.min(marginTop, 0), maxMarginTop)
  } else {
    y = Math.round((maxHeight - imageHeight) / 2)
  }

  return { x, y }
}

function getCoordinatesOfCenterdImage(zoomedElement: HTMLElement, containerElement: HTMLElement) {
  const isMobileScreen = isMobile()
  if (isMobileScreen) {
    return { x: 0, y: 0 }
  }
  const maxWidth = containerElement.getBoundingClientRect().width
  const maxHeight = containerElement.getBoundingClientRect().height

  const imageWidth = zoomedElement.getBoundingClientRect().width
  const imageHeight = zoomedElement.getBoundingClientRect().height

  const x = Math.round((maxWidth - imageWidth) / 2)
  const y = Math.round((maxHeight - imageHeight) / 2)

  return { x, y }
}
