import React, { useCallback } from 'react'
import AsyncCallResult from '@components/AsyncCallResult'
import ButtonLink from '@components/ButtonLink'
import { GlobalSearchResultsData } from '@components/GlobalSearch/GlobalSearchResults'
import GlobalSearchResults from '@components/GlobalSearch/GlobalSearchResults/GlobalSearchResults'
import { SectionType } from '@components/GlobalSearch/GlobalSearchTypes'
import LoadingIndicator from '@components/GlobalSearch/LoadingIndicator'
import { MoreResultsView } from '@components/Header/Search'
import NoResults from '@components/NoResults'
import { useAutoGET, useFetchSuccess } from '@hooks/fetch'

interface SectionItemProperties {
  label: string
  endpoint: string
  type: SectionType
  baseUrlTemplate: string
  plpUrl: string
  labels: {
    errorApiTitle: string
    errorApiDescription: string
    noResultsTitle: string
    noResultsDescription: string
    showAllLabel: string
  }
  previewResultLimit: string
  searchTerm: string
  setMoreResultsView: (data: MoreResultsView) => void
  sectionIndex
  onSearchSuccess: (numberOfResults: number, index: number) => void
  division: string
  productVariantLabel: string
}

const GlobalSearchSectionsItem: React.FC<SectionItemProperties> = ({
  label,
  endpoint,
  type,
  labels,
  previewResultLimit,
  searchTerm,
  setMoreResultsView,
  baseUrlTemplate,
  plpUrl,
  onSearchSuccess,
  sectionIndex,
  division,
  productVariantLabel,
}): JSX.Element => {
  const queryParameters =
    type === 'PCM_PRODUCTS'
      ? {
          query: searchTerm,
          page_size: previewResultLimit,
        }
      : {
          fulltextTerm: searchTerm,
          pageSize: previewResultLimit,
        }
  const [resultsState] = useAutoGET<GlobalSearchResultsData>(endpoint, queryParameters, undefined, searchTerm == '')

  const handleSuccess = useCallback(
    (results) => {
      onSearchSuccess(type === 'PCM_PRODUCTS' ? results.totalElementCount : results.numberOfResults, sectionIndex)
    },
    [type, onSearchSuccess],
  )

  useFetchSuccess(resultsState, handleSuccess)

  return (
    <div className="cmp-global-search-sections-item">
      <div className="cmp-global-search-sections-item__label">
        <h2>{label}</h2>
        <p className="cmp-global-search-view-more__label--counter">
          (
          {resultsState.status === 'SUCCESS'
            ? type === 'PCM_PRODUCTS'
              ? resultsState.data.totalElementCount || 0
              : resultsState.data.numberOfResults || 0
            : 0}
          )
        </p>
      </div>
      <AsyncCallResult
        state={resultsState}
        renderError={() => {
          return <NoResults title={labels.errorApiTitle} description={labels.errorApiDescription} isError hasSpacing />
        }}
        renderLoading={() => <LoadingIndicator />}
        renderSuccess={(data) => {
          if (!data || data.resultItems?.length === 0 || data.productList?.length === 0) {
            return <NoResults hasSpacing title={labels.noResultsTitle} description={labels.noResultsDescription} />
          }

          return (
            <div className="white-theme cmp-global-search-sections-item__container">
              <div className="cmp-global-search-sections-item__results">
                <GlobalSearchResults
                  baseUrlTemplate={baseUrlTemplate}
                  data={data}
                  type={type}
                  division={division}
                  searchTerm={searchTerm}
                  productVariantLabel={productVariantLabel}
                />
              </div>
              {type === 'PCM_PRODUCTS' && (
                <a
                  href={plpUrl.replace(':searchTerm', searchTerm)}
                  className="cmp-global-search-sections-item__view-all--is-link"
                >
                  {labels.showAllLabel}
                </a>
              )}

              {(data.hasMore ||
                ((data.productList?.length || Number.POSITIVE_INFINITY) <= data.totalElementCount &&
                  type !== 'PCM_PRODUCTS')) && (
                <ButtonLink
                  hasSpacing
                  className="cmp-global-search-sections-item__view-all"
                  text={labels.showAllLabel}
                  onClick={() =>
                    setMoreResultsView({
                      label,
                      endpoint,
                      type,
                      baseUrlTemplate,
                      division,
                    })
                  }
                ></ButtonLink>
              )}
            </div>
          )
        }}
      />
    </div>
  )
}

export default GlobalSearchSectionsItem
