import React, { useCallback, useEffect, useMemo, useState } from 'react'
import SectionsItem from '@components/GlobalSearch/GlobalSearchSections/GlobalSearchSectionsItem'
import { SectionItem } from '@components/GlobalSearch/GlobalSearchTypes'
import { MoreResultsView } from '@components/Header/Search'

interface GlobalSearchSectionsProperties {
  items: SectionItem[]
  labels: {
    errorApiTitle: string
    errorApiDescription: string
    noResultsTitle: string
    noResultsDescription: string
    showAllLabel: string
  }
  previewResultLimit: string
  searchTerm: string
  division: string
  setMoreResultsView: (data: MoreResultsView) => void
}

const initialRequestsState: Array<{
  type: string
  numberOfResults: string | number | null
}> = []

const GlobalSearchSections: React.FC<GlobalSearchSectionsProperties> = ({
  items,
  labels,
  previewResultLimit,
  searchTerm,
  setMoreResultsView,
  division,
}): JSX.Element => {
  const [requests, setRequests] = useState(initialRequestsState)
  const [numberOfReturnedRequests, setNumberOfReturnedRequests] = useState(0)

  const emptyRequests = useMemo(
    () =>
      items.map((item) => {
        return {
          type: item.searchType,
          numberOfResults: null,
        }
      }),
    [items],
  )

  /** sets up base requests object based on the config for the open tab, it resets when the search button is pressed */
  useEffect(() => {
    setNumberOfReturnedRequests(0)
    setRequests(emptyRequests)
  }, [emptyRequests, searchTerm])

  useEffect(() => {
    const isStillWaiting = numberOfReturnedRequests < items.length
    if (!isStillWaiting && requests.length > 0) {
      window.adobeDataLayer?.push({
        event: 'search result',
        eventInfo: {
          reference: 'search',
          searchKeyword: searchTerm,
          searchResults: requests,
          division: division,
        },
      })
    }
  }, [requests])

  const handleResolve = useCallback(
    (numberOfResults, index) => {
      setRequests((current) => {
        return [
          ...current.slice(0, index),
          {
            type: current?.[index]?.type,
            numberOfResults: numberOfResults || 'zero',
          },
          ...current.slice(index + 1),
        ]
      })
      setNumberOfReturnedRequests((old) => old + 1)
    },
    [setRequests],
  )

  return (
    <>
      {items?.map((item, index) => (
        <ul className="cmp-global-search-sections" key={item.endpoint}>
          <li>
            <SectionsItem
              division={division}
              label={item.label}
              endpoint={item.endpoint}
              type={item.searchType}
              labels={labels}
              baseUrlTemplate={item.baseUrlTemplate || ''}
              plpUrl={item.plpUrl || ''}
              previewResultLimit={previewResultLimit}
              searchTerm={searchTerm}
              setMoreResultsView={setMoreResultsView}
              sectionIndex={index}
              onSearchSuccess={handleResolve}
              productVariantLabel={item.productVariantLabel || ''}
            />
          </li>
        </ul>
      ))}
    </>
  )
}

export default GlobalSearchSections
