import React from 'react'

interface DateTimeDisplayProperties {
  value: number
  type: string
}

const DateTimeDisplay: React.FC<DateTimeDisplayProperties> = ({ value, type }: DateTimeDisplayProperties) => {
  return (
    <div className="cmp-date-time-display">
      <div className="cmp-date-time-display__item-value">{value}</div>
      <div className="cmp-date-time-display__item-type">{type}</div>
    </div>
  )
}
export default DateTimeDisplay
