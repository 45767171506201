const unitSystemToEarthRadius = {
  metric: 6371,
  imperial: 3958.8,
} as const

export type UnitSystem = keyof typeof unitSystemToEarthRadius

export const unitSystemToSymbol = {
  metric: 'km',
  imperial: 'mi.',
}

/**
 * https://stackoverflow.com/questions/18883601/function-to-calculate-distance-between-two-coordinates
 *
 * @param lat1 latitude of the first point
 * @param lon1 longitude of the first point
 * @param lat2 latitude of the second point
 * @param lon2 longitude of the second point
 * @returns the spherical distance (straight line)
 */
export function sphericalDistance(lat1: number, lon1: number, lat2: number, lon2: number, unitSystem: UnitSystem) {
  const earthRadius = unitSystemToEarthRadius[unitSystem] // km or mi
  const latitudeDistance = toRad(lat2 - lat1)
  const longitudeDistance = toRad(lon2 - lon1)
  const latitude1InRadiants = toRad(lat1)
  const latitude2InRadiants = toRad(lat2)

  const a =
    Math.sin(latitudeDistance / 2) * Math.sin(latitudeDistance / 2) +
    Math.sin(longitudeDistance / 2) *
      Math.sin(longitudeDistance / 2) *
      Math.cos(latitude1InRadiants) *
      Math.cos(latitude2InRadiants)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  return earthRadius * c
}

/** Converts numeric degrees to radians */
function toRad(value) {
  return (value * Math.PI) / 180
}
