import * as React from 'react'
import ResponsiveImage from '@components/ResponsiveImage'
import { cls } from '@utils/classnames'

export interface SearchResultItemProperties {
  className?: string
  title: string
  description?: string
  formatedPublicationDate?: string
  link?: string
  image?: {
    originalImageUrl: string
    altText: string
  }
  ariaLabel: string
  onClick: () => void
}

const SearchResultItem: React.FC<SearchResultItemProperties> = ({
  title,
  description,
  image,
  className,
  link,
  ariaLabel,
  formatedPublicationDate,
  onClick,
}: SearchResultItemProperties) => {
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a className="cmp-search-result-item__wrapper" href={link || '#'} aria-label={ariaLabel} onClick={onClick}>
      <div
        className={cls({
          'cmp-search-result-item': true,
          [className || '']: className,
          'cmp-search-result-item--with-image': Boolean(image?.originalImageUrl),
        })}
      >
        {Boolean(image?.originalImageUrl) ? (
          <div className="cmp-search-result-item__image">
            {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
            <ResponsiveImage src={image?.originalImageUrl || ''} alt={image?.altText || ''} />
          </div>
        ) : null}
        <div className="cmp-search-result-item__content">
          {formatedPublicationDate && (
            <h3 className="cmp-search-result-item__content-date">{formatedPublicationDate}</h3>
          )}
          <h3 className="cmp-search-result-item__content-title">{title}</h3>
          <p className="cmp-search-result-item__content-short-description">{description}</p>
        </div>
      </div>
    </a>
  )
}

export default SearchResultItem
