import React from 'react'
import ResponsiveImage from '@components/ResponsiveImage'

import { CoffeeMachineFinderTexts, CoffeeMachineProduct } from '../CoffeeMachineFinderTypes'
import CoffeeMachineProductFeature from '../CoffeeMachineProductFeature'

const CoffeeMachine: React.FC<{
  machine: CoffeeMachineProduct
  onRestart: () => void
  texts: CoffeeMachineFinderTexts
}> = ({ machine, onRestart, texts }) => {
  function onDownloadFactsheet() {
    window.adobeDataLayer?.push({
      event: 'download file',
      eventInfo: {
        reference: 'coffee machine finder',
        fileName: machine.factsheetsLink?.replace(/^.*[/\\]/, ''),
      },
    })
  }
  return (
    <>
      <div className="coffee-machine__image-spacer" />
      <ResponsiveImage src={machine.imageSrc} alt={machine.name} className="coffee-machine__image" />

      <div className="coffee-machine">
        <div className="coffee-machine__info">
          <h2 className="coffee-machine__name">{machine.name}</h2>
          <p className="coffee-machine__description">{machine.description}</p>
        </div>

        {texts?.downloadFactsheets && machine.factsheetsLink ? (
          <a
            href={machine.factsheetsLink}
            type="button"
            className="coffee-machine__download"
            onClick={onDownloadFactsheet}
          >
            {texts?.downloadFactsheets}
          </a>
        ) : null}
      </div>
      <hr className="coffee-machine__divider" />
      <div className="coffee-machine__feature-container">
        {machine.features.map((feature) => (
          <CoffeeMachineProductFeature key={feature.name} feature={feature} />
        ))}
      </div>
      <div className="coffee-machine__product-cta">
        <a
          aria-label={`link to ${texts.seeProductDetails}`}
          href={machine.productLink}
          className="coffee-machine__product-details"
        >
          {texts.seeProductDetails}
        </a>
        <button
          aria-label={`button for ${texts.restartFinder}`}
          onClick={onRestart}
          className="coffee-machine__reset-finder"
        >
          {texts.restartFinder}
        </button>
      </div>
    </>
  )
}

export default CoffeeMachine
