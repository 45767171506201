;(function () {
  'use strict'

  function onDocumentReady() {
    const pardotForm = document.querySelector('[data-component-name="pardot-form-container"] > form')

    pardotForm?.addEventListener('submit', function (event) {
      const formData = Object.fromEntries(
        [...event.target.querySelectorAll('[name]')].map((element) => [element.name, element.value]),
      )

      window.adobeDataLayer?.push({
        event: 'pardot form submitted',
        eventInfo: {
          reference: 'pardot form',
          requestCountry: formData['Country'],
          requestType: formData['TypeOfRequest'],
          consentContact: formData['ReceiveMarketingCommunication'],
        },
      })
    })
  }

  if (document.readyState !== 'loading') {
    onDocumentReady()
  } else {
    document.addEventListener('DOMContentLoaded', onDocumentReady)
  }
})()
