import React from 'react'
import register from 'react-register-dom'

if (process.env.NODE_ENV === 'msw') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { worker } = require('@mocks/browser')
  worker.start()
}

// ###########
// Stylesheets
// ###########
import './main.scss'
// ##########
// Javascript
// ##########
import '/build_root/build/frankedigitalexperience-p53202-uk85787/ui.frontend/src/main/webpack/components/core/_carousel.js'; import '/build_root/build/frankedigitalexperience-p53202-uk85787/ui.frontend/src/main/webpack/components/core/_download.js'; import '/build_root/build/frankedigitalexperience-p53202-uk85787/ui.frontend/src/main/webpack/components/core/_form.js'; import '/build_root/build/frankedigitalexperience-p53202-uk85787/ui.frontend/src/main/webpack/components/core/datalayer/_socialicons.js'
import '/build_root/build/frankedigitalexperience-p53202-uk85787/ui.frontend/src/main/webpack/components/custom/_anchor-navigation.js'; import '/build_root/build/frankedigitalexperience-p53202-uk85787/ui.frontend/src/main/webpack/components/custom/_animatedicongrid.js'; import '/build_root/build/frankedigitalexperience-p53202-uk85787/ui.frontend/src/main/webpack/components/custom/_cardteaser.js'; import '/build_root/build/frankedigitalexperience-p53202-uk85787/ui.frontend/src/main/webpack/components/custom/_footer.js'; import '/build_root/build/frankedigitalexperience-p53202-uk85787/ui.frontend/src/main/webpack/components/custom/_imagegallery.js'; import '/build_root/build/frankedigitalexperience-p53202-uk85787/ui.frontend/src/main/webpack/components/custom/_option-show-hide.js'; import '/build_root/build/frankedigitalexperience-p53202-uk85787/ui.frontend/src/main/webpack/components/custom/_pdfleadgenerationform.js'; import '/build_root/build/frankedigitalexperience-p53202-uk85787/ui.frontend/src/main/webpack/components/custom/_productfunctionalities.js'; import '/build_root/build/frankedigitalexperience-p53202-uk85787/ui.frontend/src/main/webpack/components/custom/_productinformationtable.js'; import '/build_root/build/frankedigitalexperience-p53202-uk85787/ui.frontend/src/main/webpack/components/custom/_sidebysideimages.js'; import '/build_root/build/frankedigitalexperience-p53202-uk85787/ui.frontend/src/main/webpack/components/custom/_spinviewer.js'; import '/build_root/build/frankedigitalexperience-p53202-uk85787/ui.frontend/src/main/webpack/components/custom/datalayer/_contact.js'; import '/build_root/build/frankedigitalexperience-p53202-uk85787/ui.frontend/src/main/webpack/components/custom/datalayer/_pardotform.js'
// #######################
// Import React components
// #######################
import '../components/CoffeeMachineFinder'
import '../components/CountdownPage'
import '../components/DownloadFilter/DownloadFilter'
import '../components/FloaterBar'
import '../components/HotspotsSlider'
import '../components/KeyFigure'
import '../components/Layout/Grid'
import '../components/Layout/MediaInfo'
import '../components/Layout/SampleSpacing'
import '../components/ReactComponentSample'
import '../components/ResponsiveImage'
import '../components/SliderHero'
import '../components/CoffeeMachineFinder/ThankYouPageCtaButtons'
import '../components/ThemeSwitcher'
import '../components/Video'
import '../components/StoreLocator'
import '../components/PlannerData'
import '../components/UI/FileUploader'
import '../components/ShareLinks'
import '../components/ProductExplorer'
import '../components/ProductDetailsPage/ProductCarousel'
import '../components/ProductDetailsPage/ProductPrice'
import '../components/ProductDetailsPage/ProductVariantSelectors'
import '../components/SuggestedProductsCarousel/SuggestedProductsCarousel'
import '../components/NewsletterEmbeddedForm/NewsletterEmbedddForm'
import '../components/BlogNewsletterFloatingButton/BlogNewsletterFloatingButton'
import '../components/TeaserSlider/TeaserSlider'
import '@components/StoreLocatorCSV/StoreLocatorCSV'

import Header from '../components/Header'
import HeaderState from '../components/Header/HeaderState'

// #############
// Register them
// #############
register('root-header', (render) => {
  const headerData = (document.querySelector('#root-header') as HTMLElement).dataset

  render(
    <HeaderState headerData={headerData}>
      <Header ariaNavLabel={headerData.ariaNavLabel} />
    </HeaderState>,
  )
})
