import React from 'react'
import ErrorWrapper from '@components/UI/ErrorWrapper'
import { cls } from '@utils/classnames'

const ButtonLink = ({
  onClick,
  text,
  icon,
  className = '',
  ariaLabel,
  hasSpacing = false,
}: {
  onClick?: () => void
  text?: string
  icon?: string
  className?: string
  ariaLabel?: string
  hasSpacing?: boolean
}): JSX.Element => {
  return (
    <ErrorWrapper>
      <button className={`cmp-button-link ${className}`} onClick={onClick} aria-label={ariaLabel || text}>
        {icon && <i className={cls({ 'cmp-button-link__icon': true, [icon]: icon })}></i>}
        {(text && (
          <span
            className={cls({
              'cmp-button-link__menu-item-content': true,
              'cmp-button-link__menu-item-content--has-spacing': hasSpacing,
            })}
          >
            {text}
          </span>
        )) ||
          null}
      </button>
    </ErrorWrapper>
  )
}

export default ButtonLink
