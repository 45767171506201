interface GetDynamicMediaTransformUrlProperties {
  src: string | null
  width: number | null
  height: number | null
  resizeImage?: boolean
}
export function transformUrl({
  src,
  width,
  height,
  resizeImage = true,
}: GetDynamicMediaTransformUrlProperties): string {
  // do not render images if no sizes are provided
  if (!width || !height || !src) {
    return ''
  }

  const hasDynamicMediaProperties = src.includes('fit=crop,1')

  const roundWidth = Math.ceil(width)
  const roundHeight = Math.ceil(height)

  return `${src}${
    resizeImage && !hasDynamicMediaProperties
      ? // eslint-disable-next-line sonarjs/no-nested-template-literals
        `${src.includes('?') ? '&' : '?'}fit=crop,1&wid=${roundWidth}&hei=${roundHeight}`
      : ''
  }`
}
