import React from 'react'
import ButtonLink from '@components/ButtonLink'

const SearchInputField: React.FC<{
  placeholder: string
  value: string
  onChange: (newValue: string) => void
}> = ({ placeholder, value, onChange }) => {
  const inputReference = React.useRef<HTMLInputElement>(null)

  function focusInput() {
    if (inputReference.current) {
      inputReference.current.focus()
    }
  }

  return (
    <div className="search-input-field">
      <ButtonLink icon="ri-search-line" onClick={focusInput} />
      <input
        ref={inputReference}
        className="search-input-field__input"
        onChange={(event) => onChange(event.target.value)}
        value={value}
        placeholder={placeholder}
        aria-label={placeholder}
        name="search"
      />
      <ButtonLink icon="ri-close-line" className={value.length > 0 ? '' : 'hidden'} onClick={() => onChange('')} />
    </div>
  )
}

export default SearchInputField
