import React from 'react'
import { cls } from '@utils/classnames'

const LocationSearchInputField: React.FC<{
  label?: string
  placeholder: string
  value: string
  onChange: (newValue: string) => void
  hasError: boolean
  onSearch: () => void
  onGpsRequest?: () => void
  predictions: google.maps.places.AutocompletePrediction[] | null
  autocompleteIsPending?: boolean
}> = ({
  label,
  placeholder,
  value,
  hasError,
  predictions,
  autocompleteIsPending,
  onChange,
  onSearch,
  onGpsRequest,
}) => {
  function handleGpsKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Enter' && onGpsRequest) {
      onGpsRequest()
    }
  }

  function handleSearchKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Enter') {
      onSearch()
    }
  }

  return (
    <div
      className={cls({
        'cmp-location-search-input-field': true,
        'cmp-location-search-input-field--has-error': hasError,
      })}
    >
      {label && <label className="cmp-location-search-input-field__label">{label}</label>}
      <input
        list="locations"
        className="cmp-location-search-input-field__input"
        placeholder={placeholder}
        value={value}
        onChange={(event) => onChange(event.target.value)}
        onKeyDown={handleSearchKeyDown}
      />
      {predictions && (
        <datalist id="locations" className="cmp-location-search-input-field__autocomplete-datalist">
          {predictions.map((prediction) => (
            <option
              key={prediction.place_id}
              value={prediction.description}
              title={prediction.description}
              className="cmp-location-search-input-field__autocomplete-option"
            >
              {prediction.description}
            </option>
          ))}
        </datalist>
      )}
      <i
        className="cmp-location-search-input-field__icon ri-focus-3-line"
        role="button"
        tabIndex={0}
        onClick={onGpsRequest}
        onKeyDown={handleGpsKeyDown}
      />
      <i
        className={cls({
          'cmp-location-search-input-field__icon': true,
          'ri-arrow-right-line': !autocompleteIsPending,
          'ri-refresh-line': autocompleteIsPending,
        })}
        role="button"
        tabIndex={0}
        onClick={onSearch}
        onKeyDown={handleSearchKeyDown}
      />
    </div>
  )
}

export default LocationSearchInputField
