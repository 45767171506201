import React, { useEffect, useMemo, useState } from 'react'
import { registerComponent } from 'react-register-dom'
import { initFloaterButton } from '@components/FloaterBar/floaterButtonEvents'
import Accordion from '@components/UI/Accordion'
import ErrorWrapper from '@components/UI/ErrorWrapper'
import { cls } from '@utils/classnames'

import FloaterBarItem from './FloaterBarItem'

declare global {
  interface Window {
    adobeDataLayer: any
  }
}

const FloaterBar: React.FC<{ jsonData: string }> = ({ jsonData }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const floaterBarData = useMemo(() => JSON.parse(jsonData).floaterButtons, [jsonData])

  useEffect(() => {
    for (const element of floaterBarData) {
      if (element.buttonType === 'application') {
        initFloaterButton(element.applicationType)
      }
    }
  }, [floaterBarData])

  return (
    <ErrorWrapper>
      <div className="cmp-floater-bar__container">
        <div className="cmp-floater-bar__actions">
          {floaterBarData.slice(0, 2).map((item) => (
            <FloaterBarItem floaterItem={item} key={item.id} />
          ))}
          <Accordion isOpen={isExpanded}>
            {floaterBarData.slice(2).map((item) => (
              <FloaterBarItem floaterItem={item} key={item.id} />
            ))}
          </Accordion>
        </div>

        {floaterBarData.length > 2 && (
          <div
            className={cls({
              'cmp-floater-bar__toggle': true,
              'cmp-floater-bar__toggle-expanded': isExpanded,
              'cmp-floater-bar__toggle-closed': !isExpanded,
            })}
          >
            <div
              className="cmp-floater-bar__toggle-icon"
              role="button"
              tabIndex={0}
              onKeyDown={() => setIsExpanded((current) => !current)}
              onClick={() => {
                setIsExpanded((current) => !current)
              }}
            >
              <i className="ri-close-fill"></i>
            </div>
          </div>
        )}
      </div>
    </ErrorWrapper>
  )
}

registerComponent('FloaterBar', FloaterBar)

export default FloaterBar
