import React from 'react'
import { cls } from '@utils/classnames'

import NavigationProductWrapper from '../Navigation/NavigationProducts/NavigationProductWrapper'

interface NavigationWrapperProperties {
  topNavigationData: Record<string, unknown> | null
  isHeaderOpened: boolean
}

const NavigationWrapper: React.FC<NavigationWrapperProperties> = ({
  topNavigationData,
  isHeaderOpened,
}): JSX.Element => {
  return (
    <div
      className={cls({
        'cmp-navigation-wrapper': true,
        'cmp-navigation-wrapper--is-opened': isHeaderOpened && Boolean(topNavigationData),
      })}
    >
      <div className="cmp-navigation-wrapper__inner">
        <NavigationProductWrapper
          interactiveIconGridData={topNavigationData?.interactiveIconGrid}
          backButtonLabel={(topNavigationData?.backButtonLabel as string) || ''}
          teaserData={topNavigationData}
        />
      </div>
    </div>
  )
}

export default NavigationWrapper
