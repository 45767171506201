import React from 'react'
import ResponsiveImage from '@components/ResponsiveImage'

interface TeaserSliderMainArticleProperties {
  image: { originalImageUrl: string; imageUrl: string; altText: string }
  formattedPublicationDate: string
  blogTags: string[]
  title: string
  description: string
  pageLink: { externalizedURL?: string; isTargetBlank: boolean }
  buttonLabel: string
}

const TeaserSliderMainArticle: React.FC<TeaserSliderMainArticleProperties> = ({
  image,
  formattedPublicationDate,
  blogTags,
  title,
  description,
  pageLink,
  buttonLabel,
}: TeaserSliderMainArticleProperties) => {
  return (
    <div className="cmp-teaser-slider__main-item">
      <div className="cmp-teaser-slider__main-image">
        <ResponsiveImage src={image.imageUrl || image.originalImageUrl} alt={image.altText} />
      </div>
      <div className="cmp-teaser-slider__main-content">
        <div className="cmp-teaser-slider__main-meta">
          <span className="cmp-teaser-slider__main-date">{formattedPublicationDate}</span>
          <div className="cmp-blog-tag">
            <ul className="cmp-blog-tag__list">
              <li className="cmp-blog-tag__list-item">{blogTags[0]}</li>
              {blogTags.length > 1 ? <li className="cmp-blog-tag__list-item">{`+ ${blogTags.length - 1}`}</li> : null}
            </ul>
          </div>
        </div>
        <h3 className="cmp-teaser-slider__main-title">{title}</h3>
        <div className="cmp-teaser-slider__main-description">{description}</div>
        <a
          href={pageLink.externalizedURL}
          target={pageLink.isTargetBlank ? '_blank' : ''}
          rel={pageLink.isTargetBlank ? 'noopener noreferrer' : ''}
          className="cmp-teaser-slider__main-cta"
        >
          {buttonLabel}
        </a>
      </div>
    </div>
  )
}

export default TeaserSliderMainArticle
