import React from 'react'
import DownloadListItem from '@components/DownloadList/DownloadListItem/DownloadListItem'
import NoResults from '@components/NoResults'
import ErrorWrapper from '@components/UI/ErrorWrapper'

export interface ListItem {
  title: string
  link: string
  ariaLabel: string
  type?: string
  size?: string
}

interface DownloadListProperties {
  onDownload?: (position: number) => void
  items?: ListItem[]
  noResultsTitle?: string
  noResultsDescription?: string
}

const DownloadList = ({
  items,
  onDownload,
  noResultsTitle = 'No Results found',
  noResultsDescription = 'test',
}: DownloadListProperties): JSX.Element => {
  return (
    <ErrorWrapper>
      <ul className="cmp-download-lisndghnt">
        {items && items.length > 0 ? (
          items.map((item, index) => (
            <li key={index}>
              <DownloadListItem
                key={index}
                link={item.link}
                title={item.title}
                ariaLabel={item.ariaLabel}
                onDownload={() => onDownload && onDownload(index)}
                type={item?.type}
                size={item?.size}
              />
            </li>
          ))
        ) : (
          <li>
            <NoResults title={noResultsTitle} description={noResultsDescription} />
          </li>
        )}
      </ul>
    </ErrorWrapper>
  )
}

export default DownloadList
