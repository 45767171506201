import React from 'react'
import { useCountdown } from '@hooks/useCountdown'

import Counter from '../Counter'

interface CountdownTimerProperties {
  date: string
  daysLabel: string
  hoursLabel: string
  minutesLabel: string
  secondsLabel: string
}

const CountdownTimer: React.FC<CountdownTimerProperties> = ({
  date,
  daysLabel,
  hoursLabel,
  minutesLabel,
  secondsLabel,
}: CountdownTimerProperties) => {
  const [days, hours, minutes, seconds] = useCountdown(date)

  return days + hours + minutes + seconds <= 0 ? (
    <div className="cmp-countdown-timer--is-expired">
      <span>Expired!!!</span>
    </div>
  ) : (
    <Counter
      days={days}
      hours={hours}
      minutes={minutes}
      seconds={seconds}
      daysLabel={daysLabel}
      hoursLabel={hoursLabel}
      minutesLabel={minutesLabel}
      secondsLabel={secondsLabel}
    />
  )
}

export default CountdownTimer
