import { useEffect, useState } from 'react'

const useCountdown = (targetDate) => {
  // date sent from BE
  const countDownDate = Date.parse(targetDate)

  // current date convert to UTC
  const utcCountdownDate = new Date(Date.now()).toUTCString()
  const utcCountdownDateParsed = Date.parse(utcCountdownDate)

  const [countDown, setCountDown] = useState(countDownDate - utcCountdownDateParsed)

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - Date.parse(new Date(Date.now()).toUTCString()))
    }, 1000)

    return () => clearInterval(interval)
  }, [countDownDate])

  return getReturnValues(countDown)
}

const getReturnValues = (countDown) => {
  // calculate time left
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24))
  const hours = Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000)

  return [days, hours, minutes, seconds]
}

export { useCountdown }
