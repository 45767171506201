import React from 'react'
import { cls } from '@utils/classnames'

interface NoResultsProperties {
  title: string
  description: string
  onDismiss?: () => void
  isError?: boolean
  hasSpacing?: boolean
}

const NoResults: React.FC<NoResultsProperties> = ({
  title,
  description,
  onDismiss = null,
  isError = false,
  hasSpacing = false,
}) => {
  function handleDismissKeyUp(event: React.KeyboardEvent) {
    if (event.key === 'Enter' && onDismiss) {
      onDismiss()
    }
  }

  return (
    <div
      className={cls({
        'cmp-no-results': true,
        'cmp-no-results--is-error': isError,
        'cmp-no-results--has-spacing': hasSpacing,
      })}
    >
      <i
        className={cls({
          'cmp-no-results__icon': true,
          'ri-close-circle-line': isError,
          'ri-information-line': !isError,
        })}
      />
      <div className="cmp-no-results__content">
        <p className="cmp-no-results__title">{title}</p>
        <p className="cmp-no-results__description">{description}</p>
      </div>
      {onDismiss && (
        <i
          className="ri-close-line cmp-no-results__icon"
          onClick={onDismiss}
          tabIndex={0}
          role="button"
          onKeyUp={handleDismissKeyUp}
        />
      )}
    </div>
  )
}

export default NoResults
