import { useEffect, useState } from 'react'

interface ScriptLoaderProperties {
  children: JSX.Element
  googleMapsApiKey: string | null
  onScriptReady: () => void
}

const callbackName = 'mapInitCompleted'
const globalLoadedFlag = 'hasLoadedGmapsAlready'
const scriptId = 'gmapScriptLoader'

export function ScriptLoader({ children, googleMapsApiKey, onScriptReady }: ScriptLoaderProperties) {
  const [ready, setReady] = useState(false)

  useEffect(() => {
    const callback = () => {
      window[globalLoadedFlag] = true
      setReady(true)
      onScriptReady()
    }

    const scriptElement = document.querySelector(`#${scriptId}`) as HTMLScriptElement
    if (scriptElement) {
      const hasLoaded = window[globalLoadedFlag]

      if (!hasLoaded) {
        const old = window[callbackName]

        window[callbackName] = () => {
          old()
          callback()
        }
      }
    } else {
      window[callbackName] = callback
      const script = document.createElement('script')
      script.id = scriptId
      script.src = `https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&callback=${callbackName}&libraries=places`
      script.async = true
      document.head.append(script)
    }
  }, [])

  return ready ? <>{children}</> : null
}
