import React from 'react'
import ButtonLink from '@components/ButtonLink'

interface MainMenuProperties {
  onClick: () => void
  buttonLabel: string
  ariaLabel: string
}
const MainMenu = ({ onClick, buttonLabel, ariaLabel }: MainMenuProperties): JSX.Element => {
  return <ButtonLink onClick={onClick} text={buttonLabel} ariaLabel={ariaLabel} icon="ri-menu-2-line"></ButtonLink>
}

export default MainMenu
