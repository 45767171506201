// Example of how a component should be initialized via JavaScript
// This script logs the value of the component's text property model message to the console
// eslint-disable-next-line sonarjs/cognitive-complexity
;(function () {
  'use strict'

  function onDocumentReady() {
    const cmpProductInformationTableClassName = '.cmp-product-information-table'
    const cmpButtonHide = 'cmp-product-information__cta-button--hide'
    const cmpProductInformationTableHiddenClassName = 'cmp-product-information-table--hidden'

    const productInformationList = document?.querySelector(cmpProductInformationTableClassName)
    if (!Boolean(productInformationList)) {
      return
    }

    const showMoreButton = document?.querySelector('.cmp-product-information__cta-button--more')
    const showLessButton = document?.querySelector('.cmp-product-information__cta-button--less')

    // eslint-disable-next-line prettier/prettier
    if (productInformationList.children.length <= 6) {
      showMoreButton.classList.add(cmpButtonHide)
      showLessButton.classList.add(cmpButtonHide)
    }

    if (productInformationList.children.length > 6) {
      productInformationList.classList.add(cmpProductInformationTableHiddenClassName)
      showLessButton.classList.add(cmpButtonHide)
    }

    function toggleProductInformation(visibility) {
      if (visibility === 'show') {
        productInformationList.classList.remove(cmpProductInformationTableHiddenClassName)
        showMoreButton.classList.add(cmpButtonHide)
        showLessButton.classList.remove(cmpButtonHide)
      } else if (visibility === 'hide') {
        productInformationList.classList.add(cmpProductInformationTableHiddenClassName)
        showLessButton.classList.add(cmpButtonHide)
        showMoreButton.classList.remove(cmpButtonHide)
      }
    }

    showMoreButton?.addEventListener('click', () => {
      toggleProductInformation('show')
    })
    showLessButton?.addEventListener('click', () => {
      toggleProductInformation('hide')
      document?.querySelector('.cmp-product-information').scrollIntoView({ behavior: 'smooth' })
    })
  }

  if (document.readyState !== 'loading') {
    onDocumentReady()
  } else {
    document.addEventListener('DOMContentLoaded', onDocumentReady)
  }
})()
