import React from 'react'
import ButtonLink from '@components/ButtonLink'

interface HamburgerProperties {
  onClick: () => void
  ariaLabel: string
}

const Hamburger = ({ onClick, ariaLabel }: HamburgerProperties): JSX.Element => {
  return <ButtonLink onClick={onClick} ariaLabel={ariaLabel} icon="ri-menu-2-line" />
}

export default Hamburger
