import { FC, RefObject } from 'react'
import { HotspotTeaser } from '@components/HotspotsSlider/HotspotTeaser/HotspotTeaser'
import ResponsiveImage from '@components/ResponsiveImage'
import { cls } from '@utils/classnames'

import { HostpotsSliderImage } from './HotspotsTypes'

interface HotspotsSlideProperties {
  image: HostpotsSliderImage
  onHotspotClick: (id) => void
  onHotspotClose: () => void
  activeHotspot: string | null
  sliderRef: RefObject<HTMLDivElement>
}

export const HostpotsSlide: FC<HotspotsSlideProperties> = ({
  image,
  onHotspotClick,
  onHotspotClose,
  activeHotspot,
  sliderRef,
}) => {
  return (
    <div className="cmp-hotspots-slide">
      <ResponsiveImage
        src={image.image.originalImageUrl}
        alt={image.image.altText || ''}
        className="cmp-hotspots-slide__image"
      />
      {image?.hotspots?.map((hotspot, index) => {
        const hotspotId = `${index}-${hotspot.title}` // Unique ID for hotspot teaser
        return (
          <div
            key={index}
            style={{
              top: `${hotspot?.positionVertical}%`,
              left: `${hotspot?.positionHorizontal}%`,
            }}
            className={cls({
              'cmp-hotspots-slide__hotspot-container': true,
              'cmp-hotspots-slide__hotspot-container--is-active': activeHotspot === hotspotId,
            })}
          >
            <button
              className="cmp-hotspots-slide__hotspot"
              onClick={() => onHotspotClick(hotspotId)}
              onKeyDown={(event) => (event.key === 'Enter' ? onHotspotClick(hotspotId) : 0)}
              aria-label={hotspot?.title}
              tabIndex={0}
            >
              <span className="cmp-hotspots-slide__hotspot-dot"></span>
            </button>

            <HotspotTeaser
              hotspot={hotspot}
              isVisible={activeHotspot === hotspotId}
              onCloseClick={onHotspotClose}
              sliderRef={sliderRef}
            />
          </div>
        )
      })}
    </div>
  )
}
