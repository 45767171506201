import { useState } from 'react'

const useModal = (startAsOpen = false) => {
  const [isShowing, setIsShowing] = useState(startAsOpen)

  const toggleModal = () => {
    setIsShowing((oldValue) => {
      return !oldValue
    })
  }

  return {
    isShowing,
    toggleModal,
  }
}

export default useModal
