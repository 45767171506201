import React from 'react'

import { CoffeeMachineFeature } from '../CoffeeMachineFinderTypes'

const CoffeeMachineProductFeature: React.FC<{
  feature: CoffeeMachineFeature
}> = ({ feature }) => {
  return (
    <div className="coffee-machine__product-feature">
      <h5 className="coffee-machine__product-feature__name">{feature.name}</h5>
      {feature.capabilities.map((cap) => (
        <p className="coffee-machine__product-feature__item" key={cap}>
          {cap}
        </p>
      ))}
    </div>
  )
}

export default CoffeeMachineProductFeature
