import React from 'react'

import { QuestionnaireConfig } from '../CoffeeMachineFinderTypes'
import StepperIcon from '../StepperIcon'
import StepperLine from '../StepperLine'

const Stepper: React.FC<{
  config: QuestionnaireConfig
  currentStep: number
  onStepClick: (stepIndex: number) => void
}> = ({ config, currentStep, onStepClick }) => {
  return (
    <div className="coffee-machine-finder__stepper">
      {config.map((stepConfig, index) =>
        !stepConfig?.hideIconStep ? (
          <React.Fragment key={stepConfig.id}>
            <StepperIcon
              past={currentStep > index}
              current={currentStep === index}
              src={stepConfig.iconSrc}
              title={stepConfig.title}
              onStepClick={() => onStepClick(index)}
            />
            {index < config.length - 1 && (
              <StepperLine
                past={currentStep > index}
                current={currentStep === index}
                hideLine={Object.values(config)[index + 1]?.hideIconStep || false}
              />
            )}
          </React.Fragment>
        ) : null,
      )}
    </div>
  )
}

export default Stepper
