import React, { useEffect } from 'react'
import { createPortal } from 'react-dom'
import { registerComponent } from 'react-register-dom'
import { useOnEventOutside } from '@hooks/useOnEventOutside'
import { cls } from '@utils/classnames'
import { isMobile } from '@utils/mediaQueries'
import FocusTrap from 'focus-trap-react'

const Modal: React.FC<{
  closeButtonLabel?: string
  closeButtonAriaLabel?: string
  ariaLabel?: string
  isShowing: boolean
  isFullSize?: boolean
  hideCloseButton?: boolean
  className?: string
  hideAnimation?: boolean
  closeModal: () => void
}> = ({
  closeButtonLabel,
  closeButtonAriaLabel,
  ariaLabel,
  children,
  isShowing,
  isFullSize,
  className = '',
  hideCloseButton,
  hideAnimation,
  closeModal,
}) => {
  const reference = useOnEventOutside({
    eventType: 'click',
    callBack: closeModal,
    shouldHandleKeyUp: true,
  })

  useEffect(() => {
    const bodyElement = document.querySelector('body')
    bodyElement?.classList[isShowing ? 'add' : 'remove']('no-scroll')
    bodyElement?.setAttribute('aria-hidden', Boolean(isShowing).toString())

    return () => {
      bodyElement?.classList.remove('no-scroll') // in case the modal gets destroyed
    }
  }, [isShowing])

  if (!isShowing) {
    return null
  }

  return createPortal(
    <FocusTrap active={isShowing}>
      <div
        className={cls({
          'cmp-modal': true,
          'cmp-modal--is-closed': !isShowing,
          'cmp-modal--is-full-size': isFullSize,
          [className]: className,
        })}
        role="dialog"
        aria-modal="true"
        aria-label={ariaLabel}
      >
        <div className="cmp-modal__wrapper" ref={reference}>
          <div
            className={cls({
              'cmp-modal__inner-content': true,
              'cmp-modal__inner-content--no-animation': hideAnimation,
            })}
          >
            <button
              className={cls({
                'cmp-modal__close-button': true,
                'cmp-modal__close-button--is-hidden': hideCloseButton,
              })}
              aria-label={closeButtonAriaLabel}
              onClick={closeModal}
            >
              {closeButtonLabel}
            </button>

            {children}
          </div>
        </div>
      </div>
    </FocusTrap>,
    document.body,
  )
}

registerComponent('Modal', Modal)

export default Modal
