import React from 'react'
import { registerComponent } from 'react-register-dom'
import MultiSelectFilter from '@components/ProductExplorer/MultiSelectFilter'
import { MultiSelectOption } from '@components/ProductExplorer/ProductExplorerTypes'

import { Separator } from '../PdpTypes'

interface ProductVariantSelectorsConfig {
  separators: Separator[]
  baseUrl: string
  variantIdPlaceholder: string
  infoMessageTitle: string
  infoMessageDescription: string
}

interface ProductVariantSelectorsProperties {
  jsonData: string
}

const ProductVariantSelectors: React.FC<ProductVariantSelectorsProperties> = ({ jsonData }) => {
  const config: ProductVariantSelectorsConfig = React.useMemo(() => {
    return JSON.parse(jsonData) as ProductVariantSelectorsConfig
  }, [jsonData])

  return (
    <div className="cmp-product-variant-selectors">
      {config.separators.map((variant) => (
        <ProductVariantSelector
          key={variant.attributeCode}
          separator={variant}
          baseUrl={config.baseUrl}
          variantIdPlaceholder={config.variantIdPlaceholder}
          infoMessageTitle={config.infoMessageTitle}
          infoMessageDescription={config.infoMessageDescription}
        />
      ))}
    </div>
  )
}

interface ProductVariantSelectorProperties {
  separator: Separator
  baseUrl: string
  variantIdPlaceholder: string
  infoMessageTitle: string
  infoMessageDescription: string
}

const ProductVariantSelector: React.FC<ProductVariantSelectorProperties> = ({
  separator,
  baseUrl,
  variantIdPlaceholder,
  infoMessageTitle,
  infoMessageDescription,
}) => {
  const initialValue = new Set<string>()
  initialValue.add(separator.currentVariantValue)

  const [selectedOptions] = React.useState(initialValue)

  function navigateToVariantPage(_: string, selectedOptions: Set<string>) {
    if (selectedOptions.size === 1) {
      const variantId = selectedOptions.entries().next().value[0]
      let selectedVariantId = ''

      for (const option of separator.options) {
        if (option.value === variantId) {
          selectedVariantId = option.variantId
        }
      }

      const targetUrl = baseUrl.replace(variantIdPlaceholder, selectedVariantId)
      location.href = targetUrl
    }
  }

  const separatorsAsFilterOptions = React.useMemo(
    () =>
      separator.options.map<MultiSelectOption>((option) => ({
        label: option.label,
        imageUrl: option.imageUrl,
        value: option.value,
        showInfoIcon: option.isPartialMatch,
        infoMessageTitle: infoMessageTitle,
        infoMessageDescription: infoMessageDescription,
      })),

    [separator],
  )

  return (
    <div className="cmp-product-variant-selectors__dropdown">
      <div className="cmp-product-variant-selectors__title">{separator.label}</div>
      <MultiSelectFilter
        variant="product-variants-selector"
        filterId={separator.attributeCode}
        options={separatorsAsFilterOptions}
        popoverTitle={separator.label}
        onSelectedOptionsChange={navigateToVariantPage}
        triggerLabel={separator.label}
        selectionMode="single"
        selectedOptions={selectedOptions}
      />
    </div>
  )
}

export default ProductVariantSelectors

registerComponent('ProductVariantSelectors', ProductVariantSelectors)
