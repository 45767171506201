import React, { useRef } from 'react'
import { useInView } from 'react-intersection-observer'
import { registerComponent } from 'react-register-dom'
import { useElementObserver } from '@hooks/useElementObserver'
import { transformUrl } from '@service/dynamicMedia'
import { cls } from '@utils/classnames'
import { getBooleanFromValue } from '@utils/getBooleanFromValue'

type StringOrBoolean = string | boolean

interface ResponsiveImageProperties {
  src: string
  alt: string
  shouldLazyLoad?: StringOrBoolean
  className?: string
  style?: Record<string, unknown>
  resizeImage?: boolean
  fetchPriorityHigh?: boolean
  srcSet?: string
}

interface IntersectionObserverEntryWithIsVisible extends IntersectionObserverEntry {
  isVisible: boolean
}

// TODO: add loader / spinner
const ResponsiveImage: React.FC<ResponsiveImageProperties> = ({
  src,
  alt,
  shouldLazyLoad = 'true',
  className = '',
  style = {},
  resizeImage = true,
  fetchPriorityHigh = false,
  srcSet,
}): JSX.Element => {
  const referenceElement = useRef<HTMLDivElement>(null)
  const {
    ref: referenceInView,
    inView,
    entry,
  } = useInView({
    threshold: 0,
    trackVisibility: true,
    delay: 100,
  })
  const {
    sizes: { width, height },
  } = useElementObserver({ referenceElement, inView })
  const hasSizes = Boolean(width > 0 && height > 0)
  const isLazyLoaded = getBooleanFromValue(shouldLazyLoad)
  const isFetchPriorityHigh = getBooleanFromValue(fetchPriorityHigh)
  const canLoadSource = Boolean(hasSizes && src)
  const canKeepRendering = Boolean(inView || !(entry as IntersectionObserverEntryWithIsVisible)?.isVisible)

  return (
    <div
      className={cls({
        'cmp-responsive-image__outer-wrapper': true,
        [className]: className,
      })}
      ref={referenceInView}
      style={style}
    >
      <div className="cmp-responsive-image__inner-wrapper" ref={referenceElement}>
        {canKeepRendering && (
          <img
            className="cmp-responsive-image__image"
            {...(width ? { width } : {})}
            {...(height ? { height } : {})}
            {...(isLazyLoaded ? { loading: 'lazy' as 'eager' | 'lazy' } : {})}
            {...(isFetchPriorityHigh ? { fetchpriority: 'high' } : {})}
            {...(canLoadSource && !srcSet
              ? {
                  src: transformUrl({
                    src,
                    width,
                    height,
                    resizeImage,
                  }),
                }
              : { src })}
            {...(srcSet && { srcSet })}
            alt={alt}
          />
        )}
      </div>
    </div>
  )
}

registerComponent('ResponsiveImage', ResponsiveImage)

export default ResponsiveImage
