import React from 'react'
import ProductListItem from '@components/ProductExplorer/ProductListItem/'

import { ProductExplorerTexts, ProductSnapshot } from '../ProductExplorerTypes'
interface ProductListProperties {
  products: ProductSnapshot[]
  productBaseUrl: string
  texts: ProductExplorerTexts
}

const ProductList: React.FC<ProductListProperties> = ({ products, productBaseUrl, texts }) => {
  return products ? (
    <ul className="cmp-product-list">
      {products.map((product) => (
        <ProductListItem
          title={product.name}
          link={productBaseUrl.replace(':productId', product.id)}
          image={product.imageUrl || ''}
          colors={product.colorVariants || []}
          key={product.id}
          missingImageAltText={texts.missingImageAltText}
          productVariantLabel={texts?.productVariantLabel}
          hasMultipleVariants={product?.hasMultipleVariants}
        />
      ))}
    </ul>
  ) : null
}

export default ProductList
