import React from 'react'
import { registerComponent } from 'react-register-dom'
import ResponsiveImage from '@components/ResponsiveImage'

interface CoffeeMachineModalContentProperties {
  headline: string
  description: string
  ctaButtonLabel: string
  ctaButtonLink: string
  image: string
  imageAlt: string
  resizeImage: boolean
}

const CoffeeMachineModalContent: React.FC<CoffeeMachineModalContentProperties> = ({
  headline,
  description,
  ctaButtonLabel,
  ctaButtonLink,
  image,
  imageAlt,
  resizeImage,
}) => {
  return (
    <>
      <div className="cmp-coffee-machine-modal-content__image">
        <ResponsiveImage src={image} alt={imageAlt} resizeImage={resizeImage} />
      </div>
      <div className="cmp-coffee-machine-modal-content__wrapper">
        <div className="cmp-coffee-machine-modal-content__title">{headline}</div>
        <div className="cmp-coffee-machine-modal-content__description">{description}</div>
        <a href={ctaButtonLink} className="cmp-coffee-machine-modal-content__cta">
          {ctaButtonLabel}
        </a>
      </div>
    </>
  )
}

registerComponent('CoffeeMachineModalContent', CoffeeMachineModalContent)

export default CoffeeMachineModalContent
