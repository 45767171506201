import React from 'react'
import { cls } from '@utils/classnames'

import { DOTS, usePagination } from './usePagination'

interface PageCounterProperties {
  totalElements: number
  reference: React.MutableRefObject<any>
  currentPage: number
  maxDisplayedElementCount: number
  pageSize: number
  onPageChange: (newValue: number) => void
}

const PageCounter: React.FC<PageCounterProperties> = ({
  totalElements,
  currentPage,
  reference,
  maxDisplayedElementCount,
  pageSize,
  onPageChange,
}: PageCounterProperties) => {
  const paginationRange = usePagination(totalElements, pageSize, maxDisplayedElementCount, currentPage)
  const lastPage = paginationRange[paginationRange.length - 1]

  function scrollToReference() {
    reference?.current?.scrollIntoView()
  }

  function onNext() {
    if (currentPage !== lastPage) {
      onPageChange(currentPage + 1)
      scrollToReference()
    }
  }

  function onPrevious() {
    if (currentPage !== 1) {
      onPageChange(currentPage - 1)
      scrollToReference()
    }
  }

  function handlePageChangeKeydown(event: React.KeyboardEvent<HTMLElement>, pageNumber: number) {
    if (event.key === 'Enter') {
      onPageChange(pageNumber)
      scrollToReference()
    }
  }

  function handlePreviousChange(event: React.KeyboardEvent<HTMLElement>) {
    if (event.key === 'Enter') {
      onPrevious()
    }
  }

  function handleNextChange(event: React.KeyboardEvent<HTMLElement>) {
    if (event.key === 'Enter') {
      onNext()
    }
  }

  return paginationRange?.length > 1 ? (
    <ul className="cmp-page-counter">
      <li
        className={cls({
          'cmp-page-counter__item': true,
          'cmp-page-counter__item--disabled': currentPage === 1,
        })}
        onClick={onPrevious}
        onKeyPress={handlePreviousChange}
        role="tab"
        tabIndex={0}
      >
        <div className="cmp-page-counter__item--arrow-left" />
      </li>
      {paginationRange.map((pageNumber, index) => {
        if (pageNumber === DOTS) {
          return (
            <li className="cmp-page-counter__item cmp-page-counter__item--dots" key={index}>
              ..
            </li>
          )
        }

        return (
          <li
            className={cls({
              'cmp-page-counter__item': true,
              'cmp-page-counter__item--selected': pageNumber === currentPage,
            })}
            key={index}
            onClick={() => {
              onPageChange(pageNumber)
              scrollToReference()
            }}
            onKeyPress={(event) => handlePageChangeKeydown(event, pageNumber)}
            role="tab"
            tabIndex={0}
          >
            {pageNumber}
          </li>
        )
      })}
      <li
        className={cls({
          'cmp-page-counter__item': true,
          'cmp-page-counter__item--disabled': currentPage === lastPage,
        })}
        onClick={onNext}
        onKeyPress={handleNextChange}
        role="tab"
        tabIndex={0}
      >
        <div className="cmp-page-counter__item--arrow-right" />
      </li>
    </ul>
  ) : null
}

export default PageCounter
