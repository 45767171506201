import React, { FC, RefObject, useEffect, useRef, useState } from 'react'
import DamVideo from '@components/DamVideo'
import { Hotspots } from '@components/HotspotsSlider/HotspotsTypes'
import ResponsiveImage from '@components/ResponsiveImage'
import { isMobile } from '@utils/mediaQueries'
const smallScreen = isMobile()

interface HotspotProperties {
  hotspot: Hotspots
  onHotspotClick?: (index: number) => void
  isVisible: boolean
  onCloseClick: (index: number | null) => void
  ariaLabelCloseButton?: string
  sliderRef: RefObject<HTMLDivElement>
}

export const HotspotTeaser: FC<HotspotProperties> = ({
  hotspot,
  isVisible,
  onCloseClick,
  ariaLabelCloseButton,
  sliderRef,
}) => {
  const hasHotspotsImage = Boolean(hotspot?.image?.imageUrl || hotspot?.image?.originalImageUrl)
  const hasHotspotVideo = hotspot?.isVideo

  const modal = useRef<HTMLDialogElement>(null)

  const handleClose = () => {
    onCloseClick(null)
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (event.target.classList.contains('cmp-hotspot-teaser')) handleClose()
    }
    if (isVisible) {
      modal.current?.showModal()
      document.addEventListener('click', handleClickOutside)
    } else {
      modal.current?.close()
      document.removeEventListener('click', handleClickOutside)
    }
  }, [isVisible])

  useEffect(() => {
    if (sliderRef.current !== null && modal.current !== null && isVisible) {
      const top = Number(hotspot.positionVertical)
      const left = Number(hotspot.positionHorizontal)

      const position: React.CSSProperties = {
        top: 0,
        left: 0,
      }

      const thumbnailsHeight = 48
      const pinWidth = 48
      const margin = 8

      const sliderPosition = sliderRef.current.getBoundingClientRect()
      const modalPosition = modal.current.getBoundingClientRect()
      const headerHeight = document.querySelector('.cmp-header')?.clientHeight || 0

      if (smallScreen) {
        window.scrollTo({
          top: sliderRef.current.offsetTop - headerHeight,
          left: 0,
        })
        position.top = sliderRef.current.offsetTop + sliderPosition.height / 3
        position.left = 0
      } else {
        const positionTop =
          top < 50
            ? sliderPosition.top + sliderPosition.height * (top / 100)
            : sliderPosition.top + sliderPosition.height * (top / 100) - modal.current.offsetHeight + thumbnailsHeight
        // make sure that the hotspot teaser will always stay in the borders of the parent component
        position.top = Math.max(sliderPosition.top, Math.min(positionTop, sliderPosition.bottom - modalPosition.height))
        position.left =
          left > 55
            ? sliderRef.current.offsetLeft +
              sliderRef.current.offsetWidth * (left / 100) -
              (modal.current.offsetWidth + margin)
            : sliderRef.current.offsetLeft + sliderRef.current.offsetWidth * (left / 100) + pinWidth + margin
      }

      modal.current.style.top = `${position.top}px`
      modal.current.style.left = `${position.left}px`

      const isModalOverflown = modal.current.scrollHeight > modal.current.clientHeight

      if (isModalOverflown && !smallScreen) modal.current.style.top = `0px`

      modal.current.style.opacity = '1'
    }
  }, [isVisible, hotspot, sliderRef])

  return (
    <dialog className="cmp-hotspot-teaser" ref={modal}>
      <button className="cmp-hotspot-teaser__close" onClick={handleClose} aria-label={ariaLabelCloseButton}>
        <i className="ri-close-line"></i>
      </button>
      {(hasHotspotVideo || hasHotspotsImage) && (
        <div className="cmp-hotspot-teaser__media">
          {hotspot.isVideo && hotspot.video?.url != null ? (
            <DamVideo
              videoUrl={hotspot.video.url}
              isEditModeBoolean={false}
              isVideoPlaying
              loopVideo="true"
              hideVideoControls="true"
            />
          ) : (
            <ResponsiveImage src={hotspot.image.imageUrl || ''} alt={hotspot.image.altText || ''} />
          )}
        </div>
      )}
      <div className="cmp-hotspot-teaser__description">
        <h4 className="cmp-hotspot-teaser__description-title">{hotspot.title}</h4>
        <p className="cmp-hotspot-teaser__description-text">{hotspot.text}</p>
        {hotspot.link && hotspot.linkLabel && (
          <a
            className="cmp-hotspot-teaser__description-link"
            href={hotspot.link?.externalizedURL}
            target={hotspot.link?.isTargertBlank && '_blank'}
            rel={hotspot.link?.htmlAttributes?.rel || 'nofollow'}
          >
            {hotspot.linkLabel} <i className="ri-arrow-right-line" />
          </a>
        )}
      </div>
    </dialog>
  )
}
