import React from 'react'

const LoadingIndicator = () => {
  return (
    <div className="cmp-loading-indicator">
      <div id="loading-indicator" role="progressbar" className="cmp-loading-indicator__svg-wrapper">
        <svg viewBox="22 22 44 44" className="cmp-loading-indicator__svg">
          <circle cx="44" cy="44" r="20.2" fill="none" strokeWidth="3.6" className="cmp-loading-indicator__circle" />
        </svg>
      </div>
    </div>
  )
}

export default LoadingIndicator
