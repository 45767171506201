import React from 'react'
import ErrorWrapper from '@components/UI/ErrorWrapper'
export interface LogoProperties {
  backgroundImage: string
  foregroundImage: string
  altText: string
  logoLink: string
}

const Logo: React.FC<LogoProperties> = ({ backgroundImage, foregroundImage, altText, logoLink }: LogoProperties) => {
  return (
    <ErrorWrapper>
      <a className="cmp-logo" href={logoLink} aria-label={altText}>
        <img src={backgroundImage} alt={altText} className="cmp-logo__background" width="150" height="50" />
        <img src={foregroundImage} alt={altText} className="cmp-logo__foreground" width="150" height="50" />
      </a>
    </ErrorWrapper>
  )
}

export default Logo
