import React from 'react'
interface DownloadFilterListItemProperties {
  label: string
  link: string
  size?: string
  type?: string
}
const DownloadFilterListItem: React.FC<DownloadFilterListItemProperties> = ({ label, link, type, size }) => {
  return (
    <li className="cmp-download-filter-list-item">
      <a href={link} className="cmp-download-filter-list-item__link">
        <span className="cmp-download-filter-list-item__title">
          {label}
          <small className="cmp-download-list__file-info">
            {type}
            {size && <span> ({size})</span>}
          </small>
        </span>
        <span className="cmp-download-filter-list-item__icon">
          <i className="ri-download-line"></i>
        </span>
      </a>
    </li>
  )
}

export default DownloadFilterListItem
