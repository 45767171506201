import React from 'react'
import { cls } from '@utils/classnames'

import { PartnerTag } from '../StoreLocatorTypes'

interface LabelProperties {
  tag: PartnerTag
  isActive?: boolean
  isPremium?: boolean
  onClick: () => void
}

const Label: React.FC<LabelProperties> = ({ tag, isActive, isPremium, onClick }) => {
  return (
    <button
      onClick={onClick}
      className={cls({
        'cmp-label': true,
        'cmp-label--is-active': isActive,
        'cmp-label--is-premium': isPremium,
      })}
    >
      <span className="cmp-label__label">{tag.label}</span>
    </button>
  )
}

export default Label
