;(function () {
  'use strict'

  const CMP_FORM_TEXT = 'cmp-form-text'
  const CMP_FORM_OPTIONS = 'cmp-form-options'

  const classByType = {
    textarea: CMP_FORM_TEXT,
    'select-one': CMP_FORM_OPTIONS,
    text: CMP_FORM_TEXT,
    email: CMP_FORM_TEXT,
    tel: CMP_FORM_TEXT,
    checkbox: CMP_FORM_OPTIONS,
    radio: CMP_FORM_OPTIONS,
  }

  function onDocumentReady() {
    const form = document.getElementById('contact_form')
    const formElements = form?.querySelectorAll('[name]')
    const submitButton = form?.querySelector('button[type=submit]')

    if (!submitButton) {
      return
    }

    formElements?.forEach((element) => {
      const elementType = element.type.toLowerCase()
      const elementParent = element.parentNode

      element.addEventListener(
        'invalid',
        (e) => {
          e.preventDefault()
          e.stopPropagation()
          elementParent.classList.add(`${classByType[elementType]}--is-invalid`)
          submitButton.disabled = true
        },
        false,
      )

      element.addEventListener(
        'change',
        (e) => {
          e.preventDefault()
          e.stopPropagation()
          elementParent.classList.add(`${classByType[elementType]}--is-interacted`)
          // element.validity.valid
          elementParent.classList[element.checkValidity() ? 'remove' : 'add'](`${classByType[elementType]}--is-invalid`)

          const invalidElementsNumber = form?.getElementsByClassName(`${classByType[elementType]}--is-invalid`).length

          submitButton.disabled = invalidElementsNumber > 0 ? true : false
        },
        false,
      )
    })
  }

  if (document.readyState !== 'loading') {
    onDocumentReady()
  } else {
    document.addEventListener('DOMContentLoaded', onDocumentReady)
  }
})()
