import React from 'react'

export interface BreadcrumbItemProperties {
  link: {
    externalizedURL: string
  }
  active: boolean
  title?: string
  id?: string
}

const BreadcrumbItem = ({ link, active, title }: BreadcrumbItemProperties) => {
  return active ? (
    <li className="cmp-breadcrumb__item cmp-breadcrumb__item--active" aria-current="page">
      <span className="cmp-breadcrumb__item-title">{title}</span>
    </li>
  ) : (
    <li className="cmp-breadcrumb__item">
      <a className="cmp-breadcrumb__item-link" href={link.externalizedURL}>
        <span>{title}</span>
      </a>
    </li>
  )
}

export default BreadcrumbItem
