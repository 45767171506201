import React from 'react'
import { cls } from '@utils/classnames'
import { formatBytes } from '@utils/formatBytes'
import { isSupportedFileType } from '@utils/isSupportedFileType'

interface SupportedFilesListProperties {
  supportedFiles: File[]
  onSupportedFileRemove: (file: File) => void
  buttonRemoveAriaLabel?: string
}

const SupportedFilesList: React.FC<SupportedFilesListProperties> = ({
  supportedFiles,
  onSupportedFileRemove,
  buttonRemoveAriaLabel,
}: SupportedFilesListProperties) => {
  return supportedFiles ? (
    <ul className="cmp-file-uploader-drag-and-drop__list">
      {supportedFiles.map((fileName) => (
        <li
          key={fileName.name}
          className={cls({
            'cmp-file-uploader-drag-and-drop__list-item': true,
            'cmp-file-uploader-drag-and-drop__list-item--invalid': !isSupportedFileType(fileName.type),
          })}
        >
          <div className="cmp-file-uploader-drag-and-drop__file-info">
            <div className="cmp-file-uploader-drag-and-drop__file-icon">
              {fileName?.type.match('image') ? <i className="ri-image-line"></i> : <i className="ri-file-line"></i>}
            </div>
            <div className="cmp-file-uploader-drag-and-drop__name-size">
              <div className="cmp-file-uploader-drag-and-drop__file-name">
                {fileName.name.slice(0, Math.max(0, fileName.name.lastIndexOf('.')))}
              </div>
              <div className="cmp-file-uploader-drag-and-drop__file-size">
                {fileName.type.split('/')[1]}, {formatBytes(fileName.size)}
              </div>
            </div>
          </div>
          <div className="cmp-file-uploader-drag-and-drop__file-actions">
            <button
              onClick={() => onSupportedFileRemove(fileName)}
              aria-label={buttonRemoveAriaLabel}
              className="cmp-file-uploader-drag-and-drop__file-actions--remove"
            >
              <i className="ri-close-line"></i>
            </button>
          </div>
        </li>
      ))}
    </ul>
  ) : null
}

export default SupportedFilesList
