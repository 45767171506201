import * as React from 'react'
import { cls } from '@utils/classnames'

export interface CountryLanguageSwitcher {
  id: string
  countrySwitchLabel: string
  searchPlaceholder: string
  hideSearch?: boolean
  /** currently active country - to match with title */
  countryPageLabel: string
  items: CountryLanguageCountry[]
}

interface CountryLanguageCountry {
  title: string
  languages: SwitcherNode[]
}

interface SwitcherNode {
  link: {
    externalizedURL: string
  }
  /** to be displayed */
  title: string
  /** currently active page */
  active: boolean
}

const LanguageSwitcherCountry: React.FC<{
  countryNode: CountryLanguageCountry
  countryPageLabel: string
}> = ({ countryNode, countryPageLabel }) => {
  const isCountrySelected = countryNode.title === countryPageLabel

  return (
    <div
      className={cls({
        'language-switcher__country': true,
        'language-switcher__country--active': isCountrySelected,
      })}
    >
      <p className="language-switcher__country-name">{countryNode.title}</p>
      <p>
        {countryNode.languages.map((languageNode) => (
          <a
            key={languageNode.title}
            href={languageNode.link.externalizedURL}
            className={cls({
              'language-switcher__country-language': true,
              'language-switcher__country-language--active': languageNode.active,
            })}
          >
            {languageNode.title}
          </a>
        ))}
      </p>
    </div>
  )
}

export default React.memo(LanguageSwitcherCountry)
