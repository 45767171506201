import { FC, SetStateAction, useCallback, useEffect, useRef, useState } from 'react'
import { OverlayContainer, useButton, useOverlayPosition, useOverlayTrigger } from 'react-aria'
import { useOverlayTriggerState } from 'react-stately'
import { useOnScrolls } from '@hooks/useOnScroll'
import { cls } from '@utils/classnames'
import { isMobile } from '@utils/mediaQueries'

import MultiSelectListBox from '../MultiSelectListBox'
import { MultiSelectOption } from '../ProductExplorerTypes'

import MultiSelectFilterPopover from './MultiSelectFilterPopover'

interface MultiSelectFilterProperties {
  selectionMode: 'single' | 'multiple'
  options: MultiSelectOption[]
  triggerLabel: string
  popoverTitle: string
  applyLabel?: string
  resetLabel?: string
  selectedOptions: Set<string>
  filterId: string
  variant?: 'product-variants-selector'
  onSelectedOptionsChange: (filterId: string, selectedOptions: Set<string>) => void
}

const MultiSelectFilter: FC<MultiSelectFilterProperties> = ({
  selectionMode,
  options,
  triggerLabel,
  popoverTitle,
  applyLabel,
  resetLabel,
  selectedOptions,
  filterId,
  variant,
  onSelectedOptionsChange,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const { direction } = useOnScrolls()
  const [isClicked, setIsClicked] = useState(false)
  const overlayTriggerState = useOverlayTriggerState({})
  const [triggerWidth, setTriggerWidth] = useState(0)
  const [localSelection, setLocalSelection] = useState(selectedOptions || new Set<string>())
  const triggerReference = useRef<HTMLButtonElement>(null)
  const overlayReference = useRef<HTMLDivElement>(null)
  const variantSelectorClassName = 'product-variants-selector'
  const isMobileScreen = isMobile()
  // We need to handle 2 cases to keep facet select open because react-aria closes it on scrolling,
  // - overlayTriggerState is open true for the current triggerReference (current behavior on click)
  // OR
  // - user scrolls
  //   AND overlayTriggerState is open false (current behavior on scroll)
  //   AND the facet select is clicked on (current open facet select)
  //
  // Note: we could also lift up the state to know which one was clicked on but keeping local state seems already fine
  const canStayOpenOnDesktop = Boolean(!isMobileScreen && direction && !overlayTriggerState.isOpen && isClicked)
  const isSelectFilterOpen = Boolean(overlayTriggerState.isOpen || canStayOpenOnDesktop)
  const handleSelectedOptionsChange = useCallback(
    (newValue: Set<string>) => {
      onSelectedOptionsChange(filterId, newValue)
    },
    [filterId, onSelectedOptionsChange],
  )
  // Get props for the trigger and overlay. This also handles
  // hiding the overlay when a parent element of the trigger scrolls
  // (which invalidates the popover positioning).
  const { triggerProps, overlayProps } = useOverlayTrigger({ type: 'dialog' }, overlayTriggerState, triggerReference)
  // Get popover positioning props relative to the trigger
  const { overlayProps: positionProperties } = useOverlayPosition({
    targetRef: triggerReference,
    overlayRef: overlayReference,
    placement: 'bottom left',
    offset: 8,
    isOpen: isSelectFilterOpen,
  })
  // useButton ensures that focus management is handled correctly,
  // across all browsers. Focus is restored to the button once the
  // popover closes.
  const { buttonProps } = useButton(
    {
      onPress: () => {
        overlayTriggerState.open()
        setIsClicked(true)
      },
    },
    triggerReference,
  )
  const selectedOption =
    selectionMode === 'multiple' || localSelection.size === 0
      ? undefined
      : options.find((option) => Boolean(option.value === localSelection.entries().next().value[0]))

  // this excludes filter options that aren't present in the filters after some was applied
  const visibleActiveFiltersCount = options.filter((option) => localSelection.has(option.value)).length

  useEffect(() => {
    document
      .querySelector('body')
      ?.classList[isMobileScreen && overlayTriggerState.isOpen ? 'add' : 'remove']('no-scroll')
  }, [overlayTriggerState.isOpen, isMobileScreen])

  useEffect(() => {
    if (!selectedOptions) {
      // this gets uselessly called also on first render but ensures data
      // is properly reset when user clicks on reset filters
      setLocalSelection(new Set<string>())
    }
  }, [selectedOptions])

  useEffect(() => {
    if (!triggerReference.current) {
      return
    }
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        // this will be only 1 but clean code is beautiful :D
        setTriggerWidth(entry.target.getBoundingClientRect().width)
      }
    })
    // NOTE: useEffect intrinsecally guarantees that refs created with useRef will be there
    resizeObserver.observe(triggerReference.current)

    return () => {
      resizeObserver.disconnect()
    }
  }, [triggerReference.current])

  function handleApply() {
    if (selectionMode === 'multiple') {
      handleSelectedOptionsChange(localSelection)
    }
    overlayTriggerState.close()
    setIsClicked(false)
  }

  function handleReset() {
    handleSelectedOptionsChange(new Set())
    setLocalSelection(new Set())
  }

  function handleSelectedOptionChange(value: SetStateAction<Set<string>>) {
    setLocalSelection((oldValue) => {
      if (typeof value === 'function') {
        const newValue = value(oldValue)
        if (selectionMode === 'single') {
          handleSelectedOptionsChange(newValue)
          overlayTriggerState.close()
          setIsClicked(false)
        }

        return newValue
      }
      if (selectionMode === 'single') {
        handleSelectedOptionsChange(value)
        overlayTriggerState.close()
        setIsClicked(false)
      }

      return value
    })
  }

  const triggerTop = triggerReference.current?.getBoundingClientRect().top || 0
  const overlayTop = overlayReference.current?.getBoundingClientRect().top || 0

  return (
    <>
      <button
        {...buttonProps}
        {...triggerProps}
        ref={triggerReference}
        className={cls({
          'cmp-multi-select-filter__trigger': true,
          'cmp-multi-select-filter__trigger--product-variants-selector': variant === variantSelectorClassName,
          'cmp-multi-select-filter__trigger--is-open': isSelectFilterOpen,
        })}
      >
        <div
          className={cls({
            'cmp-multi-select-listbox__item-description': variant === variantSelectorClassName,
          })}
        >
          {variant === variantSelectorClassName && filterId === 'SCC_COLOR'
            ? options?.map((option) => {
                if (option.label === selectedOption?.label && option.imageUrl) {
                  return (
                    <img
                      key={option.value}
                      className="cmp-multi-select-listbox__image"
                      src={option.imageUrl}
                      alt={option?.value}
                    />
                  )
                }
              })
            : null}

          {selectionMode === 'multiple' || (selectionMode === 'single' && visibleActiveFiltersCount === 0)
            ? triggerLabel
            : selectedOption?.label ?? selectedOption?.value}
        </div>
        <span
          className={cls({
            'cmp-multi-select-filter__counter': true,
            'cmp-multi-select-filter__counter--is-visible': visibleActiveFiltersCount > 0,
          })}
        >
          {visibleActiveFiltersCount}
        </span>
        <span className="cmp-multi-select-filter__trigger-arrow">
          <i
            className={cls({
              'ri-arrow-down-s-line': !isSelectFilterOpen,
              'ri-arrow-up-s-line': isSelectFilterOpen,
            })}
          />
        </span>
      </button>

      {isSelectFilterOpen && (
        <OverlayContainer>
          <MultiSelectFilterPopover
            {...overlayProps}
            {...(isMobileScreen ? {} : positionProperties)}
            className={cls({
              'cmp-multi-select-filter__popover': true,
              'cmp-multi-select-filter__popover--above-button': triggerTop > overlayTop,
              'cmp-multi-select-filter__popover--product-variants-selector': variant === variantSelectorClassName,
            })}
            ref={overlayReference}
            title={
              <>
                {popoverTitle}
                {localSelection.size > 0 && (
                  <span className="cmp-multi-select-filter__counter">{localSelection.size}</span>
                )}
              </>
            }
            isOpen={isSelectFilterOpen}
            onClose={handleApply}
          >
            <>
              <div
                className={cls({
                  'cmp-multi-select-filter__popover-border-adjustment': true,
                  'cmp-multi-select-filter__popover-border-adjustment--below': triggerTop < overlayTop,
                  'cmp-multi-select-filter__popover-border-adjustment--above': triggerTop > overlayTop,
                })}
                style={{
                  width: triggerWidth > 0 ? Math.round(triggerWidth) : undefined,
                }}
              ></div>
              <MultiSelectListBox
                triggerLabel={triggerLabel}
                selectionMode={selectionMode}
                options={options}
                applyLabel={applyLabel}
                resetLabel={resetLabel}
                selectedOptions={localSelection}
                onApplySelection={handleApply}
                onResetSelection={handleReset}
                onSelectedOptionChange={handleSelectedOptionChange}
              />
            </>
          </MultiSelectFilterPopover>
        </OverlayContainer>
      )}
    </>
  )
}

export default MultiSelectFilter
