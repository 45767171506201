import React from 'react'
import { registerComponent } from 'react-register-dom'
import { useKeyPress } from '@hooks/useKeyPress'

const Columns: React.FC = () => {
  return (
    <>
      {Array.from({ length: 12 }).map((_, index) => (
        <div key={index} className="cmp-grid__column"></div>
      ))}
    </>
  )
}

const Grid: React.FC = () => {
  const { isToggled } = useKeyPress()

  if (!isToggled) {
    return null
  }

  return (
    <>
      <div className="cmp-grid cmp-grid--small-s">
        <Columns />
      </div>
      <div className="cmp-grid cmp-grid--small-m">
        <Columns />
      </div>
      <div className="cmp-grid cmp-grid--medium-l">
        <Columns />
      </div>
      <div className="cmp-grid cmp-grid--large-xl">
        <Columns />
      </div>
      <div className="cmp-grid cmp-grid--x-large">
        <Columns />
      </div>
    </>
  )
}

registerComponent('Grid', Grid)

export default Grid
