import React from 'react'
import { registerComponent } from 'react-register-dom'
import BlogNewsletterFloatingButton from '@components/BlogNewsletterFloatingButton'
import FormContent from '@components/Form/FormContent'
import { AEMLinkProperties, FormContentModel } from '@components/Form/FormTypes'

interface NewsletterEmbeddedFormProperties {
  fieldConfig: FormContentModel
  buttonLink?: AEMLinkProperties
  modalCloseLabel?: string
  modalCloseAriaLabel?: string
  isModal: boolean
  enableFloaterButton: boolean
  endpointUrl: {
    formEndpointUrl: string
  }
}

const NewsletterEmbeddedForm: React.FC<{ jsonData: string; id?: string }> = ({ jsonData, id }) => {
  const newsletterEmbeddedForm = JSON.parse(jsonData) as NewsletterEmbeddedFormProperties
  return (
    <React.Fragment>
      <FormContent
        fieldConfig={newsletterEmbeddedForm?.fieldConfig}
        endpointUrl={newsletterEmbeddedForm?.endpointUrl}
        hideImage={true}
        recaptchaElementId={id}
      />

      {newsletterEmbeddedForm?.enableFloaterButton && (
        <BlogNewsletterFloatingButton
          fieldConfig={newsletterEmbeddedForm?.fieldConfig}
          endpointUrl={newsletterEmbeddedForm?.endpointUrl}
          isModal={newsletterEmbeddedForm?.isModal}
          buttonLink={newsletterEmbeddedForm?.buttonLink}
          modalCloseAriaLabel={newsletterEmbeddedForm?.modalCloseAriaLabel}
          modalCloseLabel={newsletterEmbeddedForm?.modalCloseLabel}
          recaptchaElementId={id}
        />
      )}
    </React.Fragment>
  )
}

registerComponent('NewsletterEmbeddedForm', NewsletterEmbeddedForm)

export default NewsletterEmbeddedForm
