import React from 'react'
import { registerComponent } from 'react-register-dom'
import { searchUrlParameters } from '@components/CoffeeMachineFinder/constants'
import ErrorWrapper from '@components/UI/ErrorWrapper'

import { usePrepareLocalStorageForPdf, useStepperState } from './CoffeeMachineFinderHooks'
import CoffeeMachineFinderResults from './CoffeeMachineFinderResults'
import { CoffeeMachineFinderData, QuestionConfig } from './CoffeeMachineFinderTypes'
import QuestionnaireSlide from './QuestionnaireSlide'
import Stepper from './Stepper'

function getSelectedOptions(stepConfig: QuestionConfig, setOptions: { [key: string]: boolean }): string | string[] {
  return stepConfig.options
    .map((option) => {
      return setOptions[option.id] && option.title
    })
    .filter((entry) => Boolean(entry))
    .join(',')
}

const CoffeeMachineFinder: React.FC<{
  jsonData: string
}> = ({ jsonData }) => {
  const { resultsEndpoint, questionnaire, texts, modal } = React.useMemo(
    (): CoffeeMachineFinderData => JSON.parse(jsonData),
    [jsonData],
  )
  const {
    selectedAnswersOptions,
    selectedWeightsOptions,
    currentStep,
    showResults,
    canGoForward,
    goBack,
    goForward,
    goToStep,
    toggleOption,
    resetQuestionnaire,
  } = useStepperState(questionnaire)
  usePrepareLocalStorageForPdf({
    questionnaire,
    pdfExportUrl: resultsEndpoint.pdfExportUrl,
    allSelectedOptions: {
      ...selectedAnswersOptions,
      ...selectedWeightsOptions,
    },
    showResults,
  })
  const triggerAnalytics = (stepConfig: QuestionConfig, index) => {
    window.adobeDataLayer?.push({
      event: 'coffee machine finder',
      eventInfo: {
        reference: 'coffee machine finder',
        step: index + 1,
        title: stepConfig.question,
        [searchUrlParameters.ANSWERS]: getSelectedOptions(stepConfig, selectedAnswersOptions),
        [searchUrlParameters.WEIGHTS]: getSelectedOptions(stepConfig, selectedWeightsOptions),
      },
    })
  }

  if (showResults) {
    return (
      <CoffeeMachineFinderResults
        onRestart={resetQuestionnaire}
        resultsEndpoint={resultsEndpoint}
        selectedAnswersOptions={selectedAnswersOptions}
        selectedWeightsOptions={selectedWeightsOptions}
        texts={texts}
        config={questionnaire}
        modal={modal}
      />
    )
  }

  return (
    <ErrorWrapper>
      <div className="coffee-machine-finder">
        <Stepper config={questionnaire} currentStep={currentStep} onStepClick={goToStep} />
        <div className="coffee-machine-finder__slider">
          {questionnaire.map((stepConfig, index) => (
            <QuestionnaireSlide
              key={stepConfig.id}
              current={currentStep === index}
              config={stepConfig}
              displayedStep={index + 1}
              totalSteps={questionnaire.length}
              texts={texts}
              first={index === 0}
              last={index === questionnaire.length - 1}
              selectedOptions={{
                ...selectedAnswersOptions,
                ...selectedWeightsOptions,
              }}
              isForwardDisabled={!canGoForward}
              onBack={goBack}
              onForward={() => {
                triggerAnalytics(stepConfig, index)
                goForward()
              }}
              onOptionToggle={toggleOption}
            />
          ))}
        </div>
      </div>
    </ErrorWrapper>
  )
}

registerComponent('CoffeeMachineFinder', CoffeeMachineFinder)

export default CoffeeMachineFinder
