// Example of how a component should be initialized via JavaScript
// This script logs the value of the component's text property model message to the console
// eslint-disable-next-line sonarjs/cognitive-complexity
;(function () {
  'use strict'
  function onDocumentReady() {
    const slideImagesAreas = document.querySelectorAll('.cmp-side-by-side-images')
    let startX, startY

    for (const slideImagesArea of slideImagesAreas) {
      // Store the initial touch position
      slideImagesArea.addEventListener('touchstart', function (event) {
        const touch = event.changedTouches[0]
        startX = touch.clientX
        startY = touch.clientY
      })

      // Detect horizontal movement and ignore vertical scrolls
      slideImagesArea.addEventListener('touchmove', function (event) {
        const touch = event.changedTouches[0]
        const diffX = touch.clientX - startX
        const diffY = touch.clientY - startY

        // Check if horizontal movement is greater than vertical movement
        if (Math.abs(diffX) > Math.abs(diffY)) {
          if (diffX > 0) {
            slideImagesArea.classList.remove('cmp-side-by-side-images--is-scrolled-right')
            slideImagesArea.scrollLeft = 0
          } else {
            slideImagesArea.classList.add('cmp-side-by-side-images--is-scrolled-right')
            slideImagesArea.scrollLeft = slideImagesArea.scrollWidth
          }
        }
      })
    }
  }

  if (document.readyState !== 'loading') {
    onDocumentReady()
  } else {
    document.addEventListener('DOMContentLoaded', onDocumentReady)
  }
})()
