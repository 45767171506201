;(function () {
  'use strict'

  function onDocumentReady() {
    const actionWrapper = document.querySelector('.cmp-carousel__actions')
    const actions = actionWrapper?.querySelectorAll('.cmp-carousel__action')

    const themes = [
      'vibrant-red-theme',
      'burgundy-theme',
      'grey-theme',
      'lavender-theme',
      'lightgreen-theme',
      'mocha-theme',
      'wheat-theme',
      'white-theme',
    ]

    const checkForActiveTheme = () => {
      const activeItem = document.querySelector('.cmp-carousel__item--active')
      const cardTeaser = activeItem?.querySelector('.cardteaser')
      const activeItemTheme = Array.from(cardTeaser.classList).find((className) => themes.includes(className))
      actionWrapper.classList.add(activeItemTheme)
    }

    if (actions) {
      checkForActiveTheme()
      for (const action of actions) {
        action.addEventListener('click', () => {
          actionWrapper.classList.remove(
            ...Array.from(actionWrapper.classList).filter((className) => themes.includes(className)),
          )
          setTimeout(function () {
            checkForActiveTheme()
          }, 5)
        })
      }
    }
  }

  if (document.readyState !== 'loading') {
    onDocumentReady()
  } else {
    document.addEventListener('DOMContentLoaded', onDocumentReady)
  }
})()
