import { useEffect, useRef } from 'react'

export function useOnEventOutside({
  eventType,
  callBack,
  shouldHandleKeyUp = false,
}: {
  eventType: string
  callBack: () => void
  shouldHandleKeyUp?: boolean
}) {
  const reference = useRef<any>(null)

  const handleOnEvent = ({ target }: Event) => {
    reference.current && !reference.current.contains(target) && callBack()
  }

  const handleKeyUp = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      handleOnEvent(event)
    }
  }

  useEffect(() => {
    document.addEventListener(eventType, handleOnEvent, true)
    if (shouldHandleKeyUp) {
      document.addEventListener('keyup', handleKeyUp, true)
    }

    return () => {
      document.removeEventListener(eventType, handleOnEvent, true)
      if (shouldHandleKeyUp) {
        document.removeEventListener('keyup', handleKeyUp, true)
      }
    }
  }, [shouldHandleKeyUp, eventType])

  return reference
}
