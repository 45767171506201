import { useEffect, useState } from 'react'

export const useOnScrolls = () => {
  const [isScrolled, setIsScrolled] = useState(false)
  const [direction, setDirection] = useState('')

  let lastScrollY = 0

  const handleOnWindowScroll = () => {
    setIsScrolled(Boolean(window.pageYOffset > 0))
    setDirection(window.scrollY > lastScrollY ? 'up' : 'down')
    lastScrollY = window.scrollY
  }

  useEffect(() => {
    window.addEventListener('scroll', handleOnWindowScroll)
    return () => {
      window.removeEventListener('scroll', handleOnWindowScroll)
    }
  }, [])

  return { isScrolled, direction }
}
