export function getDecimalsCount(number: string): number {
  // TODO: we need to cast it otherwise Number.isInteger('5') somehow does not work but Number.isInteger('2.5') does :D
  if (Number.isInteger(Number(number))) {
    return 0
  }
  const decimalPart = number?.split('.')[1]
  return decimalPart.length
}

export function getStringNumber(value: string): string {
  return value.replace(/[^\d.]*/g, '')
}

export function getSymbol(value: string): string {
  return value.replace(/\d*\.?\d+/g, '')
}
