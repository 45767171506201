import React, { Key, useEffect, useState } from 'react'
import Flickity from 'react-flickity-component'
import { registerComponent } from 'react-register-dom'

import TeaserSliderFeaturedArticle from './TeaserSliderFeaturedArticle'
import TeaserSliderMainArticle from './TeaserSliderMainArticle'
import { HeaderTag } from './TeaserSliderTypes'

const flickityOptions = {
  cellAlign: 'left',
  autoPlay: 4000,
  pageDots: false,
  draggable: false,
  contain: true,
}

const TeaserSlider: React.FC<{ jsonData: string }> = ({ jsonData }) => {
  const teaserSliderItems = JSON.parse(jsonData) // TODO: put a type here
  const HeaderTag = (teaserSliderItems.headlineHeadingLevel = 'h2' as HeaderTag)

  const filteredTeaserSliderItems = teaserSliderItems.blogArticles.filter((item) => item.image && item.title)

  const flickityReference = React.useRef<Flickity | null>(null)
  const articlesReference = React.useRef<HTMLDivElement>(null)

  const [position, setPosition] = React.useState({
    isScrolling: false,
    clientX: 0,
    scrollX: 0,
  })
  const [selectedIndex, setSelectedIndex] = useState(0)

  const handleScrollonMouseDown = (event) => {
    setPosition({ clientX: event.clientX, isScrolling: true, scrollX: 0 })
  }

  const handleScrollonMouseUp = () => {
    setPosition({
      clientX: position.clientX,
      isScrolling: false,
      scrollX: position.scrollX,
    })
  }

  const handleScrollonMouseMove = (event) => {
    const { clientX, scrollX, isScrolling } = position
    if (isScrolling) {
      if (articlesReference.current) {
        articlesReference.current.scrollLeft = scrollX + event.clientX - clientX
      }
      position.scrollX = scrollX + event.clientX - clientX
      position.clientX = event.clientX
    }
  }

  useEffect(() => {
    // NOTE: empty dependencies array guarantees this will be there
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    flickityReference!.current!.on('change', setSelectedIndex)
    return () => {
      flickityReference.current?.off('change', setSelectedIndex)
    }
  }, [])
  const handleFeaturedArticleClick = (selectedIndex: number) => {
    flickityReference.current?.select(selectedIndex)
  }
  return (
    <div className={`cmp-teaser-slider`}>
      <HeaderTag className="cmp-teaser-slider__headline">{teaserSliderItems.headline}</HeaderTag>
      <div className="cmp-teaser-slider__main-article">
        <Flickity
          flickityRef={(reference: any) => {
            flickityReference.current = reference
          }}
          className={'cmp-teaser-slider__slider'} // default ''
          elementType="div" // default 'div'
          options={flickityOptions} // takes flickity options {}
          disableImagesLoaded={false} // default false
        >
          {filteredTeaserSliderItems.map((item, index: Key) => (
            <TeaserSliderMainArticle
              key={index}
              image={item.image}
              formattedPublicationDate={item.formattedPublicationDate}
              blogTags={item.blogTags}
              title={item.title}
              description={item.description}
              pageLink={{
                externalizedURL: item.pageLink?.externalizedURL,
                isTargetBlank: item.pageLink?.isTargetBlank,
              }}
              buttonLabel={item.buttonLabel}
            />
          ))}
        </Flickity>
      </div>
      <div
        className="cmp-teaser-slider__featured-articles"
        ref={articlesReference}
        onMouseDown={handleScrollonMouseDown}
        onMouseUp={handleScrollonMouseUp}
        onMouseMove={handleScrollonMouseMove}
        role="button"
        tabIndex={0}
      >
        {filteredTeaserSliderItems.length > 1 &&
          filteredTeaserSliderItems.map((item, index) => (
            <TeaserSliderFeaturedArticle
              key={`${item.id}_${index}`}
              item={item}
              isSelected={index === selectedIndex}
              onClick={() => handleFeaturedArticleClick(index)}
              containerElement={articlesReference.current}
              onAnimationCompleted={() => {
                if (flickityReference?.current) {
                  flickityReference.current.next(true)
                }
              }}
              onMouseEnter={() => {
                if (flickityReference?.current) {
                  flickityReference?.current.pausePlayer()
                }
              }}
              onMouseLeave={() => {
                if (flickityReference?.current) {
                  flickityReference?.current.unpausePlayer()
                }
              }}
            />
          ))}
      </div>
    </div>
  )
}

registerComponent('TeaserSlider', TeaserSlider)
export default TeaserSlider
