import React from 'react'
import { cls } from '@utils/classnames'

const NavigationProductGradient = ({ isReverse = false }: { isReverse?: boolean }): JSX.Element => {
  return (
    <div className="cmp-navigation-product-gradient">
      <div
        className={cls({
          'cmp-navigation-product-gradient__overlay': true,
          'cmp-navigation-product-gradient__overlay--is-reverse': isReverse,
        })}
      ></div>
    </div>
  )
}

export default NavigationProductGradient
