import React from 'react'

interface CountDownPageHeadlineProperties {
  leftHeadline: string
  rightHeadline: string
}

const CountDownPageHeadline: React.FC<CountDownPageHeadlineProperties> = ({
  leftHeadline,
  rightHeadline,
}: CountDownPageHeadlineProperties) => {
  return (
    <div className="cmp-countdown-page-headline">
      <div className="cmp-countdown-page-headline__left">{leftHeadline}</div>
      <div className="cmp-countdown-page-headline__right">{rightHeadline}</div>
    </div>
  )
}

export default CountDownPageHeadline
