import React, { useEffect, useState } from 'react'
import { registerComponent } from 'react-register-dom'
import { useKeyPress } from '@hooks/useKeyPress'

const themes = [
  'artemis-theme',
  'black-theme',
  'burgundy-theme',
  'chainlink-theme',
  'default-theme',
  'eurodomo-theme',
  'grey-theme',
  'industries-theme',
  'kindred-theme',
  'lavender-theme',
  'lightgreen-theme',
  'mocha-theme',
  'mepamsa-theme',
  'roblin-theme',
  // eslint-disable-next-line sonarjs/no-duplicate-string
  'vibrant-red-theme',
  'wheat-theme',
  'white-theme',
]

const ThemeSwitcher: React.FC = () => {
  const [theme, setTheme] = useState('default-theme')
  const { isThemeToggled } = useKeyPress()
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value
    setTheme(value)
  }

  useEffect(() => {
    if (!document.body.classList.contains('black-theme')) {
      // clear all body classes
      document.body.classList.remove(...document.body.classList)
      document.body.classList.add(theme)
    }

    // add extra classes whenever needed
    if (['vibrant-red-theme', 'artemis-theme', 'industries-theme', 'mepamsa-theme'].includes(theme)) {
      document.body.classList.add('logo--white')
    }
    return () => {
      document.body.classList.remove(theme)
      // remove any extra classes that were needed by some themes
      document.body.classList.remove('logo--white')
      document.body.classList.remove('logo--red')
    }
  }, [theme])

  if (!isThemeToggled) {
    return null
  }

  return (
    <div
      style={{
        position: 'fixed',
        bottom: '100px',
        left: 0,
        height: '100px',
        backgroundColor: 'fuchsia',
        padding: '10px',
        zIndex: 4,
        opacity: 0.6,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
      }}
    >
      <select defaultValue={theme} onChange={handleChange}>
        {themes.map((theme) => (
          <option key={theme} value={theme}>
            {theme}
          </option>
        ))}
      </select>
    </div>
  )
}

registerComponent('ThemeSwitcher', ThemeSwitcher)

export default ThemeSwitcher
