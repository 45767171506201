import { useEffect, useState } from 'react'
import { Cluster } from '@googlemaps/markerclusterer'
import { FetchState } from '@hooks/fetch'

export function useOrigin({
  map,
  country,
  latitude,
  longitude,
  zoomLevel,
}: {
  map: google.maps.Map | null
  country?: string
  latitude?: number
  longitude?: number
  zoomLevel?: number
}) {
  const [state, setState] = useState<
    FetchState<{ lat: number; lng: number; zoom: number } | google.maps.places.PlaceGeometry, unknown>
  >({
    status: 'NEVER_EXECUTED',
  })

  useEffect(() => {
    /** on load, if some state is retrieved from url, use it instead of calling the service */
    if (latitude && longitude && zoomLevel) {
      setState({
        status: 'SUCCESS',
        data: { lat: latitude, lng: longitude, zoom: zoomLevel },
      })
    }
  }, [])

  useEffect(() => {
    if (!map || !country || (latitude && longitude && zoomLevel)) {
      return
    }

    getCountryViewportFromName(map, country)
      .then((viewport) => {
        setState({
          status: 'SUCCESS',
          data: viewport,
        })
      })
      .catch((error) => {
        setState({
          status: 'ERROR',
          data: error,
        })
      })
  }, [map, country])

  return state
}

function getCountryViewportFromName(map: google.maps.Map, country: string) {
  return new Promise<google.maps.places.PlaceGeometry>((resolve, reject) => {
    const service = new google.maps.places.PlacesService(map)

    service.findPlaceFromQuery({ query: country, fields: ['place_id'] }, async (results, status) => {
      if (status !== google.maps.places.PlacesServiceStatus.OK || !results || results?.length === 0) {
        reject({ status, results, reason: 'no results finding for matches', country })

        return
      }

      for (const { place_id } of results) {
        try {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const result = await getLocationDetailsById(service, place_id!)

          resolve(result)
          return
        } catch {
          // not good, next
        }
      }

      reject({ results, status, reason: 'none of the results were a country' })
    })
  })
}

function getLocationDetailsById(service: google.maps.places.PlacesService, placeId: string) {
  return new Promise<google.maps.places.PlaceGeometry>((resolve, reject) => {
    service.getDetails(
      {
        placeId,
        fields: ['address_components', 'geometry.viewport', 'geometry.location'],
      },
      (result, status) => {
        if (status !== google.maps.places.PlacesServiceStatus.OK) {
          reject({ status, result })
          return
        }

        const isMatchingCountry = result?.address_components?.some((component) => component.types.includes('country'))

        if (!isMatchingCountry) {
          reject({ status, result })
          return
        }

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        resolve(result!.geometry!)
      },
    )
  })
}

export function isGeometry(
  origin: { lat: number; lng: number; zoom: number } | google.maps.places.PlaceGeometry,
): origin is google.maps.places.PlaceGeometry {
  return !!origin['location']
}

export function useGmapsAutocompleteApi(searchQuery: string) {
  const [service, setService] = useState<google.maps.places.AutocompleteService>()
  const [isPending, setIsPending] = useState(false)
  const [error, setError] = useState<null | google.maps.places.PlacesServiceStatus>(null)
  const [predictions, setPredictions] = useState<null | google.maps.places.AutocompletePrediction[]>(null)

  useEffect(() => {
    if (!window['google']) {
      return
    }
    const serviceToUse = service ?? new google.maps.places.AutocompleteService()
    if (!service) {
      setService(serviceToUse)
    }

    if (searchQuery === '') {
      setError(null)
      setPredictions(null)
      setIsPending(false)

      return
    }

    setIsPending(true)
    serviceToUse.getPlacePredictions(
      {
        input: searchQuery,
      },
      (predictions, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          setPredictions(predictions)
          setError(null)
        } else {
          setError(status)
        }
        setIsPending(false)
      },
    )
  }, [searchQuery])

  return {
    isPending,
    error,
    predictions,
  }
}

function equals(other) {
  return this.width * this.height === other.width * other.height
}

export function clusterRenderer({ position, count }: Cluster): google.maps.Marker {
  return new google.maps.Marker({
    position,
    label: {
      className: 'cmp-partner-finder__cluster',
      text: String(count),
    },
    icon: {
      path: 'M -8.5,-8.5 L -8.5,8.5 L 8.5,8.5 L 8.5,-8.5 L -8.5,-8.5 Z', // empirically 34px total
      scaledSize: { width: 34, height: 34, equals },
      size: { width: 34, height: 34, equals },
    },
  })
}

export function getPlaceGeometryById(map: google.maps.Map, placeId: string) {
  if (!window['google']) {
    return
  }

  const service = new google.maps.places.PlacesService(map)
  return new Promise<google.maps.places.PlaceGeometry>((resolve, reject) => {
    service.getDetails({ placeId, fields: ['geometry.viewport'] }, (result, status) => {
      if (status !== google.maps.places.PlacesServiceStatus.OK) {
        reject(status)

        return
      }

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      resolve(result!.geometry!)
    })
  })
}
