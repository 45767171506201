import React from 'react'

interface DamVideoProperties {
  videoUrl: string
  isEditModeBoolean: boolean
  isVideoPlaying: boolean
  loopVideo: string
  hideVideoControls: string
}

const DamVideo = React.forwardRef<HTMLVideoElement, DamVideoProperties>(
  ({ isEditModeBoolean, isVideoPlaying, loopVideo, hideVideoControls, videoUrl }, reference): JSX.Element => {
    return (
      <video
        ref={reference}
        className="cmp-video__dam-video"
        {...(isEditModeBoolean
          ? {}
          : {
              autoPlay: isVideoPlaying,
              loop: loopVideo === 'true',
              controls: hideVideoControls !== 'true',
            })}
        muted
        playsInline
      >
        <source src={videoUrl} type="video/mp4" />
        <span>I'm sorry; your browser doesn't support HTML5 video</span>
      </video>
    )
  },
)

export default DamVideo
