import React, { useState } from 'react'
import GlobalSearchLayout from '@components/GlobalSearch/GlobalSearchLayout'
import { SectionType, TabsConfig } from '@components/GlobalSearch/GlobalSearchTypes'
import { cls } from '@utils/classnames'

export interface SearchConfig {
  searchTabs: TabsConfig[]
  activeTab: string
  searchLabel: string
  titleFirstLine: string
  titleSecondLine: string
  errorApiTitle: string
  errorApiDescription: string
  noResultsTitle: string
  noResultsDescription: string
  showAllLabel: string
  previewSectionPageSize: string
  placeholder: string
  showAllPageSize: string
  showMoreLabel: string
  backlinkLabel: string
}
interface SearchProperties {
  config: SearchConfig
}

export interface MoreResultsView {
  label?: string
  endpoint?: string
  type?: SectionType
  baseUrlTemplate?: string
  division?: string
}

const Search: React.FC<SearchProperties> = ({ config }: SearchProperties) => {
  const [moreResultsView, setMoreResultsView] = useState<MoreResultsView>({})
  const [searchTerm, setSearchTerm] = useState('')
  const [searchTermInput, setSearchTermInput] = useState(searchTerm)

  const handleSearch = (event) => {
    event.preventDefault()
    setSearchTerm(searchTermInput.trim())
  }

  return (
    <div className="cmp-search">
      <h1 className="cmp-search__title">
        {config.titleFirstLine}
        <br />
        {config.titleSecondLine}
      </h1>
      {!moreResultsView.endpoint && (
        <form className="cmp-search__search-field" onSubmit={handleSearch}>
          <label htmlFor="global-search-input" className="cmp-search__search-field-label">
            <i className="cmp-search__search-field-icon ri-search-line"></i>
          </label>
          <input
            name="search"
            id="global-search-input"
            className="cmp-search__search-field-input"
            placeholder={config.placeholder}
            type={'text'}
            value={searchTermInput}
            onChange={(event) => setSearchTermInput(event.target.value)}
          ></input>
          <button
            type="submit"
            className={cls({
              'cmp-search__search-field-button': true,
              'cmp-search__search-field-button--is-active': searchTermInput && searchTerm !== searchTermInput,
            })}
            onClick={handleSearch}
          >
            {config.searchLabel}
            <i className="cmp-search__search-field-button-icon ri-arrow-right-line"></i>
          </button>
          <button
            className={cls({
              'cmp-search__search-field-button': true,
              'cmp-search__search-field-button--is-active': searchTermInput && searchTerm === searchTermInput,
            })}
            onClick={() => {
              setSearchTerm('')
              setSearchTermInput('')
            }}
          >
            <i className="cmp-search__search-field-button-icon ri-close-line"></i>
          </button>
        </form>
      )}
      <div
        className={cls({
          'cmp-search__result-wrapper': true,
          'cmp-search__result-wrapper--is-visible': searchTerm !== '',
        })}
      >
        <GlobalSearchLayout
          labels={config}
          resultLimits={{
            previewResultLimit: config.previewSectionPageSize,
            showAllResultLimit: config.showAllPageSize,
          }}
          tabs={config.searchTabs}
          defaultActiveTab={config.activeTab}
          searchTerm={searchTerm}
          moreResultsView={moreResultsView}
          setMoreResultsView={setMoreResultsView}
        />
      </div>
    </div>
  )
}

export default Search
