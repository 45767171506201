import * as React from 'react'

export type GPSState =
  | {
      status: 'NOT_REQUESTED' | 'PENDING'
    }
  | {
      status: 'SUCCESS'
      position: GeolocationPosition
    }
  | {
      status: 'ERROR'
      error: GeolocationPositionError
    }

export function useGPS() {
  const [state, setState] = React.useState<GPSState>({
    status: 'NOT_REQUESTED',
  })

  const handleSuccess = React.useCallback(
    (position: GeolocationPosition) => {
      if (position.coords.accuracy < 100 && state.status !== 'SUCCESS') {
        setState({
          status: 'SUCCESS',
          position,
        })
      }
    },
    [setState],
  )
  const handleError = React.useCallback(
    (error: GeolocationPositionError) => {
      setState({
        status: 'ERROR',
        error,
      })
    },
    [setState],
  )

  const requestGps = React.useCallback(() => {
    setState({
      status: 'PENDING',
    })
    navigator.geolocation.getCurrentPosition(handleSuccess, handleError, {
      enableHighAccuracy: true,
      maximumAge: 0,
    })
  }, [setState])

  return [state, requestGps] as [GPSState, () => void]
}

export function useGpsSuccessEffect(gpsState: GPSState, callback: (position: GeolocationPosition) => void) {
  React.useEffect(() => {
    if (gpsState.status === 'SUCCESS') {
      callback(gpsState.position)
    }
  }, [gpsState, callback])
}

export function useGpsErrorEffect(gpsState: GPSState, callback: (error: GeolocationPositionError) => void) {
  React.useEffect(() => {
    if (gpsState.status === 'ERROR') {
      callback(gpsState.error)
    }
  }, [gpsState, callback])
}
