// Example of how a component should be initialized via JavaScript
// This script logs the value of the component's text property model message to the console
// eslint-disable-next-line sonarjs/cognitive-complexity
;(function () {
  'use strict'
  function onDocumentReady() {
    const videoCardTeasers = document.querySelectorAll('.cmp-card-teaser__video')

    if (videoCardTeasers) {
      for (const videoCardTeaser of videoCardTeasers) {
        const playButton = videoCardTeaser.querySelector('.cmp-card-teaser__video-button')
        const video = videoCardTeaser.querySelector('.cmp-card-teaser__video-player')
        const cover = videoCardTeaser.querySelector('.cmp-card-teaser__video-cover')
        playButton.addEventListener('click', () => {
          cover.style.display = 'none'
          video.play()
        })
      }
    }
  }

  if (document.readyState !== 'loading') {
    onDocumentReady()
  } else {
    document.addEventListener('DOMContentLoaded', onDocumentReady)
  }
})()
