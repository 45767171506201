/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect, useRef } from 'react'
import ResponsiveImage from '@components/ResponsiveImage'
import { cls } from '@utils/classnames'

import { TeaserSliderItem } from '../TeaserSliderTypes'
interface TeaserSliderFeaturedArticleProperties {
  containerElement?: HTMLDivElement | null
  item: TeaserSliderItem
  isSelected: boolean
  onClick: () => void
  onMouseEnter: () => void
  onMouseLeave: () => void
  onAnimationCompleted: () => void
}

const TeaserSliderFeaturedArticle: React.FC<TeaserSliderFeaturedArticleProperties> = ({
  onClick,
  onMouseEnter,
  onMouseLeave,
  onAnimationCompleted,
  item,
  isSelected,
  containerElement,
}: TeaserSliderFeaturedArticleProperties) => {
  const reference = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (isSelected && reference.current && containerElement) {
      const containerX = containerElement.getBoundingClientRect().left
      containerElement.scrollTo({ left: reference.current.offsetLeft - containerX, behavior: 'smooth' })
    }
  }, [isSelected])

  const raiseClick = () => {
    if (!isSelected) {
      onClick()
    }
  }

  const raiseMouseEnter = () => {
    if (isSelected) {
      onMouseEnter()
    }
  }

  const raiseMouseLeave = () => {
    if (isSelected) {
      onMouseLeave()
    }
  }
  const raiseAnimationEnd = () => {
    if (isSelected) {
      onAnimationCompleted()
    }
  }
  return (
    <div
      className={cls({
        'cmp-teaser-slider-featured-article': true,
        'cmp-teaser-slider-featured-article--is-selected': isSelected,
      })}
      ref={reference}
      onClick={raiseClick}
      onKeyDown={raiseClick}
      onMouseEnter={raiseMouseEnter}
      onMouseLeave={raiseMouseLeave}
      onAnimationEnd={raiseAnimationEnd}
      role="button"
      tabIndex={0}
    >
      <link rel="prefetch" href={item.image.imageUrl || item.image.originalImageUrl}></link>
      <ResponsiveImage
        src={item.image.imageUrl || item.image.originalImageUrl}
        alt={item.image.altText}
        className="cmp-teaser-slider-featured-article__thumbnail"
      />

      <div className="cmp-teaser-slider-featured-article__content">
        <div className="cmp-teaser-slider-featured-article__meta">
          <div className="cmp-teaser-slider-featured-article__date">{item.formattedPublicationDate}</div>
          <div className="cmp-blog-tag">
            <ul className="cmp-blog-tag__list">
              <li className="cmp-blog-tag__list-item">{item.blogTags[0]}</li>
            </ul>
          </div>
        </div>

        <p className="cmp-teaser-slider-featured-article__title">{item.title}</p>
        <a
          href={item.pageLink.externalizedURL}
          target={item.pageLink.isTargetBlank ? '_blank' : ''}
          rel={item.pageLink.isTargetBlank ? 'noopener noreferrer' : ''}
          className="cmp-teaser-slider-featured-article__cta"
        ></a>
      </div>
      <div className="cmp-teaser-slider-featured-article__timer">
        <div className="cmp-teaser-slider-featured-article__progress"></div>
      </div>
    </div>
  )
}

export default TeaserSliderFeaturedArticle
