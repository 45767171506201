// Example of how a component should be initialized via JavaScript
// This script logs the value of the component's text property model message to the console
// eslint-disable-next-line sonarjs/cognitive-complexity
;(function () {
  'use strict'

  const elementPercentage = (element) => {
    const viewportHeight = window.innerHeight
    const scrollTop = window.scrollY
    const elementOffsetTop = element.offsetTop
    const elementHeight = element.offsetHeight

    const distance = scrollTop + viewportHeight - elementOffsetTop
    const percentage = Math.round(distance / ((viewportHeight + elementHeight) / 100))

    return Math.min(100, Math.max(0, percentage))
  }

  function onDocumentReady() {
    const anchorNavigationWrapper = document.querySelector('.anchornavigation')
    const anchorNavigation = document.querySelector('.cmp-anchor-navigation')
    const anchorNavigationLinks = anchorNavigation?.querySelectorAll('.cmp-anchor-navigation__link')
    const header = document.querySelector('.cmp-header')
    const hero = document.querySelector('.cmp-hero-basic')
    let anchorNavigationSections = []
    let headerIsHidden = false

    header.addEventListener('headerIsHidden', function (event) {
      headerIsHidden = event.detail.isOpened
    })

    const activeClass = 'cmp-anchor-navigation__link--active'
    const stickyClass = 'cmp-anchor-navigation--sticky'
    const overflowingClass = 'cmp-anchor-navigation--overflowing'

    let scroll_position = 0
    let scroll_direction

    if (anchorNavigation && anchorNavigationLinks) {
      const isGeneric = anchorNavigationWrapper?.classList.contains('anchornavigation--generic')
      const isOverflowing = anchorNavigation.scrollWidth > anchorNavigation.clientWidth

      if (isGeneric) anchorNavigationWrapper.style.margin = `0 -${anchorNavigation.offsetLeft - 1}px`
      for (const link of anchorNavigationLinks) {
        let id = link.getAttribute('href').slice(1)
        let target = document.querySelector(`[id="${id}"]`)
        if (target && target.getBoundingClientRect().height) {
          anchorNavigationSections.push(target)
        } else {
          link.parentElement.remove()
        }

        if (isOverflowing && isGeneric) {
          anchorNavigation.classList.add(overflowingClass)
        }

        link.addEventListener('click', function (event) {
          if (target) {
            const headerOffset = headerIsHidden ? header.offsetHeight : 0
            const offsetPosition = target.getBoundingClientRect().top + window.pageYOffset - headerOffset

            window.scrollTo({
              top: offsetPosition,
              behavior: 'smooth',
            })
            event.preventDefault()
          }
        })
      }

      const anchorNavHeight = anchorNavigation.offsetTop

      // checks if user has scrolled page and add the sticky class to the anchor navigation when it has reached its scroll position
      // and take header into consideration if it's hidden or not
      const anchorNavIsSticky = () =>
        window.pageYOffset > anchorNavHeight ||
        (!headerIsHidden && anchorNavHeight - window.pageYOffset <= header.offsetHeight)
      const toggleSticky = () => {
        scroll_direction = document.body.getBoundingClientRect().top > scroll_position ? 'up' : 'down'
        scroll_position = document.body.getBoundingClientRect().top

        if (anchorNavIsSticky()) {
          anchorNavigation.classList.add(stickyClass)
          anchorNavigation.style.top = headerIsHidden ? `0px` : `${header.offsetHeight}px`
          anchorNavigation.style.right = `${isGeneric ? 0 : hero.getBoundingClientRect().x}px`
          anchorNavigation.style.transition = 'top 0.4s ease-in-out'
        } else {
          anchorNavigation.classList.remove(stickyClass)
          if (headerIsHidden && anchorNavigation.getBoundingClientRect().top < header.offsetHeight) {
            anchorNavigation.style.transition = 'none'
          }
        }
      }
      const setActiveSection = (link) => {
        for (const anchorNavigationLink of anchorNavigationLinks) {
          anchorNavigationLink.blur()
          removeActiveSection(anchorNavigationLink)
        }
        link.classList.add(activeClass)
        if (isOverflowing) {
          // handles the horizontal scrolling of the anchor navigation bar
          // and adjusts the scroll position of the anchor navigation bar to the active link
          anchorNavigation.scroll({
            left: link.offsetLeft - anchorNavigation.clientWidth / 2 + link.clientWidth / 2,
            behavior: 'smooth',
          })
        }
      }

      const removeActiveSection = (link) => {
        link.classList.remove(activeClass)
      }

      function handleIntersection(entries) {
        const viewportHeight = window.innerHeight
        entries.map((entry) => {
          const link = document.querySelector(`.cmp-anchor-navigation__link[href="#${CSS.escape(entry.target.id)}"]`)
          const elementIsVisibleEnough = () =>
            (entry.boundingClientRect.height < viewportHeight && entry.intersectionRatio > 0.9) ||
            (entry.boundingClientRect.height > viewportHeight &&
              elementPercentage(entry.target) > 15 &&
              elementPercentage(entry.target) < 85)

          if (entry.isIntersecting && elementIsVisibleEnough()) {
            setActiveSection(link)
          } else if (entry.intersectionRatio === 0) {
            removeActiveSection(link)
          }
        })
      }

      const observer = new IntersectionObserver(handleIntersection, { threshold: [0, 0.25, 0.5, 0.75, 1] })

      for (const section of anchorNavigationSections) {
        observer.observe(section)
      }

      if (window.scrollY > 0) toggleSticky()

      window.addEventListener('scroll', toggleSticky)
    }
  }

  if (document.readyState !== 'loading') {
    onDocumentReady()
  } else {
    document.addEventListener('DOMContentLoaded', onDocumentReady)
  }
})()
