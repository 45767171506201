import React from 'react'
import { cls } from '@utils/classnames'

import BreadcrumbItem, { BreadcrumbItemProperties } from './BreadcrumbItem/BreadcrumbItem'

interface BreadcrumbProperties {
  items?: BreadcrumbItemProperties[]
}
const MOBILE_SCREEN_SIZE = 768

const Breadcrumb = ({ items }: BreadcrumbProperties): JSX.Element => {
  const [breadcrumbsCollapsed, setBreadcrumbsCollapsed] = React.useState(false)
  const isBreadcumbsCollapsed = !breadcrumbsCollapsed && window.innerWidth < MOBILE_SCREEN_SIZE

  const handleClick = () => {
    setBreadcrumbsCollapsed(true)
  }

  return (
    <nav className="cmp-breadcrumb" aria-label="Breadcrumb">
      <ol
        className={cls({
          'cmp-breadcrumb__list': true,
          'cmp-breadcrumb__list--collapsed': isBreadcumbsCollapsed,
        })}
      >
        {items?.map((item, index) => {
          return index === items.length - 1 && isBreadcumbsCollapsed ? (
            <>
              <li
                className="cmp-breadcrumb__item--toggle"
                key="toggle-item"
                onClick={handleClick}
                onKeyDown={handleClick}
                tabIndex={0}
                role="menuitem"
              >
                ...
              </li>
              <BreadcrumbItem link={item.link} active={item.active} title={item.title} key={item.id} />
            </>
          ) : (
            <BreadcrumbItem link={item.link} active={item.active} title={item.title} key={item.id} />
          )
        })}
      </ol>
    </nav>
  )
}

export default Breadcrumb
