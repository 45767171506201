import { FC, useEffect, useMemo, useRef, useState } from 'react'
import Flickity from 'react-flickity-component'
import { registerComponent } from 'react-register-dom'
import ErrorWrapper from '@components/UI/ErrorWrapper'

import SliderHeroContent from './SliderHeroContent'
import SliderHeroPreview from './SliderHeroPreview'
import { HeroItem } from './SliderHeroTypes'

const flickityOptions = {
  cellAlign: 'left',
  autoPlay: 6000,
  pageDots: false,
  draggable: true,
  contain: true,
}

const Hero: FC<{
  jsonData: string
}> = ({ jsonData }) => {
  const heroItems: HeroItem[] = useMemo(
    (): Array<any> => Object.entries(JSON.parse(jsonData)[':items']).map((entry) => entry[1]),
    [jsonData],
  )
  const filteredHeroItems = heroItems.filter((item) => item.heroImage && item.heroTitle && item.heroThumbnailImage)
  const flickityReference = useRef<Flickity | null>(null)
  const [selectedIndex, setSelectedIndex] = useState(0)

  useEffect(() => {
    flickityReference.current?.on('change', setSelectedIndex)
    return () => {
      flickityReference.current?.off('change', setSelectedIndex)
    }
  }, [])
  const activeSlide = filteredHeroItems[selectedIndex]

  return (
    <ErrorWrapper>
      <div
        className="cmp-hero-slider"
        onMouseEnter={() => {
          if (flickityReference?.current) {
            flickityReference?.current.stopPlayer()
          }
        }}
        onMouseLeave={() => {
          if (flickityReference?.current) {
            flickityReference?.current.playPlayer()
          }
        }}
      >
        {activeSlide && (
          <SliderHeroContent
            heroImage={activeSlide.heroImage}
            heroPretitle={activeSlide.heroPretitle}
            heroTitle={activeSlide.heroTitle}
            heroTitleHeadingLevel={activeSlide.heroTitleHeadingLevel}
            heroDescription={activeSlide.heroDescription}
            link={activeSlide.link}
            ctaLabel={activeSlide.ctaLabel}
            selectedIndex={selectedIndex}
          />
        )}
        {filteredHeroItems.length > 1 && (
          <Flickity
            flickityRef={(reference: any) => {
              flickityReference.current = reference
            }}
            className={'cmp-hero-slider__slider'} // default ''
            elementType="div" // default 'div'
            options={flickityOptions} // takes flickity options {}
            disableImagesLoaded={false} // default false
          >
            {filteredHeroItems.map((item, index) => (
              <SliderHeroPreview
                key={`${item.id}_${index}`}
                isSelected={selectedIndex === index}
                item={item}
                onAnimationEnd={() => flickityReference.current?.next()}
                onMouseEnter={() => {
                  if (flickityReference.current) {
                    flickityReference.current.pausePlayer()
                  }
                }}
                onMouseLeave={() => {
                  if (flickityReference.current) {
                    flickityReference.current.playPlayer()
                  }
                }}
                onClick={() => flickityReference.current?.select(index, true)}
              />
            ))}
          </Flickity>
        )}
      </div>
    </ErrorWrapper>
  )
}

registerComponent('SliderHero', Hero)

export default Hero
