import React from 'react'
import Svg from '@components/Svg'

const VideoPlayButton = () => {
  return (
    <Svg>
      <path
        d="M6.00098 2.99976L20.001 11.9998L6.00098 20.9998V2.99976Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </Svg>
  )
}

export default VideoPlayButton
