import React from 'react'
import ResponsiveImage from '@components/ResponsiveImage'
import { cls } from '@utils/classnames'

import { QuestionnaireOptionConfig } from '../CoffeeMachineFinderTypes'

const QuestionnaireOption: React.FC<{
  selectedOptions: { [optionId: string]: boolean }
  option: QuestionnaireOptionConfig
  maxAnswers: number
  numberOfSelectedOptions: number
  onToggle: (id: string) => void
  // TODO: not sure yet what to do here but to prevent eslint to complain for now...
  onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>
  isDisabled?: boolean
}> = ({ selectedOptions, onToggle, option, onKeyDown, numberOfSelectedOptions, maxAnswers, isDisabled }) => {
  return (
    <div
      className={cls({
        'coffee-machine-finder__option': true,
        'coffee-machine-finder__option--selected': selectedOptions[option.id],
        'coffee-machine-finder__option--is-disabled':
          (numberOfSelectedOptions >= maxAnswers && !selectedOptions[option.id]) || isDisabled,
      })}
      onClick={() => onToggle(option.id)}
      role="button"
      tabIndex={0}
      onKeyDown={onKeyDown}
    >
      <div className="coffee-machine-finder__option-image">
        <ResponsiveImage src={option.imageSrc} alt={option.title} />
      </div>
      <h4 className="coffee-machine-finder__option-title">{option.title}</h4>
      <h5 className="coffee-machine-finder__option-subtitle">{option.subTitle}</h5>

      <p className="coffee-machine-finder__option-description">{option.description}</p>
    </div>
  )
}

export default QuestionnaireOption
