import React, { useEffect } from 'react'
import { registerComponent } from 'react-register-dom'
import NoResults from '@components/NoResults'
import { isSupportedFileType } from '@utils/isSupportedFileType'
import { uniqueFileBy } from '@utils/uniqueFileBy'

import FileUploaderDragAndDrop from './FileUploaderDragAndDrop'
import FileUploadInstruction from './FileUploadInstruction'
import SupportedFilesList from './SupportedFilesList'

interface FileUploaderProperties {
  title?: string
  inputName: string
  buttonText: string
  buttonAriaLabel: string
  buttonRemoveAriaLabel: string
  uploadFailedText?: string
  fileTypeNotSupportedMessage?: string
  fileMaxSizeMessage?: string
  maxSizeUpload: number
  separatorText?: string
  instructionText: string
}

// TODO: as enhancement, remove error or success messages after a certain time. Eg. NoResults with new props
const FileUploader: React.FC<FileUploaderProperties> = ({
  title,
  inputName,
  buttonText,
  uploadFailedText,
  buttonAriaLabel,
  buttonRemoveAriaLabel,
  fileTypeNotSupportedMessage,
  fileMaxSizeMessage,
  maxSizeUpload,
  separatorText,
  instructionText,
}: FileUploaderProperties): JSX.Element => {
  const [hasReachedMaxTotalSize, setHasReachedMaxTotalSize] = React.useState(false)
  const [totalFileSize, setTotalFileSize] = React.useState(0)
  const [supportedFiles, setSupportedFiles] = React.useState<File[]>([])
  const [unsupportedFiles, setUnsupportedFiles] = React.useState<File[]>([])

  // TODO: totalFileSize always 0 in handleFiles
  let trackedTotalFileSize = totalFileSize

  // TODO: this feels rock'n roll :D maybe as enhancement the structure could be re-worked
  // Eg. include in 1 structure all supported & unsupported files by name as key
  // As well as check for existing files and trying not to upload them again
  function handleFiles(files: FileList, inputReference: React.MutableRefObject<HTMLInputElement>) {
    const arrayOfFiles = [...files]
    setHasReachedMaxTotalSize(false)
    const supportedFilesTemporary = arrayOfFiles.filter((file) => {
      if (!isSupportedFileType(file.type)) {
        return false
      }
      const newTotalSizeUpload = trackedTotalFileSize + file.size

      if (Boolean(newTotalSizeUpload < maxSizeUpload)) {
        trackedTotalFileSize = trackedTotalFileSize + file.size
        setTotalFileSize(newTotalSizeUpload)
        return true
      }

      setHasReachedMaxTotalSize(true)

      return false
    })

    const unsupportedFilesTemporary = arrayOfFiles.filter((file) => !isSupportedFileType(file.type))

    const uniqueSupportedFiles = uniqueFileBy('name', [...supportedFilesTemporary, ...supportedFiles])

    const uniqueUnsupportedFiles = uniqueFileBy('name', [...unsupportedFilesTemporary, ...unsupportedFiles])

    setSupportedFiles(uniqueSupportedFiles)
    setUnsupportedFiles(uniqueUnsupportedFiles)
    const fileList = new DataTransfer()
    for (const file of uniqueSupportedFiles) {
      fileList.items.add(file)
    }

    inputReference.current.files = fileList.files
  }

  function handleRemove(file: File) {
    const updatedSupportedFiles = supportedFiles.filter((supportedFile) => supportedFile.name !== file.name)
    setSupportedFiles(updatedSupportedFiles)
  }

  useEffect(() => {
    let totalSize = 0
    for (const file of supportedFiles) {
      totalSize = totalSize + file.size
    }
    setTotalFileSize(totalSize)
  }, [supportedFiles])

  return (
    <>
      <FileUploadInstruction instructionText={instructionText} />
      <FileUploaderDragAndDrop
        title={title}
        inputName={inputName}
        buttonText={buttonText}
        buttonAriaLabel={buttonAriaLabel}
        handleFileInputChange={handleFiles}
        separatorText={separatorText}
      />
      {totalFileSize > maxSizeUpload || hasReachedMaxTotalSize ? (
        <NoResults title={uploadFailedText || ''} description={fileMaxSizeMessage || ''} isError hasSpacing />
      ) : null}
      {unsupportedFiles?.map((file) => (
        <NoResults
          key={file.name}
          title={`${uploadFailedText} ${file.name}`}
          description={fileTypeNotSupportedMessage || ''}
          isError
          hasSpacing
        />
      ))}
      {supportedFiles ? (
        <SupportedFilesList
          supportedFiles={supportedFiles}
          onSupportedFileRemove={handleRemove}
          buttonRemoveAriaLabel={buttonRemoveAriaLabel}
        />
      ) : null}
    </>
  )
}
registerComponent('FileUploader', FileUploader)

export default FileUploader
