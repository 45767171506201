import React, { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { registerComponent } from 'react-register-dom'
import DamVideo from '@components/DamVideo'
import { cls } from '@utils/classnames'
import { isMobile } from '@utils/mediaQueries'

import CountdownTimer from './Countdown/CountdownTimer'
import CountDownPageHeadlines from './CountDownPageHeadline/'
import { sections } from './constants'
import CountdownTeaser from './CountdownTeaser'
import ScrollingArrow from './ScrollingArrow'
import Section from './Section'

const inViewConfig = {
  threshold: 0,
  trackVisibility: true,
  delay: 100,
}

function onScrollFactory(
  middleSectionReference: MutableRefObject<HTMLDivElement | null>,
  lastSectionReference: MutableRefObject<HTMLDivElement | null>,
  teaserReference: MutableRefObject<HTMLDivElement | null>,
) {
  return () => {
    const footer = document.querySelector('footer')
    const middle = middleSectionReference.current?.getBoundingClientRect().top || 0
    const bottom = lastSectionReference.current?.getBoundingClientRect().top || 0

    if (middle > 0) {
      teaserReference.current?.classList.remove('cmp-countdown-page__teaser--is-fixed')
    } else {
      teaserReference.current?.classList.add('cmp-countdown-page__teaser--is-fixed')
      const teaserHeight = teaserReference.current?.getBoundingClientRect().height || 0
      const sectionHeight = middleSectionReference.current?.getBoundingClientRect().height || 0
      const maxTop = sectionHeight - teaserHeight

      let footerTop = Number.POSITIVE_INFINITY
      if (footer) {
        footerTop = footer.getBoundingClientRect().top
      }

      const targetTop = (bottom * maxTop) / sectionHeight

      // when the footer approaches the top of the page the teaser can be pushed up
      const adjustedTop =
        footerTop >= teaserHeight ? Math.max(Math.min(targetTop, maxTop), 0) : footerTop - teaserHeight

      if (teaserReference.current) {
        teaserReference.current.style.top = `${adjustedTop}px`
      }
      if (lastSectionReference.current) {
        lastSectionReference.current.style.minHeight = `${teaserHeight}px`
      }
    }
  }
}

const CountdownPage: React.FC<{
  jsonData: string
}> = ({ jsonData }) => {
  const { desktopVideo, mobileVideo, teaser, countdown, ariaLabelUp, ariaLabelDown } = JSON.parse(jsonData)

  const [currentSection, setSection] = useState(sections.FIRST)
  const firstSectionReference = useRef<HTMLDivElement | null>(null)
  const middleSectionReference = useRef<HTMLDivElement | null>(null)
  const lastSectionReference = useRef<HTMLDivElement | null>(null)
  const teaserReference = useRef<HTMLDivElement | null>(null)
  const smallScreen = isMobile()

  const { ref: inViewFirstSectionReference, inView: firstSectionInView } = useInView(inViewConfig)
  const { ref: inViewMiddleSectionReference, inView: middleSectionInView } = useInView(inViewConfig)
  const { ref: inViewLastSectionReference, inView: lastSectionInView } = useInView(inViewConfig)

  const setFirstSectionReference = useCallback(
    (node) => {
      firstSectionReference.current = node
      inViewFirstSectionReference(node)
    },
    [inViewFirstSectionReference],
  )
  const setMiddleSectionReference = useCallback(
    (node) => {
      middleSectionReference.current = node
      inViewMiddleSectionReference(node)
    },
    [inViewMiddleSectionReference],
  )
  const setLastSectionReference = useCallback(
    (node) => {
      lastSectionReference.current = node
      inViewLastSectionReference(node)
    },
    [inViewLastSectionReference],
  )
  const setTeaserReference = (node) => {
    teaserReference.current = node
  }

  useEffect(() => {
    if (firstSectionInView) {
      setSection(sections.FIRST)
    }
    if (middleSectionInView) {
      setSection(sections.MIDDLE)
    }
    if (lastSectionInView) {
      setSection(sections.LAST)
    }
  }, [firstSectionInView, middleSectionInView, lastSectionInView])

  function handleOnCallBack(direction: string) {
    if (currentSection === sections.FIRST && direction === 'bottom') {
      middleSectionReference?.current?.scrollIntoView({ behavior: 'smooth' })
    }

    if (currentSection === sections.MIDDLE && direction === 'top') {
      firstSectionReference.current?.scrollIntoView({ behavior: 'smooth' })
    }

    if (currentSection === sections.MIDDLE && direction === 'bottom') {
      lastSectionReference.current?.scrollIntoView({ behavior: 'smooth' })
    }

    if (currentSection === sections.LAST && direction === 'top') {
      middleSectionReference.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }

  function handleOnKeyboard(event: KeyboardEvent) {
    event.preventDefault()

    if (event.key === 'ArrowUp') {
      handleOnCallBack('top')
    }
    if (event.key === 'ArrowDown') {
      handleOnCallBack('bottom')
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleOnKeyboard)
    return () => {
      window.removeEventListener('keydown', handleOnKeyboard)
    }
  })

  useEffect(() => {
    const onScroll = onScrollFactory(middleSectionReference, lastSectionReference, teaserReference)
    // this executes after the first load, after the refs are set (empty-deps-array)
    document.addEventListener('scroll', onScroll)

    return () => {
      document.removeEventListener('scroll', onScroll)
    }
  }, [])

  return (
    <div className="cmp-countdown-page">
      {currentSection !== sections.FIRST ? (
        <div className="cmp-countdown-page__scrolling-arrow cmp-countdown-page__scrolling-arrow--is-up">
          <ScrollingArrow callBack={() => handleOnCallBack('top')} ariaLabel={ariaLabelUp} />
        </div>
      ) : null}
      <div
        className={cls({
          'cmp-countdown-page__sections': true,
          'cmp-countdown-page__sections--hide-video': currentSection === sections.LAST,
        })}
      >
        <DamVideo
          videoUrl={smallScreen ? mobileVideo.video.url : desktopVideo.video.url}
          isEditModeBoolean={false}
          isVideoPlaying
          loopVideo="true"
          hideVideoControls="true"
        />

        <Section ref={setFirstSectionReference}>
          <div className="cmp-countdown-page__sections-wrapper">
            <div className="cmp-countdown-page__timer-headlines">
              <CountdownTimer
                date={countdown.formattedDeadline}
                daysLabel={countdown.labels.daysLabel}
                hoursLabel={countdown.labels.hoursLabel}
                minutesLabel={countdown.labels.minutesLabel}
                secondsLabel={countdown.labels.secondsLabel}
              />
              <CountDownPageHeadlines leftHeadline={countdown.leftHeadline} rightHeadline={countdown.rightHeadline} />
            </div>
          </div>
        </Section>
        <Section ref={setMiddleSectionReference}>
          <div className="cmp-countdown-page__sections-wrapper">
            <div
              className={cls({
                'cmp-countdown-page__teaser': true,
              })}
              ref={setTeaserReference}
            >
              <CountdownTeaser
                title={teaser.title}
                leadText={teaser.leadText}
                description={teaser.description}
                ctaLabel={teaser.ctaLabel}
                ctaLink={teaser.ctaLink}
              />
            </div>
          </div>
        </Section>
        <div ref={setLastSectionReference} className="cmp-countdown-page__placeholder-section"></div>
      </div>
      {currentSection !== sections.LAST ? (
        <div
          className={cls({
            'cmp-countdown-page__scrolling-arrow cmp-countdown-page__scrolling-arrow--is-down': true,
            'cmp-countdown-page__scrolling-arrow--is-middle-section': currentSection === sections.MIDDLE,
          })}
        >
          <ScrollingArrow callBack={() => handleOnCallBack('bottom')} isUp={false} ariaLabel={ariaLabelDown} />
        </div>
      ) : null}
    </div>
  )
}

registerComponent('CountdownPage', CountdownPage)

export default CountdownPage
