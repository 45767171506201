import React from 'react'

import MultiSelectFilter from '../MultiSelectFilter'
import { FilterConfig } from '../ProductExplorerTypes'

interface ProductExplorerFilterListProperties {
  filtersConfig: FilterConfig[]
  activeFilters: Record<string, Set<string>>
  applyLabel: string
  textSearchLabel?: string
  resetLabel: string
  textSearch: string
  onTextSearchChange: React.Dispatch<React.SetStateAction<string>>
  onOptionsChange: (filterId: string, newValue: Set<string>) => void
}

const ProductExplorerFilterList: React.FC<ProductExplorerFilterListProperties> = ({
  filtersConfig,
  applyLabel,
  resetLabel,
  textSearch,
  activeFilters,
  onOptionsChange,
  onTextSearchChange,
  textSearchLabel,
}) => {
  const [searchTermInput, setSearchTermInput] = React.useState(textSearch)

  const handleSearch = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    onTextSearchChange(searchTermInput)
  }

  // TODO: we can get no filters...what to display? Or data problem from PCM and should 'never' happen?
  return filtersConfig ? (
    <div className="cmp-product-explorer-filter-list">
      <div className="cmp-product-explorer-filter-list__list">
        <div className="cmp-product-explorer-filter-list-search__wrapper">
          <form onSubmit={handleSearch}>
            <button type="submit" className="cmp-product-explorer-filter-list-search__button">
              <i className="ri-search-line cmp-product-explorer-filter-list-search__icon"></i>
            </button>
            <input
              type="text"
              className="cmp-product-explorer-filter-list__search"
              value={searchTermInput}
              onChange={(event) => setSearchTermInput(event.target.value)}
              placeholder={textSearchLabel}
            />
          </form>
        </div>

        {filtersConfig.map((filter) => (
          <MultiSelectFilter
            applyLabel={applyLabel}
            resetLabel={resetLabel}
            options={filter.options}
            popoverTitle={filter.label}
            selectionMode="multiple"
            triggerLabel={filter.label}
            key={filter.id}
            selectedOptions={activeFilters[filter.id]}
            filterId={filter.id}
            onSelectedOptionsChange={onOptionsChange}
          />
        ))}
      </div>
    </div>
  ) : null
}

export default ProductExplorerFilterList
