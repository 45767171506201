import React from 'react'
import { registerComponent } from 'react-register-dom'

const SampleSpacing: React.FC<{ message: string }> = ({ message }) => {
  return (
    <div className="sample-spacing">
      <div className="sample-spacing__element-1">
        <span className="sample-spacing__content">{message}</span>
      </div>
      <div className="sample-spacing__element-2">
        <span className="sample-spacing__content">{message}</span>
      </div>
    </div>
  )
}

registerComponent('SampleSpacing', SampleSpacing)

export default SampleSpacing
