import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { registerComponent } from 'react-register-dom'
import LoadingIndicator from '@components/GlobalSearch/LoadingIndicator'
import ErrorWrapper from '@components/UI/ErrorWrapper'
import { MarkerClusterer, SuperClusterAlgorithm } from '@googlemaps/markerclusterer'
import { useAutoGET } from '@hooks/fetch'
import { useStateInUrl } from '@hooks/use-state-in-url'
import { useDebounce } from '@hooks/useDebounce'
import { useGPS, useGpsErrorEffect, useGpsSuccessEffect } from '@hooks/useGps'
import { cls } from '@utils/classnames'
import { sphericalDistance } from '@utils/sphericalDistance'

import { GMapsStyles } from './StoreLocatorMap/GMapsStyles'
import { ScriptLoader } from './StoreLocatorMap/ScriptLoader'
import Label from './Label'
import LocationSearchInputField from './LocationSearchInputField'
import { createInfoWindowForPartner } from './PartnerFinderInfoWindow'
import {
  clusterRenderer,
  getPlaceGeometryById,
  isGeometry,
  useGmapsAutocompleteApi,
  useOrigin,
} from './StoreLocatorHooks'
import { StoreInfo, StoreLocatorConfig, StoreLocatorStoresResponse } from './StoreLocatorTypes'

const mapOptions = {
  styles: GMapsStyles,
}

const mapControls = {
  streetViewControl: false,
  fullscreenControl: false,
  mapTypeControl: false,
}

const ICON_WIDTH = 32
const ICON_HEIGHT = 41

const OPEN_ICON_WIDTH = 12
const OPEN_ICON_HEIGHT = 9

function equals(other) {
  return this.width * this.height === other.width * other.height
}

let openInfoWindow: google.maps.InfoWindow | null = null
let gpsPosition: { lat: number; lng: number } | null = null
let openMarker: google.maps.Marker | null = null
let openPartnerSharedId: string | null = null

interface StoreLocatorProperties {
  jsonData: string
}

const StoreLocator: React.FC<StoreLocatorProperties> = ({ jsonData }) => {
  const config: StoreLocatorConfig = useMemo(() => {
    return JSON.parse(jsonData) as StoreLocatorConfig
  }, [jsonData])
  const mapDivReference = useRef<HTMLDivElement | null>(null)
  const [map, setMap] = useState<google.maps.Map | null>(null)
  const [isGoogleApiReady, setIsGoogleApiReady] = useState(false)
  const [storesState] = useAutoGET<StoreLocatorStoresResponse>(
    config.storesEndpoint.url.replace(config.storesEndpoint.placeholder, config.defaultCountry),
    {},
  )
  const [renderableStores, setRenderableStores] = useState<StoreInfo[]>([])
  const [clusterer, setClusterer] = useState<MarkerClusterer>()
  const [markersMap, setMarkersMap] = useState<Record<string, google.maps.Marker>>({})
  const [searchQuery, setSearchQuery] = useState('')
  const [gpsState, fetchGpsPosition] = useGPS()

  const [latitude, setLatitude] = useStateInUrl(config.presetLatitude || '', 'lat')
  const [longitude, setLongitude] = useStateInUrl(config.presetLongitude || '', 'lng')
  const [zoom, setZoom] = useStateInUrl(config.presetZoom || '', 'zoom')
  const [activeFilters, setActiveFilters] = useStateInUrl('', 'categories')
  const [openPartnerId, setOpenPartnerId] = useStateInUrl('', 'partnerId')

  const { predictions, isPending: autocompleteIsPending } = useGmapsAutocompleteApi(searchQuery)

  const filteredStores = useMemo<StoreInfo[]>(() => {
    if (storesState.status !== 'SUCCESS') {
      return []
    }

    if (activeFilters.length <= 0) {
      return storesState.data.stores
    }

    const activeFilterList = activeFilters.split(',').filter((f) => f.length > 0)
    return storesState.data.stores.filter((store) => {
      return activeFilterList.every((filter) => store.contactCategories.includes(filter))
    })
  }, [storesState, activeFilters])

  const origin = useOrigin({
    map,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    country: config.countryOptions.find((country) => config.defaultCountry === country.value)!.label,
    latitude: Number.parseFloat(latitude) || undefined,
    longitude: Number.parseFloat(longitude) || undefined,
    zoomLevel: Number.parseFloat(zoom) || undefined,
  })

  const icons = useMemo(() => {
    return {
      standard: {
        url:
          config.standardMarkerIcon?.originalImageUrl ||
          '/etc.clientlibs/franke-aem/clientlibs/clientlib-site/resources/images/store-locator-dealer-standard.svg',
        size: { width: ICON_WIDTH, height: ICON_HEIGHT, equals },
        scaledSize: { width: ICON_WIDTH, height: ICON_HEIGHT, equals },
      },
      premium: {
        url:
          config.premiumMarkerIcon?.originalImageUrl ||
          '/etc.clientlibs/franke-aem/clientlibs/clientlib-site/resources/images/store-locator-dealer-premium.svg',
        size: { width: ICON_WIDTH, height: ICON_HEIGHT, equals },
        scaledSize: { width: ICON_WIDTH, height: ICON_HEIGHT, equals },
      },
    }
  }, [config])

  useEffect(() => {
    if (isGoogleApiReady && mapDivReference?.current && !map) {
      // init map here
      const mapObject = new google.maps.Map(mapDivReference.current, {
        ...mapOptions,
        ...mapControls,
        zoom: 6,
        center: { lat: 0, lng: 0 },
      })

      setMap(mapObject)
    }
  }, [isGoogleApiReady, map])

  useEffect(() => {
    if (origin.status === 'SUCCESS' && map) {
      if (openInfoWindow) {
        openInfoWindow.unbindAll()
        openInfoWindow.close()
      }
      if (isGeometry(origin.data)) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        map.fitBounds(origin.data.viewport!)
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        map.setCenter(origin.data.location!)
      } else {
        map.setCenter(origin.data)
        map.panTo(origin.data)
        map.setZoom(origin.data.zoom)
      }
    }
  }, [origin, map])

  useEffect(() => {
    // makes sure every filter update the visible list is updated as well
    handleMapBoundsChange()
  }, [filteredStores])

  const handleMapBoundsChange = useCallback(() => {
    if (map) {
      if (!map.getBounds) {
        console.warn('map object does not have method `getBounds()`, something must be wrong', map)
      }

      const newStoreList = filteredStores.filter((store) =>
        map.getBounds?.()?.contains({ lat: store.lat, lng: store.lng }),
      )
      setLatitude(map.getCenter()?.lat().toString() || '')
      setLongitude(map.getCenter()?.lng().toString() || '')
      setZoom(map.getZoom()?.toString() || '')
      setRenderableStores(newStoreList)
    }
  }, [filteredStores, map, setRenderableStores])

  const debouncedBoundsChangeHandler = useDebounce(handleMapBoundsChange, 400)

  useEffect(() => {
    // this effect updates the list of displayed stores to show only the ones that fit into the map viewport
    if (!map || storesState.status !== 'SUCCESS') {
      return
    }
    const removeListener = map.addListener('bounds_changed', debouncedBoundsChangeHandler)
    // call change handler whenever the stores are updated
    debouncedBoundsChangeHandler()

    return () => {
      removeListener.remove()
    }
  }, [storesState.status, map, debouncedBoundsChangeHandler])

  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    // this effects adds and removes stores everytime the array changes.
    if (!map || !clusterer || storesState.status !== 'SUCCESS') {
      return
    }
    // get the list of existing markers to remove and the one of markers to add
    const markersToDelete: google.maps.Marker[] = []
    const markersToAdd: google.maps.Marker[] = []
    const newMarkersMap: Record<string, google.maps.Marker> = {}

    for (const store of renderableStores) {
      if (markersMap[store.id]) {
        // marker already exist, nothing to do with this one
        newMarkersMap[store.id] = markersMap[store.id]
      } else if (!map.getBounds) {
        console.warn('map object does not have method `getBounds()`, something must be wrong', map)
      } else if (map?.getBounds()?.contains({ lat: store.lat, lng: store.lng })) {
        // exclude stuff out of the map
        // this marker needs to be added
        const iconConfig =
          icons[
            storesState.data.premiumCategoryId && store?.contactCategories?.includes(storesState.data.premiumCategoryId)
              ? 'premium'
              : 'standard'
          ]
        const newMarker = new google.maps.Marker({
          position: store,
          icon: iconConfig,
          map,
          clickable: true,
        })
        newMarker.addListener('click', () => {
          onLocationClick(store, newMarker)
        })
        newMarkersMap[store.id] = newMarker

        markersToAdd.push(newMarker)

        if (openPartnerId.length > 0 && openPartnerId === store.id) {
          onLocationClick(store, newMarker)
        }
      }
    }

    for (const [storeId, marker] of Object.entries(markersMap)) {
      if (!newMarkersMap[storeId]) {
        markersToDelete.push(marker)
      }
    }

    clusterer.removeMarkers(markersToDelete, markersToAdd.length > 0)
    clusterer.addMarkers(markersToAdd)
    setMarkersMap(newMarkersMap)
  }, [renderableStores, map, clusterer, icons])

  useEffect(() => {
    if (map) {
      let clusterManager = clusterer
      if (!clusterManager) {
        const algorithm = new SuperClusterAlgorithm({
          radius: 130,
        })
        clusterManager = new MarkerClusterer({
          map,
          renderer: { render: clusterRenderer },
          algorithm,
        })
      }
      setClusterer(clusterManager)
    }
  }, [map])

  const handleGpsError = useCallback((error: GeolocationPositionError) => {
    console.error(error)
  }, [])

  const handleGpsSuccess = useCallback(
    (position: GeolocationPosition) => {
      gpsPosition = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      }

      if (!map) {
        return
      }

      if (openInfoWindow) {
        openInfoWindow.unbindAll()
        openInfoWindow.close()
      }

      closeInfoWindow(renderableStores.find((store) => store.id === openPartnerSharedId))
      map.panTo({ lat: position.coords.latitude, lng: position.coords.longitude })
      map.setZoom(12)
    },
    [map],
  )

  useGpsErrorEffect(gpsState, handleGpsError)
  useGpsSuccessEffect(gpsState, handleGpsSuccess)

  async function handleSearch(newValue: string) {
    setSearchQuery(newValue)
    const selectedOption = predictions?.find((pred) => pred.description === newValue)
    if (selectedOption && map) {
      const geometry = await getPlaceGeometryById(map, selectedOption.place_id)

      if (geometry?.viewport) {
        map.fitBounds(geometry.viewport)
      }
    }
  }

  function handleTagToggle(id: string) {
    setActiveFilters((old) => {
      if (old.includes(id)) {
        return old
          .replace(id, '')
          .split(',')
          .filter((s) => s.length > 0)
          .join(',')
      }

      return `${old},${id}`
    })
  }

  function closeInfoWindow(partner?: StoreInfo | null) {
    if (openInfoWindow) {
      openInfoWindow.unbindAll()
      openInfoWindow.close()
    }
    if (storesState.status !== 'SUCCESS') {
      return
    }
    const marker = openMarker
    const iconConfig =
      icons[
        storesState.data.premiumCategoryId && partner?.contactCategories?.includes(storesState.data.premiumCategoryId)
          ? 'premium'
          : 'standard'
      ]
    if (marker) {
      marker.setIcon(iconConfig)
    }
    openMarker = null
  }

  function onLocationClick(location: StoreInfo, marker: google.maps.Marker) {
    if (storesState.status !== 'SUCCESS') {
      return
    }

    const distance = gpsPosition
      ? sphericalDistance(location.lat, location.lng, gpsPosition.lat, gpsPosition.lng, config.unitMeasurement).toFixed(
          1,
        )
      : undefined

    closeInfoWindow(renderableStores.find((store) => store.id === openPartnerSharedId))
    openInfoWindow = createInfoWindowForPartner({
      categories: storesState.data.categories,
      locationEntry: location,
      texts: config.texts,
      unitOfMeasure: config.unitMeasurement,
      distance,
      premiumCategoryId: storesState.data.premiumCategoryId,
    })

    setOpenPartnerId(location.id)
    openPartnerSharedId = location.id

    openInfoWindow.addListener('domready', () => {
      document
        .querySelector('.cmp-partner-finder-info-window__link--phone')
        ?.addEventListener('click', () => logLinkClick('phone'))
      document
        .querySelector('.cmp-partner-finder-info-window__google-link')
        ?.addEventListener('click', () => logLinkClick('google maps'))
      document
        .querySelector('.cmp-partner-finder-info-window__link--email')
        ?.addEventListener('click', () => logLinkClick('email'))
    })

    openInfoWindow.addListener('closeclick', () => {
      openPartnerSharedId = null
      openMarker = null
      setOpenPartnerId('')
      openInfoWindow?.unbindAll()
      const iconConfig =
        icons[
          storesState.data.premiumCategoryId &&
          location?.contactCategories?.includes(storesState.data.premiumCategoryId)
            ? 'premium'
            : 'standard'
        ]
      marker.setIcon(iconConfig)
    })

    function logLinkClick(type: string) {
      if (storesState.status !== 'SUCCESS') {
        return
      }
      window.adobeDataLayer?.push({
        event: 'shop locator detail click',
        storeLocator: {
          shopName: location.companyName,
          shopId: location.id,
          country: config.countryOptions.find((country) => config.defaultCountry === country.value)!.label,
          contactType: type,
          ...(storesState.data.premiumCategoryId &&
            location?.contactCategories.includes(storesState.data.premiumCategoryId) && {
              labelId: storesState.data.premiumCategoryId,
            }),
        },
      })
    }

    openInfoWindow.open({
      anchor: marker,
      map,
    })

    window.adobeDataLayer?.push({
      event: 'shop locator expand',
      storeLocator: {
        shopName: location.companyName,
        shopId: location.id,
        country: config.countryOptions.find((country) => config.defaultCountry === country.value)!.label,
        ...(storesState.data.premiumCategoryId &&
          location?.contactCategories.includes(storesState.data.premiumCategoryId) && {
            labelId: storesState.data.premiumCategoryId,
          }),
      },
    })

    marker.setIcon({
      url: '/etc.clientlibs/franke-aem/clientlibs/clientlib-site/resources/images/store-locator-open-dealer.svg',
      size: { width: OPEN_ICON_WIDTH, height: OPEN_ICON_HEIGHT, equals },
      scaledSize: { width: OPEN_ICON_WIDTH, height: OPEN_ICON_HEIGHT, equals },
    })
    openMarker = marker
  }

  return (
    <ErrorWrapper>
      <div className="cmp-partner-finder">
        <ScriptLoader googleMapsApiKey={config.googleApiKey} onScriptReady={() => setIsGoogleApiReady(true)}>
          <div
            className={cls({
              'cmp-partner-finder__map': true,
              'cmp-partner-finder__map--has-premium-partner-open':
                openPartnerId !== '' &&
                storesState.status === 'SUCCESS' &&
                storesState.data.premiumCategoryId &&
                renderableStores
                  .find((store) => store.id === openPartnerId)
                  ?.contactCategories.includes(storesState.data.premiumCategoryId),
              'cmp-partner-finder__map--is-standalone': config.isStandaloneTemplate,
            })}
            ref={mapDivReference}
          />
        </ScriptLoader>
        <div
          className={cls({
            'cmp-partner-finder__filter-section': true,
            'cmp-partner-finder__filter-section--has-partner-open': openPartnerId !== '',
          })}
        >
          <LocationSearchInputField
            hasError={false}
            placeholder={config.texts.locationSearchPlaceholder}
            value={searchQuery}
            onChange={handleSearch}
            onSearch={() => handleSearch(searchQuery)}
            predictions={predictions}
            autocompleteIsPending={autocompleteIsPending}
            onGpsRequest={fetchGpsPosition}
          />
          {storesState.status === 'PENDING' && <LoadingIndicator />}
          {storesState.status === 'SUCCESS' && storesState.data.categories.length > 1 && (
            <div className="cmp-partner-finder__category-list">
              {storesState.data.categories.map((category) => (
                <Label
                  tag={category}
                  key={category.id}
                  onClick={() => handleTagToggle(category.id)}
                  isActive={activeFilters.includes(category.id)}
                  isPremium={category.id === 'premium'}
                >
                  {category.label}
                </Label>
              ))}
            </div>
          )}
        </div>
      </div>
    </ErrorWrapper>
  )
}

registerComponent('StoreLocator', StoreLocator)
export default StoreLocator
