// Example of how a component should be initialized via JavaScript
// This script logs the value of the component's text property model message to the console
// eslint-disable-next-line sonarjs/cognitive-complexity
;(function () {
  'use strict'

  function onDocumentReady() {
    const cmpProductFunctionalitiesClassName = '.cmp-product-functionalities'
    const productFunctionalities = document.querySelector(cmpProductFunctionalitiesClassName)
    if (!Boolean(productFunctionalities)) {
      return
    }
    const showMoreButton = productFunctionalities.querySelector(
      cmpProductFunctionalitiesClassName + '__cta-button--more',
    )
    const showLessButton = productFunctionalities.querySelector(
      cmpProductFunctionalitiesClassName + '__cta-button--less',
    )

    const iconGridItemSelector = '.cmp-icon-grid__item'
    // eslint-disable-next-line prettier/prettier
    if (productFunctionalities.querySelectorAll(iconGridItemSelector).length <= 6) {
      showMoreButton.classList.add('cmp-product-functionalities__cta-button--hide-on-desktop')
      // eslint-disable-next-line prettier/prettier
      if (productFunctionalities.querySelectorAll(iconGridItemSelector).length <= 3) {
        showMoreButton.classList.add('cmp-product-functionalities__cta-button--hide-on-mobile')
      }
    }

    function toggleIconGrid(visibility) {
      if (visibility === 'show') {
        productFunctionalities.classList.remove('cmp-product-functionalities--hidden')
      } else if (visibility === 'hide') {
        productFunctionalities.classList.add('cmp-product-functionalities--hidden')
      }
    }

    showMoreButton?.addEventListener('click', () => {
      toggleIconGrid('show')
    })
    showLessButton?.addEventListener('click', () => {
      toggleIconGrid('hide')
    })
  }

  if (document.readyState !== 'loading') {
    onDocumentReady()
  } else {
    document.addEventListener('DOMContentLoaded', onDocumentReady)
  }
})()
