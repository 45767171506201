import React from 'react'
import ButtonLink from '@components/ButtonLink'
import { clickedFloaterButton } from '@components/FloaterBar/floaterButtonEvents'

const FloaterBarItem: React.FC<{ floaterItem: any }> = ({ floaterItem }) => {
  return floaterItem.buttonType === 'link' ? (
    <a href={floaterItem.link} className="cmp-floater-bar__item" rel={floaterItem.linkRelAttribute}>
      <i className={floaterItem.icon}></i>
    </a>
  ) : (
    <div className="cmp-floater-bar__item">
      <ButtonLink onClick={() => clickedFloaterButton(floaterItem.applicationType)} text={''} icon={floaterItem.icon} />
    </div>
  )
}
export default FloaterBarItem
