import { FetchState } from '@hooks/fetch'

function AsyncCallResult<DataType, ErrorDataType>({
  renderLoading,
  renderSuccess,
  renderError,
  state,
}: {
  renderLoading: () => JSX.Element
  renderSuccess: (data: DataType) => JSX.Element
  renderError: (error: ErrorDataType, response?: Response) => JSX.Element
  state: FetchState<DataType, ErrorDataType>
}) {
  if (state.status === 'NEVER_EXECUTED' || state.status === 'PENDING') {
    return <>{state.status === 'PENDING' && state.data ? renderSuccess(state.data as DataType) : renderLoading()}</>
  }

  if (state.status === 'SUCCESS') {
    return renderSuccess(state.data)
  }

  if (state.status === 'ERROR') {
    return renderError(state.data, state.response)
  }

  return null
}

export default AsyncCallResult
