import React from 'react'
import { registerComponent } from 'react-register-dom'

const ReactComponentSample: React.FC<{ message: string }> = ({ message }) => {
  return <div className="react-component-sample">{message}</div>
}

registerComponent('ReactComponentSample', ReactComponentSample)

export default ReactComponentSample
