import { useState } from 'react'
import AsyncCallResult from '@components/AsyncCallResult'
import ButtonLink from '@components/ButtonLink'
import { MoreResultsView } from '@components/Header/Search'
import NoResults from '@components/NoResults'
import { useAutoGET } from '@hooks/fetch'

import GlobalSearchResults from '../GlobalSearchResults/GlobalSearchResults'
import LoadingIndicator from '../LoadingIndicator'

const GlobalSearchMoreResultsView = ({
  labels,
  showAllResultLimit,
  searchTerm,
  moreResultsView,
  setMoreResultsView,
}: {
  labels: {
    errorApiTitle: string
    errorApiDescription: string
    noResultsTitle: string
    noResultsDescription: string
    showAllLabel: string
    showMoreLabel: string
    backlinkLabel: string
  }
  showAllResultLimit: string
  searchTerm: string
  moreResultsView: MoreResultsView
  setMoreResultsView: (data: MoreResultsView) => void
}): JSX.Element => {
  const [resultAmount, setResultAmount] = useState(showAllResultLimit)
  const queryParameters =
    moreResultsView.type === 'PCM_PRODUCTS'
      ? {
          query: searchTerm,
          page_size: resultAmount,
        }
      : {
          fulltextTerm: searchTerm,
          pageSize: resultAmount,
        }
  const [resultsState] = useAutoGET<any>(
    moreResultsView.endpoint,
    queryParameters,
    false,
    searchTerm == '' || !moreResultsView.endpoint,
  )

  return (
    <div className="cmp-global-search-view-more">
      <ButtonLink text={labels.backlinkLabel} icon="ri-arrow-left-line" onClick={() => setMoreResultsView({})} />
      <div className="cmp-global-search-view-more__container">
        <div className="cmp-global-search-view-more__label">
          <h2>{moreResultsView.label}</h2>
          <p className="cmp-global-search-view-more__label--counter">
            (
            {resultsState.status === 'SUCCESS'
              ? moreResultsView.type === 'PCM_PRODUCTS'
                ? resultsState.data.totalElementCount
                : resultsState.data.numberOfResults
              : 0}
            )
          </p>
        </div>

        <AsyncCallResult
          state={resultsState}
          renderError={() => {
            return (
              <NoResults title={labels.errorApiTitle} description={labels.errorApiDescription} isError hasSpacing />
            )
          }}
          renderLoading={() => <LoadingIndicator />}
          renderSuccess={(data) => {
            if (data?.resultItems?.length === 0 || !data) {
              return <NoResults hasSpacing title={labels.noResultsTitle} description={labels.noResultsDescription} />
            }

            return (
              <div className="cmp-global-search-sections-item__container">
                <div className="cmp-global-search-sections-item__results">
                  <GlobalSearchResults
                    baseUrlTemplate={moreResultsView.baseUrlTemplate}
                    data={data}
                    type={moreResultsView.type}
                    division={moreResultsView.division}
                    searchTerm={searchTerm}
                    productVariantLabel={data.productVariantLabel || ''}
                  />
                </div>
                {(data.hasMore || data.productList?.length < data.totalElementCount) && (
                  <ButtonLink
                    hasSpacing
                    className="cmp-global-search-sections-item__view-all"
                    text={labels.showMoreLabel}
                    onClick={() =>
                      setResultAmount((current) => {
                        return (Number.parseInt(current) + Number.parseInt(showAllResultLimit)).toString()
                      })
                    }
                  ></ButtonLink>
                )}
              </div>
            )
          }}
        />
      </div>
    </div>
  )
}

export default GlobalSearchMoreResultsView
