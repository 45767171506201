import * as React from 'react'
import { parseJsonToFormData } from '@utils/parseJsonToFormData'

const MOCK_DEFAULT_TIMEOUT = 3000

export type FetchState<DataType, ErrorDataType> =
  | {
      status: 'NEVER_EXECUTED'
    }
  | {
      status: 'PENDING'
      data?: DataType | ErrorDataType
    }
  | {
      status: 'SUCCESS'
      data: DataType
    }
  | {
      status: 'ERROR'
      data: ErrorDataType
      response?: Response
    }

function mockHelper<DataType, ErrorType>(
  setState: React.Dispatch<React.SetStateAction<FetchState<DataType, ErrorType>>>,
  mockData: DataType,
) {
  return () => {
    setTimeout(() => {
      setState({
        status: 'SUCCESS',
        data: mockData,
      })
    }, MOCK_DEFAULT_TIMEOUT)
  }
}

/**
 *
 * @param url the base url
 * @param queryParameters a key value map for query parameters
 * @param mockData data to be used instead of calling the endpoint, for test purposes only
 * @returns the current state of the call and the method to perform the fetch request
 */
// eslint-disable-next-line sonarjs/cognitive-complexity
export function useGET<DataType, ErrorType = unknown>({
  url,
  queryParameters,
  mockData,
  options,
}: {
  url?: string
  queryParameters?: Record<string, string | string[] | undefined> | string
  mockData?: DataType
  options?: Record<string, unknown>
}): [FetchState<DataType, ErrorType>, () => void] {
  const [callState, setCallState] = React.useState<FetchState<DataType, ErrorType>>({ status: 'NEVER_EXECUTED' })

  const performCall = React.useCallback(async () => {
    let endpointUrl: string | undefined = ''
    let urlParameters: URLSearchParams | string
    if (queryParameters) {
      if (typeof queryParameters !== 'string') {
        const searchString = new URLSearchParams()
        for (const [key, value] of Object.entries(queryParameters)) {
          if (!value) {
            continue
          }
          if (typeof value === 'string') {
            searchString.append(key, value)
          } else {
            for (const valueEntry of value) {
              searchString.append(key, valueEntry)
            }
          }
        }
        urlParameters = searchString
      } else {
        urlParameters = queryParameters
      }

      endpointUrl = `${url}${url?.includes('?') ? '&' : '?'}${urlParameters}`
    } else {
      endpointUrl = url
    }

    setCallState((old) => ({
      ...old, // preserve existing data
      status: 'PENDING',
    }))
    try {
      if (!endpointUrl) {
        throw new Error('no url defined for the endpoint')
      }
      const response = await fetch(endpointUrl?.toString(), options)

      const responseBodyAsText = await response.text()

      let data: DataType | ErrorType = new Error('something went wrong') as unknown as ErrorType
      if (responseBodyAsText.length > 0) {
        try {
          data = JSON.parse(responseBodyAsText)
        } catch (error) {
          data = error
        }
      }

      setCallState(
        !data['errors'] && response.status >= 200 && response.status < 400
          ? {
              status: 'SUCCESS',
              data: data as DataType,
            }
          : {
              status: 'ERROR',
              data: data as ErrorType,
              response,
            },
      )
    } catch (error) {
      setCallState({
        status: 'ERROR',
        data: error,
      })
    }
  }, [url, JSON.stringify(queryParameters ?? {})])

  const useMockData = React.useMemo(() => mockHelper(setCallState, mockData), [setCallState, mockData])

  return [callState, mockData ? useMockData : performCall]
}

/**
 *
 * @param url the base url
 * @param queryParameters a key value map for query parameters
 * @param mockData data to be used instead of calling the endpoint, for test purposes only
 * @returns the current state of the call and the method to perform the fetch request
 */
// eslint-disable-next-line sonarjs/cognitive-complexity
export function usePOSTFormData<
  DataType,
  PayloadType extends Record<string, string | Blob | boolean>,
  ErrorType = unknown,
>({
  url,
  mockData,
}: {
  url: string
  mockData?: DataType
}): [FetchState<DataType, ErrorType>, (payloadAsJson: PayloadType) => void] {
  const [callState, setCallState] = React.useState<FetchState<DataType, ErrorType>>({ status: 'NEVER_EXECUTED' })

  const performCall = React.useCallback(
    async (payloadAsJson: PayloadType) => {
      const formData = parseJsonToFormData(payloadAsJson)

      setCallState((old) => ({
        ...old, // preserve existing data
        status: 'PENDING',
      }))
      try {
        const response = await fetch(url, {
          method: 'POST',
          body: formData,
        })

        const responseBodyAsText = await response.text()

        let data: DataType | ErrorType = new Error('something went wrong') as unknown as ErrorType
        if (responseBodyAsText.length > 0) {
          try {
            data = JSON.parse(responseBodyAsText)
          } catch (error) {
            data = error
          }
        }

        setCallState(
          (!Boolean(data) || !data['errors']) && response.status >= 200 && response.status < 400
            ? {
                status: 'SUCCESS',
                data: data as DataType,
              }
            : {
                status: 'ERROR',
                data: data as ErrorType,
                response,
              },
        )
      } catch (error) {
        setCallState({
          status: 'ERROR',
          data: error,
        })
      }
    },
    [url],
  )

  const useMockData = React.useMemo(() => mockHelper(setCallState, mockData), [setCallState, mockData])

  return [callState, mockData ? useMockData : performCall]
}

/**
 * automatically calls some endpoint at mount and every time any param changes
 * implemented using useGET() internally
 * @param url the base url
 * @param queryParameters a key value map for query parameters
 * @param mockData data to be used instead of calling the endpoint, for test purposes only
 * @param preventCall prevent call from bein executed
 * @returns
 */
export function useAutoGET<DataType, ErrorType = unknown>(
  url?: string,
  queryParameters?: { [key: string]: string | string[] | undefined },
  mockData?: DataType,
  preventCall?: boolean,
  options?: Record<string, unknown>,
): [FetchState<DataType, ErrorType>, () => void] {
  const [state, call] = useGET<DataType, ErrorType>({
    url,
    queryParameters,
    mockData,
    options,
  })

  React.useEffect(() => {
    !preventCall && call()
  }, [call])

  return [state, call]
}

export function useFetchSuccess<DataType, ErrorType = unknown>(
  state: FetchState<DataType, ErrorType>,
  callback: (data: DataType) => void,
) {
  React.useEffect(() => {
    if (state.status === 'SUCCESS') {
      callback(state.data)
    }
  }, [callback, state.status])
}

export function useFetchError<DataType, ErrorType = unknown>(
  state: FetchState<DataType, ErrorType>,
  callback: (data: { status: 'ERROR'; data: ErrorType; response?: Response }) => void,
) {
  React.useEffect(() => {
    if (state.status === 'ERROR') {
      callback(state)
    }
  }, [callback, state.status])
}
