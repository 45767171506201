// eslint-disable-next-line sonarjs/cognitive-complexity
;(function () {
  'use strict'

  function animateGrid(iconGrid) {
    const iconItem = iconGrid.querySelectorAll('.cmp-icon-grid__item')
    const timer = iconGrid?.dataset?.timer
    const animateItems = iconGrid.querySelectorAll('animate')

    if (timer > 0) {
      for (const [index, item] of animateItems?.entries()) {
        setTimeout(() => {
          item.beginElement()
        }, (index + 1) * timer)
      }
      for (const [index, item] of iconItem?.entries()) {
        item.classList.add('cmp-icon-grid__item--is-animated')
        const iconTitle = item.querySelector('.cmp-icon-grid__item-title')
        const iconDescription = item.querySelector('.cmp-icon-grid__item-description')

        iconTitle.style.animationDelay = `${(index + 1) * timer}ms`
        iconTitle.style.animationDuration = `${timer}ms`
        iconDescription.style.animationDelay = `${(index + 1) * timer}ms`
        iconDescription.style.animationDuration = `${timer}ms`
      }
    }
  }

  const prepareAnimation = (targetElement) => {
    const timer = targetElement?.dataset?.timer
    const paths = targetElement.querySelectorAll('svg path')
    for (const path of paths) {
      let pathLength = path.getTotalLength()
      path.setAttribute('stroke-dasharray', pathLength)
      path.setAttribute('stroke-dashoffset', pathLength)
      path.innerHTML = `<animate attributeName="stroke-dashoffset" begin="indefinite" dur="${timer}ms" to="0" fill="freeze"/>`
    }
  }

  function onDocumentReady() {
    const iconGrids = document.querySelectorAll('.cmp-icon-grid--is-animated')

    const observer = new IntersectionObserver((elements) => {
      for (const element of elements) {
        prepareAnimation(element.target)
        if (element.isIntersecting) {
          animateGrid(element.target)
        }
      }
    })

    for (const element of iconGrids) {
      observer.observe(element)
    }
  }

  if (document.readyState !== 'loading') {
    onDocumentReady()
  } else {
    document.addEventListener('DOMContentLoaded', onDocumentReady)
  }
})()
