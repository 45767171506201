/* eslint-disable sonarjs/no-duplicate-string */

// Example of how a component should be initialized via JavaScript
// This script logs the value of the component's text property model message to the console
// eslint-disable-next-line sonarjs/cognitive-complexity
;(function () {
  'use strict'

  function copyProperties(a, b, list) {
    for (const property of list) {
      b[property] = a[property]
    }
    return b
  }

  function replicatePointerEvent(eventName, event) {
    const properties = copyProperties(event, {}, [
      'altKey',
      'bubbles',
      'button',
      'buttons',
      'cancelBubble',
      'cancelable',
      'clientX',
      'clientY',
      'composed',
      'ctrlKey',
      'defaultPrevented',
      'detail',
      'eventPhase',
      'height',
      'isPrimary',
      'isTrusted',
      'layerX',
      'layerY',
      'metaKey',
      'movementX',
      'movementY',
      'mozInputSource',
      'offsetX',
      'offsetY',
      'pageX',
      'pageY',
      'pointerId',
      'pointerType',
      'pressure',
      'rangeOffset',
      'returnValue',
      'screenX',
      'screenY',
      'shiftKey',
      'srcElement',
      'tangentialPressure',
      'tiltX',
      'tiltY',
      'timeStamp',
      'twist',
      'type',
      'view',
      'which',
      'width',
      'x',
      'y',
    ])

    const newEvent = new PointerEvent(eventName, properties)
    newEvent.replicated = true

    return newEvent
  }
  function replicateTouchEvent(eventName, event) {
    const properties = copyProperties(event, {}, [
      'altKey',
      'bubbles',
      'cancelBubble',
      'cancelable',
      'changedTouches',
      'composed',
      'ctrlKey',
      'defaultPrevented',
      'detail',
      'eventPhase',
      'isTrusted',
      'layerX',
      'layerY',
      'metaKey',
      'rangeOffset',
      'rangeParent',
      'returnValue',
      'shiftKey',
      'srcElement',
      'target',
      'targetTouches',
      'timeStamp',
      'touches',
      'type',
      'view',
      'which',
    ])

    const newEvent = new TouchEvent(eventName, properties)
    newEvent.replicated = true

    return newEvent
  }
  function replicateMouseEvent(eventName, event) {
    const properties = copyProperties(event, {}, [
      'altKey',
      'bubbles',
      'button',
      'buttons',
      'cancelBubble',
      'cancelable',
      'clientX',
      'clientY',
      'composed',
      'ctrlKey',
      'defaultPrevented',
      'detail',
      'eventPhase',
      'explicitOriginalTarget',
      'isTrusted',
      'layerX',
      'layerY',
      'metaKey',
      'movementX',
      'movementY',
      'mozInputSource',
      'offsetX',
      'offsetY',
      'pageX',
      'pageY',
      'rangeOffset',
      'rangeParent',
      'returnValue',
      'screenX',
      'screenY',
      'shiftKey',
      'timeStamp',
      'type',
      'view',
      'which',
      'x',
      'y',
    ])

    const newEvent = new MouseEvent(eventName, properties)
    newEvent.replicated = true

    return newEvent
  }

  function setVisibleSpinViewer(skipAnalytics, interactedElementTitle, isTypeAndNotVariant) {
    const selectedModel = document.querySelector(
      '.cmp-spin-viewer__radio-group--models .cmp-spin-viewer__radio[aria-checked="true"]',
    )
    const selectedColor = document.querySelector(
      '.cmp-spin-viewer__radio-group--variants .cmp-spin-viewer__radio[aria-checked="true"]',
    )

    if (!selectedModel || !selectedColor) {
      return
    }

    const type = selectedModel.id.replace('spin-viewer-menu-type-', '')
    const variant = selectedColor.id.replace('spin-viewer-menu-variant-', '')

    const targetId = `#spin-viewer-type-${type}-variant-${variant}`
    const targetBase = document.querySelector(targetId)
    const target = targetBase ? targetBase.parentElement.parentElement : null

    const selectedModelType = selectedModel.getAttribute('aria-labelledby')
    const selectedModelVariant = selectedColor.getAttribute('aria-labelledby')

    if (!target) {
      return
    }

    const oldOne = document.querySelector('.dmspinvieweritem[data-is-visible="true"]')
    if (oldOne) {
      oldOne.dataset.isVisible = 'false'
    }
    target.dataset.isVisible = 'true'

    if (skipAnalytics || !window.adobeDataLayer) {
      return
    }

    window.adobeDataLayer.push({
      event: `spinviewer ${isTypeAndNotVariant ? 'model' : 'colour'} interactions`,
      eventInfo: {
        reference: 'Spin Viewer',
        title: interactedElementTitle, // title written in the clickable element
        componentId: targetId,
        Model: selectedModelType,
        Colour: selectedModelVariant,
      },
    })
  }

  function addAccessibilityInteractions() {
    const radioGroups = document.querySelectorAll('.cmp-spin-viewer__radio-group')
    for (const radioGroup of radioGroups) {
      radioGroup.addEventListener('keydown', (event) => {
        const radios = radioGroup.querySelectorAll('.cmp-spin-viewer__radio')

        let selectedIndex = 0
        for (const [index, radio] of radios.entries()) {
          if (radio.hasAttribute('aria-checked') && radio.getAttribute('aria-checked') === 'true') {
            selectedIndex = index
          }
        }
        let focusedIndex = 0
        for (const [index, radio] of radios.entries()) {
          if (radio.dataset.hasFocus === 'true') {
            focusedIndex = index
          }
        }
        function selectIndex(targetIndex) {
          const target = radios.item((targetIndex + radios.length) % radios.length)
          target.setAttribute('aria-checked', 'true')
          const old = radios.item(selectedIndex)
          old.setAttribute('aria-checked', 'false')
          const titleElement = target.querySelector('.cmp-spin-viewer__model-title,.cmp-spin-viewer__color-title')
          const title = titleElement ? titleElement.textContent : null

          setVisibleSpinViewer(false, title, titleElement.classList.contains('cmp-spin-viewer__model-title'))
        }

        function focusIndex(targetIndex) {
          const target = radios.item((targetIndex + radios.length) % radios.length)
          target.dataset.hasFocus = true
          const old = radios.item(focusedIndex)
          old.dataset.hasFocus = false
        }

        switch (event.key) {
          case 'ArrowDown':
          case 'ArrowRight': {
            event.preventDefault()
            // select next one
            focusIndex(focusedIndex + 1)

            break
          }
          case 'ArrowUp':
          case 'ArrowLeft': {
            event.preventDefault()
            // select previous one
            focusIndex(focusedIndex - 1)

            break
          }
          case ' ': {
            event.preventDefault()
            selectIndex(focusedIndex)

            break
          }
          // No default
        }
      })

      radioGroup.addEventListener('focus', () => {
        const radios = radioGroup.querySelectorAll('.cmp-spin-viewer__radio')
        let selectedIndex = 0
        for (const [index, radio] of radios.entries()) {
          if (radio.hasAttribute('aria-checked') && radio.getAttribute('aria-checked') === 'true') {
            selectedIndex = index
          }
        }
        radios.item(selectedIndex).dataset.hasFocus = 'true'
      })

      radioGroup.addEventListener('blur', () => {
        const focused = radioGroup.querySelector('.cmp-spin-viewer__radio[data-has-focus="true"]')
        if (focused) {
          focused.dataset.hasFocus = 'false'
        }
      })

      const radioButtons = radioGroup.querySelectorAll('.cmp-spin-viewer__radio')
      for (const radioButton of radioButtons) {
        radioButton.addEventListener('click', () => {
          const checked = radioGroup.querySelector('.cmp-spin-viewer__radio[aria-checked="true"]')
          if (checked) {
            checked.setAttribute('aria-checked', 'false')
            checked.dataset.hasFocus = 'false'
          }
          radioButton.setAttribute('aria-checked', 'true')
          const titleElement = radioButton.querySelector('.cmp-spin-viewer__model-title,.cmp-spin-viewer__color-title')
          const title = titleElement ? titleElement.textContent : null
          setVisibleSpinViewer(false, title, titleElement.classList.contains('cmp-spin-viewer__model-title'))
        })
      }
    }
  }

  function syncMouseEvent(eventName, eventListSelector) {
    const list = document.querySelectorAll(eventListSelector)

    for (const [index, element] of list.entries()) {
      element.addEventListener(eventName, (event) => {
        if (event.replicated) {
          return
        }
        for (const [index_, target] of list.entries()) {
          if (index !== index_) {
            target.dispatchEvent(replicateMouseEvent(eventName, event))
          }
        }
      })
    }
  }
  function syncPointerEvent(eventName, eventListSelector) {
    const list = document.querySelectorAll(eventListSelector)

    for (const [index, element] of list.entries()) {
      element.addEventListener(eventName, (event) => {
        if (event.replicated) {
          return
        }
        for (const [index_, target] of list.entries()) {
          if (index !== index_) {
            target.dispatchEvent(replicatePointerEvent(eventName, event))
          }
        }
      })
    }
  }
  function syncTouchEvent(eventName, eventListSelector) {
    const list = document.querySelectorAll(eventListSelector)

    for (const [index, element] of list.entries()) {
      element.addEventListener(eventName, (event) => {
        if (event.replicated) {
          return
        }
        for (const [index_, target] of list.entries()) {
          if (index !== index_) {
            target.dispatchEvent(replicateTouchEvent(eventName, event))
          }
        }
      })
    }
  }

  function syncAllSpinners() {
    syncMouseEvent('click', '.s7zoominbutton')
    syncMouseEvent('click', '.s7zoomoutbutton')
    syncMouseEvent('click', '.s7zoomresetbutton')
    syncMouseEvent('click', '.s7panleftbutton')
    syncMouseEvent('click', '.s7panrightbutton')

    syncMouseEvent('click', 'canvas')
    syncMouseEvent('dblclick', 'canvas')
    syncMouseEvent('mousedown', 'canvas')
    syncMouseEvent('mousemove', 'canvas')
    syncMouseEvent('mouseup', 'canvas')

    syncPointerEvent('pointerdown', 'canvas')
    syncPointerEvent('pointermove', 'canvas')
    syncPointerEvent('pointerup', 'canvas')
    syncPointerEvent('pointercancel', 'canvas')

    syncTouchEvent('touchstart', 'canvas')
    syncTouchEvent('touchmove', 'canvas')
    syncTouchEvent('touchend', 'canvas')
    syncTouchEvent('touchcancel', 'canvas')

    const fullScreenButton = document.querySelector('.s7fullscreenbutton')
    const layout = document.querySelector('#cmp-spin-viewer__layout')

    fullScreenButton?.addEventListener('click', () => {
      layout.classList.toggle('cmp-spin-viewer__layout--is-full-screen')
    })
  }

  function attemptToSyncEvents() {
    const numberOfModels = document.querySelectorAll('.cmp-spin-viewer__radio-group--models').length
    const numberOfVariants = document.querySelectorAll('.cmp-spin-viewer__radio-group--variants').length
    if (!S7dmUtils) {
      window.requestAnimationFrame(attemptToSyncEvents)
    }
    const loadedCarousels = Object.keys(S7dmUtils).filter((k) => k !== 'Viewer')
    const allInitComplete =
      loadedCarousels.length >= numberOfModels * numberOfVariants &&
      loadedCarousels.every((k) => S7dmUtils[k].initializationComplete)
    if (allInitComplete) {
      setTimeout(syncAllSpinners, 1000)
    } else {
      window.requestAnimationFrame(attemptToSyncEvents)
    }
  }

  function isOverflown(element) {
    return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth
  }

  function isScrolledToBottom(element) {
    return element.scrollTop + element.offsetHeight === element.scrollHeight
  }
  function onDocumentReady() {
    const hasSpinViewerInPage = document.querySelector('.cmp-spin-viewer') !== null
    const variant = document.querySelector('.cmp-spin-viewer__radio-group--variants .cmp-spin-viewer__variants-layout')

    if (!hasSpinViewerInPage) {
      return
    }

    if (variant && isOverflown(variant)) {
      const html = `<div class="cmp-spin-viewer__scroll-button">
             <i class="ri-arrow-down-line cmp-spin-viewer__scroll-button--down"></i>
             <i class="ri-arrow-up-line cmp-spin-viewer__scroll-button--up"></i>
           </div>`

      const variantWrapper = variant.closest('.cmp-spin-viewer__radio-group')
      variantWrapper.insertAdjacentHTML('beforeend', html)

      const scrollButton = variantWrapper.querySelector('.cmp-spin-viewer__scroll-button')
      scrollButton.addEventListener('click', adjustScrollPosition)
      variant.addEventListener('scroll', adjustArrowDirection)
      function adjustScrollPosition() {
        if (isScrolledToBottom(variant)) {
          variant.scrollTo({ top: 0, behavior: 'smooth' })
        } else {
          variant.scrollBy({
            top: 140,
            behavior: 'smooth',
          })
        }
      }

      function adjustArrowDirection() {
        const arrowUp = scrollButton.querySelector('.cmp-spin-viewer__scroll-button--up')
        const arrowDown = scrollButton.querySelector('.cmp-spin-viewer__scroll-button--down')
        if (isScrolledToBottom(variant)) {
          arrowDown.style.display = 'none'
          arrowUp.style.display = 'block'
        } else {
          arrowUp.style.display = 'none'
          arrowDown.style.display = 'block'
        }
      }
    }

    addAccessibilityInteractions()
    window.setTimeout(attemptToSyncEvents, 60)

    setVisibleSpinViewer(true)
  }

  if (document.readyState !== 'loading') {
    onDocumentReady()
  } else {
    document.addEventListener('DOMContentLoaded', onDocumentReady)
  }
})()
