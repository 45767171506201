import { FC, Fragment, useMemo, useRef, useState } from 'react'
import { registerComponent } from 'react-register-dom'
import { ProductSnapshot } from '@components/ProductExplorer/ProductExplorerTypes'
import ProductListItem from '@components/ProductExplorer/ProductListItem'
import { cls } from '@utils/classnames'
import { getMediaQuery, useWindowResize } from '@utils/mediaQueries'

interface ProductsCarouselProperties {
  title: string
  buttonNextAccessibilityLabel?: string
  buttonPreviousAccessibilityLabel?: string
  baseProductUrl: string
  products: ProductSnapshot[]
}

const pageSizeByScreenSize = {
  mq1: 2,
  mq2: 3,
  mq3: 4,
  mq4: 4,
  mq5: 4,
}

const MIN_SWIPE_MOVEMENT = 25

const ProductsCarousel: FC<{ jsonData }> = ({ jsonData }) => {
  const {
    products,
    title,
    baseProductUrl,
    buttonNextAccessibilityLabel = '',
    buttonPreviousAccessibilityLabel = '',
  } = useMemo(() => JSON.parse(jsonData) as ProductsCarouselProperties, [jsonData])

  const windowReference = useRef<HTMLDivElement>(null)
  const touchStartReference = useRef<number | null>(null)

  const [currentOffset, setCurrentOffset] = useState<number>(0)
  const [marginOffset, setMarginOffset] = useState<number>(0)

  const mq = useWindowResize(getMediaQuery)
  const pageSize = pageSizeByScreenSize[mq]
  const totalElementCount = products.length
  const maxOffset = Math.max(totalElementCount - pageSize, 0)
  const hasElementsOnLeft = currentOffset > 0
  const hasElementsOnRight = currentOffset < maxOffset
  let minNumberOfPages = 1
  if (hasElementsOnLeft) {
    minNumberOfPages += 1
  }
  if (hasElementsOnRight) {
    minNumberOfPages += 1
  }
  const totalPageCount = Math.max(Math.ceil(totalElementCount / pageSize), minNumberOfPages)
  // eslint-disable-next-line unicorn/no-new-array
  const pageDots = new Array(totalPageCount).fill(0).map((_, index) => index)
  const currentPageIndex = Math.ceil(currentOffset / pageSize)

  function calculateWidthAndGap() {
    if (!windowReference.current) {
      return [0, 0]
    }
    const newTileWidth = windowReference.current.querySelector('li')?.getBoundingClientRect().width || 0
    const listElelemnt = windowReference.current.querySelector('ul')
    if (!listElelemnt) {
      return [0, 0]
    }
    const gap = window.getComputedStyle(listElelemnt).gap
    const newTileGap = Number.parseFloat(gap.replace('px', ''))

    return [newTileWidth, typeof newTileGap === 'number' ? newTileGap : 0]
  }

  const [tileWidth, tileGap] = useWindowResize(calculateWidthAndGap)

  const listMargin = useMemo(() => {
    return -1 * (tileWidth + tileGap) * currentOffset
  }, [tileWidth, currentOffset])

  function goToPage(pageIndex: number) {
    const pageDiff = pageIndex - currentPageIndex
    const offsetDiff = pageDiff * pageSize
    const maxOffset = totalElementCount - pageSize

    setCurrentOffset((oldOffset) => {
      const newOffset = oldOffset + offsetDiff

      return Math.max(Math.min(maxOffset, newOffset), 0)
    })
  }

  const onTouchStart = (event: React.TouchEvent<HTMLUListElement>) => {
    touchStartReference.current = event.targetTouches[0].clientX
  }
  const onTouchMove = (event: React.TouchEvent<HTMLUListElement>) => {
    if (touchStartReference.current) {
      const delta = event.targetTouches[0].clientX - touchStartReference.current
      setMarginOffset(delta)
    }
  }
  const onTouchEnd = () => {
    touchStartReference.current = null
    // calculate snap
    const hasMovedEnough = Math.abs(marginOffset) > MIN_SWIPE_MOVEMENT
    if (hasMovedEnough) {
      const steps = Math.ceil(Math.abs(marginOffset / (tileWidth + tileGap)))
      const stepsOff = marginOffset > 0 ? steps * -1 : steps

      setCurrentOffset((old) => Math.max(0, Math.min(old + stepsOff, maxOffset)))
    }
    setMarginOffset(0)
  }
  const handleFocus = (index: number) => {
    const isVisibleOnPage = index >= currentOffset && index < currentOffset + pageSize

    if (isVisibleOnPage) {
      return
    }

    if (index < currentOffset) {
      goToPage(currentPageIndex - 1)
    } else {
      goToPage(currentPageIndex + 1)
    }
  }

  return (
    <div className="cmp-suggested-products-carousel">
      <div className="cmp-suggested-products-carousel__container">
        <h2 className="cmp-suggested-products-carousel__title">{title}</h2>
        <div className="cmp-suggested-products-carousel__layout" ref={windowReference}>
          {currentPageIndex > 0 ? (
            <button
              className="cmp-suggested-products-carousel__scroller cmp-suggested-products-carousel__scroller--left"
              aria-label={buttonPreviousAccessibilityLabel}
              onClick={() => goToPage(currentPageIndex - 1)}
            >
              <i className="ri-arrow-left-line" />
            </button>
          ) : null}
          <ul
            className={cls({
              'cmp-suggested-products-carousel__list': true,
              'cmp-suggested-products-carousel__list--is-dragged': marginOffset !== 0,
            })}
            style={{ marginLeft: listMargin + marginOffset }}
            onTouchStart={onTouchStart}
            onTouchMove={onTouchMove}
            onTouchEnd={onTouchEnd}
          >
            {products.map((product, index) => (
              <Fragment key={product.id}>
                <ProductListItem
                  title={product.name}
                  link={baseProductUrl.replace(':productId', product.id)}
                  image={product.imageUrl || ''}
                  colors={product.colorVariants || []}
                  key={product.id}
                  missingImageAltText={product.name}
                  onFocus={() => handleFocus(index)}
                />
              </Fragment>
            ))}
          </ul>
          {currentPageIndex < totalPageCount - 1 ? (
            <button
              className="cmp-suggested-products-carousel__scroller cmp-suggested-products-carousel__scroller--right"
              aria-label={buttonNextAccessibilityLabel}
              onClick={() => goToPage(currentPageIndex + 1)}
            >
              <i className="ri-arrow-right-line" />
            </button>
          ) : null}
        </div>
        <div className="cmp-suggested-products-carousel__dots">
          {pageDots.map((index) => (
            <button
              key={index}
              onClick={() => goToPage(index)}
              className={cls({
                'cmp-suggested-products-carousel__dot': true,
                'cmp-suggested-products-carousel__dot--is-active': index === currentPageIndex,
              })}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

registerComponent('ProductsCarousel', ProductsCarousel)

export default ProductsCarousel
