import React from 'react'
import { cls } from '@utils/classnames'

const ScrollingArrow: React.FC<{
  callBack?: () => void
  // TODO: remove maybe, not sure we need that for now (Ana?)
  classNames?: string
  isUp?: boolean
  ariaLabel?: string
}> = ({ callBack, classNames, isUp = true, ariaLabel }): JSX.Element => {
  return (
    <button className={`cmp-scrolling-arrow ${classNames ? classNames : ''}`} onClick={callBack} aria-label={ariaLabel}>
      <i
        className={cls({
          'ri-arrow-up-line': isUp,
          'ri-arrow-down-line': !isUp,
        })}
      />
    </button>
  )
}

export default ScrollingArrow
