// https://gist.github.com/ca0v/73a31f57b397606c9813472f7493a940
import { useCallback, useEffect, useRef } from 'react'

export const useDebounce = <F extends (...arguments_: any) => any>(
  function_: F,
  waitFor = 250,
): ((...arguments_: Parameters<F>) => ReturnType<F>) => {
  const timer = useRef<NodeJS.Timer | null>()
  const savedFunction = useRef<F | null>(function_)

  useEffect(() => {
    savedFunction.current = function_
    return () => {
      timer.current = null
      savedFunction.current = null
    }
  }, [waitFor, function_])

  return useCallback((...arguments_: any) => {
    if (timer.current) {
      clearTimeout(timer.current)
      timer.current = null
    }

    timer.current = setTimeout(() => savedFunction?.current?.(...arguments_), waitFor)
  }, []) as (...arguments_: Parameters<F>) => ReturnType<F>
}
