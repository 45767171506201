import React from 'react'

const StepperLine: React.FC<{
  past: boolean
  current: boolean
  hideLine: boolean
}> = ({ past, current, hideLine }) => {
  return hideLine ? null : (
    <div
      className={`coffee-machine-finder__step-line ${past ? 'coffee-machine-finder__step-line--past' : ''} ${
        current ? 'coffee-machine-finder__step-line--current' : ''
      }`}
    >
      <div />
      <div />
    </div>
  )
}

export default StepperLine
