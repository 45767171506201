import React from 'react'
import { cls } from '@utils/classnames'

interface FileUploaderDragAndDropProperties {
  title?: string
  inputName: string
  buttonText: string
  buttonAriaLabel: string
  uploadFailedText?: string
  handleFileInputChange: (argument: FileList, inputReference: React.MutableRefObject<HTMLInputElement | null>) => void
  separatorText?: string
}

const FileUploaderDragAndDrop: React.FC<FileUploaderDragAndDropProperties> = ({
  title,
  inputName,
  buttonText,
  buttonAriaLabel,
  handleFileInputChange,
  separatorText,
}: FileUploaderDragAndDropProperties): JSX.Element => {
  const inputReference = React.useRef<HTMLInputElement>(null)
  const [dragActive, setDragActive] = React.useState<boolean>(false)

  const onButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    inputReference?.current?.click()
  }

  function handleInput(event: React.ChangeEvent<HTMLInputElement>) {
    event.preventDefault()

    if (event.target.files && event.target.files[0]) {
      handleFileInputChange(event.target.files, inputReference)
    }
  }

  function handleDrag(event: React.DragEvent<HTMLElement>) {
    event.preventDefault()
    event.stopPropagation()
    if (event.type === 'dragenter' || event.type === 'dragover') {
      setDragActive(true)
    }
    if (event.type === 'dragleave') {
      setDragActive(false)
    }
  }

  function handleDrop(event: React.DragEvent<HTMLElement>) {
    event.preventDefault()
    event.stopPropagation()

    setDragActive(false)
    if (event.dataTransfer.files) {
      handleFileInputChange(event.dataTransfer.files, inputReference)
    }
  }

  return (
    <div className="cmp-file-uploader-drag-and-drop" onDragEnter={handleDrag}>
      <input
        ref={inputReference}
        type="file"
        id="file-upload__input"
        className="cmp-file-uploader-drag-and-drop__input"
        name={inputName}
        multiple
        onInput={handleInput}
      />
      <label
        htmlFor="file-upload__input"
        className={cls({
          'cmp-file-uploader-drag-and-drop__label': true,
          'cmp-file-uploader-drag-and-drop__label--is-drag-active': dragActive,
        })}
      >
        <div>
          <div className="cmp-file-uploader-drag-and-drop__icon">
            <i className="ri-upload-line"></i>
          </div>
          <p className="cmp-file-uploader-drag-and-drop__title">{title}</p>
          <div>{separatorText}</div>
          <button
            className="cmp-file-uploader-drag-and-drop__button"
            onClick={onButtonClick}
            aria-label={buttonAriaLabel}
          >
            {buttonText}
          </button>
        </div>
      </label>
      {dragActive && (
        <div
          id="drag-file-element"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        ></div>
      )}
    </div>
  )
}

export default FileUploaderDragAndDrop
