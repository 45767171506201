import React from 'react'

interface SectionProperties {
  children: JSX.Element
}

const Section = React.forwardRef<HTMLDivElement, SectionProperties>(
  ({ children, ...otherProperties }, reference): JSX.Element => {
    return (
      <div ref={reference} className="cmp-section" {...otherProperties}>
        {children}
      </div>
    )
  },
)

export default Section
