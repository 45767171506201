import { FallbackProps } from 'react-error-boundary'

const Error = (properties: FallbackProps) => {
  console.error(properties.error)

  return (
    <div className="cmp-error" role="img" aria-label="error warning" title="error warning">
      <i className="ri-error-warning-fill cmp-error__error-warning"></i>
    </div>
  )
}

export default Error
