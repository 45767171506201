export const PDF_COOKIE_NAME = 'PDF_COOKIE'

// https://www.w3schools.com/js/js_cookies.asp
export function setCookie(name: string, value: string, expiryDays?: number): void {
  let expires = ''

  // By default cookie does not expire or lasts for a long long long time...
  expires = `expires=${new Date(2_147_483_647 * 1000).toUTCString()}`

  if (expiryDays) {
    const dateTimeStamp = new Date()
    dateTimeStamp.setTime(dateTimeStamp.getTime() + expiryDays * 24 * 60 * 60 * 1000)
    expires = `expires=${dateTimeStamp.toUTCString()};`
  }

  // eslint-disable-next-line unicorn/no-document-cookie
  document.cookie = `${name}=${value};${expires}path=/`
}

// https://gomakethings.com/how-to-set-and-get-cookies-with-vanilla-js/
export function getCookie(name: string): string {
  // eslint-disable-next-line unicorn/no-document-cookie
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) {
    return parts.pop()?.split(';')?.shift() || ''
  }
  return ''
}
