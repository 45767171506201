import React from 'react'
import ResponsiveImage from '@components/ResponsiveImage'
import { cls } from '@utils/classnames'

import { HeroItem } from './SliderHeroTypes'

interface HeroPreview {
  isSelected: boolean
  item: HeroItem
  onClick: () => void
  onMouseEnter: () => void
  onMouseLeave: () => void
  onAnimationEnd: () => void
}

const SliderHeroPreview: React.FC<HeroPreview> = ({
  item,
  isSelected,
  onClick,
  onAnimationEnd,
  onMouseEnter,
  onMouseLeave,
}: HeroPreview) => {
  const raiseClick = () => {
    if (!isSelected) {
      onClick()
    }
  }

  const raiseMouseEnter = () => {
    if (isSelected) {
      onMouseEnter()
    }
  }

  const raiseMouseLeave = () => {
    if (isSelected) {
      onMouseLeave()
    }
  }

  if (!item.heroImage?.originalImageUrl || !item.heroThumbnailImage?.originalImageUrl) {
    return null
  }

  return (
    <div
      className={cls({
        'cmp-slider-hero-preview': true,
        'cmp-slider-hero-preview--is-active': isSelected,
      })}
      onMouseEnter={raiseMouseEnter}
      onMouseLeave={raiseMouseLeave}
      onClick={raiseClick}
      role="button"
      tabIndex={0}
      onKeyDown={raiseClick}
    >
      <link rel="prefetch" href={item.heroImage.originalImageUrl}></link>
      <ResponsiveImage
        src={item.heroThumbnailImage.originalImageUrl}
        alt={item.heroThumbnailImage.altText}
        className="cmp-slider-hero-preview__thumbnail"
      />
      <p className="cmp-slider-hero-preview__pretitle">{item.heroPretitle}</p>
      <p className="cmp-slider-hero-preview__title">{item.heroTitle}</p>
      <div className="cmp-slider-hero-preview__timer">
        <div className="cmp-slider-hero-preview__progress" onAnimationEnd={onAnimationEnd}></div>
      </div>
    </div>
  )
}

export default SliderHeroPreview
