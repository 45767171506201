import React, { FC, useEffect, useState } from 'react'
import { PDF_CMF_KEY } from '@constants/localStorage'
import { getLocalStorageData } from '@utils/getLocalStorageData'

import { CoffeeMachineFinderTexts } from '../CoffeeMachineFinderTypes'

import Options from './Options'

interface UserOptionSelectionProperties {
  texts: CoffeeMachineFinderTexts
}

const UserOptionSelection: FC<UserOptionSelectionProperties> = ({ texts }): JSX.Element => {
  const [userOptions, setUserOptions] = useState({})

  useEffect(() => {
    const data = getLocalStorageData(PDF_CMF_KEY, {})

    setUserOptions((data as any)?.selectedOptions || {})
  }, [])

  return (
    <div className="cmp-selection">
      <hr className="cmp-selection__divider" />
      <div className="cmp-selection__content">
        <div className="cmp-teaser">
          <div className="cmp-teaser__content">
            <div className="cmp-teaser__description">
              <span className="cmp-teaser__paragraph--lead">{texts.selectionsDescription}</span>
              <Options userOptions={userOptions} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserOptionSelection
