import React from 'react'
import { cls } from '@utils/classnames'

interface NavLanguageSwitcherProperties {
  title: string
  link: {
    externalizedURL: string
  }
  active: boolean
  displayName: string
  showLanguageSwitcherOnMobile: boolean
}
const NavLanguageSwitcher = ({
  title,
  link,
  active,
  displayName,
  showLanguageSwitcherOnMobile,
}: NavLanguageSwitcherProperties) => {
  return (
    <li
      className={cls({
        'cmp-header__menu-item': true,
        'cmp-header__menu-item--hide-on-mobile': !showLanguageSwitcherOnMobile,
      })}
    >
      <a
        href={link.externalizedURL}
        className={cls({
          'cmp-nav-language-switcher': true,
          'cmp-nav-language-switcher--is-active': active,
        })}
        title={title}
      >
        {displayName}
      </a>
    </li>
  )
}

export default NavLanguageSwitcher
