;(function () {
  'use strict'

  function onDocumentReady() {
    const socialIcons = document.querySelectorAll('.socialicons__icon')

    for (const socialIcon of socialIcons) {
      socialIcon.addEventListener('click', function () {
        window.adobeDataLayer?.push({
          event: 'clicked social icon',
          eventInfo: {
            reference: 'social',
            requestUrl: this.getAttribute('href'),
          },
        })
      })
    }
  }

  if (document.readyState !== 'loading') {
    onDocumentReady()
  } else {
    document.addEventListener('DOMContentLoaded', onDocumentReady)
  }
})()
