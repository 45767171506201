import React, { FC } from 'react'

interface OptionsProperties {
  userOptions: Record<string, [Record<string, string>]>
}

const Options: FC<OptionsProperties> = ({ userOptions }) => {
  const options = Object.keys(userOptions)
  return options ? (
    <ul>
      {options.map((option) => {
        return (
          <li key={option}>
            <span>{option}:</span>&nbsp;
            <span>
              {userOptions[option].length > 0 ? userOptions[option].map((element) => element.title).join(', ') : '-'}
            </span>
          </li>
        )
      })}
    </ul>
  ) : null
}

export default Options
