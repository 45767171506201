import React, { useState } from 'react'
import { registerComponent } from 'react-register-dom'

import DownloadFilterListItem from './DownloadFilterListItem'
import { DownloadFilterLanguageItems } from './DownloadFilterTypes'
interface DownloadFilterProperties {
  downloadFilterLabel: string
  downloadFilterLanguages: DownloadFilterLanguageItems[]
  buttonAriaLabel?: string
  buttonLabel?: string
}
const DEFAULT_DISPLAYED_ELEMENT_COUNT = 5

const DownloadFilter: React.FC<{ jsonData: string }> = ({ jsonData }) => {
  const downloadFilter = JSON.parse(jsonData) as DownloadFilterProperties

  const [selectedLanguage, setSelectedLanguage] = useState(downloadFilter.downloadFilterLanguages[0].name)

  const [isListExpanded, setIsListExpanded] = useState(false)

  function handleChange(event: React.ChangeEvent<HTMLSelectElement>) {
    setSelectedLanguage(event.target.value)
    setIsListExpanded(false)
  }

  function expandList() {
    setIsListExpanded(true)
  }
  const localizedList =
    downloadFilter.downloadFilterLanguages.find((filter) => filter.name == selectedLanguage)?.items || []
  const displayedElements = React.useMemo(() => {
    if (isListExpanded) {
      return localizedList
    }
    return localizedList.slice(0, DEFAULT_DISPLAYED_ELEMENT_COUNT)
  }, [selectedLanguage, isListExpanded, downloadFilter.downloadFilterLanguages, localizedList])

  const isExpandButtonVisible = !isListExpanded && localizedList.length > DEFAULT_DISPLAYED_ELEMENT_COUNT
  return (
    <div className="cmp-download-filter">
      <div className="cmp-download-filter__select-wrapper">
        <label htmlFor="cmp-download-filter__select" className="cmp-download-filter__label">
          {downloadFilter.downloadFilterLabel}
        </label>
        <select
          className="cmp-download-filter__select"
          id="cmp-download-filter__select"
          value={selectedLanguage}
          onChange={handleChange}
        >
          {downloadFilter.downloadFilterLanguages?.map((filterItem) => {
            return (
              <option value={filterItem?.name} key={filterItem?.name}>
                {filterItem?.name}
              </option>
            )
          })}
        </select>
      </div>

      <div className="cmp-download-filter__list-container">
        <ul className="cmp-download-filter__list">
          {displayedElements.map((item) => (
            <DownloadFilterListItem
              label={item.label}
              link={item.link}
              key={item.link}
              size={item?.size}
              type={item?.type}
            />
          ))}
        </ul>
        {isExpandButtonVisible ? (
          <div className="cmp-download-filter__button-wrapper">
            <button
              className="cmp-download-filter__button"
              aria-label={downloadFilter.buttonAriaLabel}
              onClick={expandList}
            >
              {downloadFilter.buttonLabel}
            </button>
          </div>
        ) : null}
      </div>
    </div>
  )
}

registerComponent('DownloadFilter', DownloadFilter)
export default DownloadFilter
