import React from 'react'
import {
  NavigationProductCategoriesListItem,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from '@components/Header/Navigation/Navigation.d.ts'

import NavigationProductGradient from '../NavigationProductGradient'
import NavigationProductItem from '../NavigationProductItem'

interface NavigationProductListroperties {
  items: Record<string, NavigationProductCategoriesListItem> | null
  itemsOrder: string[]
}

const NavigationProductList: React.FC<NavigationProductListroperties> = ({ items = null, itemsOrder = [] }) => {
  return (
    <>
      <NavigationProductGradient />
      <div className="cmp-navigation-product-list">
        <ul className="cmp-navigation-product-list__items">
          {itemsOrder
            ?.map((itemsOrderKey) => {
              const product = items?.[itemsOrderKey]
              if (!product) {
                return
              }
              return (
                <NavigationProductItem
                  key={itemsOrderKey}
                  title={product.title}
                  link={product.link.externalizedURL}
                  image={product.image}
                />
              )
            })
            .filter((element) => element)}
        </ul>
      </div>
      <NavigationProductGradient isReverse />
    </>
  )
}

export default NavigationProductList
