import React from 'react'
import ResponsiveImage from '@components/ResponsiveImage'

export interface NavigationProductTeaserProperties {
  navigationTeaserLink: string
  navigationTeaserTitle: string
  navigationTeaserLinkLabel: string
  navigationTeaserImage: {
    originalImageUrl: string
    imageUrl: string
    altText: string | null
  }
}

const NavigationProductsTeaser: React.FC<NavigationProductTeaserProperties> = ({
  navigationTeaserLink,
  navigationTeaserTitle,
  navigationTeaserLinkLabel,
  navigationTeaserImage: { originalImageUrl, imageUrl, altText },
}: NavigationProductTeaserProperties) => {
  return imageUrl || originalImageUrl ? (
    <div className="cmp-navigation-product-teaser">
      <ResponsiveImage
        src={imageUrl || originalImageUrl}
        alt={altText || ''}
        resizeImage={Boolean(imageUrl)}
        className="cmp-navigation-product-teaser__image"
      />
      <div className="cmp-navigation-product-teaser__content">
        <div className="cmp-navigation-product-teaser__title">{navigationTeaserTitle}</div>
        <a className="cmp-navigation-product-teaser__button" href={navigationTeaserLink}>
          {navigationTeaserLinkLabel}
        </a>
      </div>
    </div>
  ) : null
}

export default NavigationProductsTeaser
