import * as React from 'react'
import { createPortal } from 'react-dom'

const Toast: React.FC<{
  children?: JSX.Element | string
  isVisible: boolean
  onClose: () => void
  duration?: number
  type: 'success'
}> = ({ children, isVisible, onClose, duration = 2500, type }) => {
  const toastContainerReference = React.useRef<HTMLDivElement>()
  const lastVisibleValue = React.useRef<boolean>(false)
  const timeout = React.useRef<number>()

  /**
   * adds the toast container to the page
   */
  React.useLayoutEffect(() => {
    const container = document.createElement('div')
    container.classList.add('cmp-toast', `cmp-toast--${type}`)

    document.body.append(container)

    toastContainerReference.current = container

    return () => {
      container.remove()
    }
  }, [])

  /**
   * listens to change in isVisible to refresh or set the timeout
   * also takes care of clearing the timeout if the component gets destroyed
   */
  React.useEffect(() => {
    if (!lastVisibleValue.current && isVisible) {
      // visibility just turned on
      if (timeout.current) {
        window.clearTimeout(timeout.current)
      }

      const newTimeout = window.setTimeout(onClose, duration)
      timeout.current = newTimeout
    }

    lastVisibleValue.current = isVisible

    return () => {
      if (timeout.current) {
        window.clearTimeout(timeout.current)
      }
    }
  }, [isVisible])

  /**
   * syncs visibility with isVisible prop
   */
  if (toastContainerReference.current) {
    toastContainerReference.current.classList.remove('cmp-toast--is-visible')
    if (isVisible) {
      toastContainerReference.current.classList.add('cmp-toast--is-visible')
    }
  }

  if (!isVisible || !toastContainerReference.current) {
    return null
  }

  return createPortal(
    <>
      <i className="ri-checkbox-circle-line cmp-toast__icon" />
      {children}
    </>,
    toastContainerReference.current,
  )
}

export default Toast
