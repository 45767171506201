import React, { useContext, useEffect, useState } from 'react'
import { HeaderContext } from '@components/Header/HeaderContext'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { NavigationSubLink } from '@components/Header/Navigation/Navigation.d.ts'
import NavigationAccordionIcon from '@components/Header/Navigation/NavigationAccordion/NavigationAccordionIcon'
import { cls } from '@utils/classnames'

const NavigationSubItem = ({
  item,
  open,
  sectionId,
}: {
  item: NavigationSubLink
  open: boolean
  sectionId: null | string
}) => {
  const {
    setTopNavigationData,
    isSubNavigationOpenOnMobile,
    setIsSubNavigationOpenOnMobile,
    isHeaderOpened,
    currentSectionId,
  } = useContext(HeaderContext)
  const [isNavigationSubItemOpen, setNavigationSubItemOpen] = useState(false)

  function handleClick() {
    setNavigationSubItemOpen(!isNavigationSubItemOpen)
    setIsSubNavigationOpenOnMobile(!isSubNavigationOpenOnMobile)
  }

  useEffect(() => {
    const isCurrentSubitemOpened = Boolean(isHeaderOpened && open && isNavigationSubItemOpen)

    if (isCurrentSubitemOpened) {
      setTopNavigationData(item.navigationSubItem)
    }

    if (!open || !isHeaderOpened || !isSubNavigationOpenOnMobile) {
      setNavigationSubItemOpen(false)
      setTopNavigationData(null)
    }
  }, [isNavigationSubItemOpen, isHeaderOpened, open, isSubNavigationOpenOnMobile])

  useEffect(() => {
    const navigationTeaserElement = document.querySelector('.cmp-navigation-teaser')

    // Only do that for the current accordion section id containing navigation subitems.
    // The other accordion section ids are handled with the usual logic from header state.
    navigationTeaserElement?.classList[
      currentSectionId === sectionId && (isNavigationSubItemOpen || !open) ? 'remove' : 'add'
    ]('cmp-navigation-teaser--is-opened')
  }, [isNavigationSubItemOpen, open, currentSectionId, sectionId])

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      role="button"
      tabIndex={0}
      className={cls({
        'cmp-navigation-subitem': true,
        'cmp-navigation-subitem__item': true,
        'cmp-navigation-subitem__item--is-active': item.active,
      })}
      onClick={handleClick}
    >
      {item.linkTitle}
      <NavigationAccordionIcon open={isNavigationSubItemOpen} useArrow={false} />
    </div>
  )
}

export default NavigationSubItem
