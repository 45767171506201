import React from 'react'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { NavigationSubLink } from '@components/Header/Navigation/Navigation.d.ts'
import Accordion from '@components/UI/Accordion'
import { cls } from '@utils/classnames'

import NavigationSubItem from '../NavigationSubItem'

const NavigationAccordionContent = ({
  open,
  items,
  sectionId,
}: {
  open: boolean
  items: NavigationSubLink[]
  sectionId: null | string
}) => {
  return (
    <Accordion isOpen={open}>
      <ul className="cmp-accordion-list">
        {items.map((item) => {
          return (
            <li key={`${item.id}-${item.linkTitle}-${item.link.externalizedURL}`}>
              {item.navigationSubItem ? (
                <NavigationSubItem item={item} open={open} sectionId={sectionId} />
              ) : (
                <a
                  className={cls({
                    'cmp-accordion-list__item': true,
                    'cmp-accordion-list__item--is-active': item.active,
                  })}
                  href={item.link.externalizedURL}
                >
                  {item.linkTitle}
                </a>
              )}
            </li>
          )
        })}
      </ul>
    </Accordion>
  )
}

export default NavigationAccordionContent
