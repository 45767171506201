import React from 'react'
import { TabsConfig } from '@components/GlobalSearch/GlobalSearchTypes'
import { cls } from '@utils/classnames'

interface TabsProperties {
  tabs: TabsConfig[]
  activeTab: string
  onSwitchTab: (newValue: string) => void
}

const Tabs: React.FC<TabsProperties> = ({ tabs, activeTab, onSwitchTab }: TabsProperties): JSX.Element => {
  function handleTabKeydown(event: React.KeyboardEvent<HTMLElement>, id: string) {
    if (event.key === 'Enter') {
      onSwitchTab(id)
    }
  }

  return (
    <div className="cmp-tabs">
      <ol className="cmp-tabs__tablist">
        {tabs.map((tab) => (
          <li
            className={cls({
              'cmp-tabs__tab': true,
              'cmp-tabs__tab--active': activeTab === tab.id,
            })}
            onClick={() => onSwitchTab(tab.id)}
            onKeyPress={(event) => handleTabKeydown(event, tab.id)}
            id={tab.id}
            key={tab.id}
            tabIndex={0}
            role="tab"
          >
            {tab.label}
          </li>
        ))}
      </ol>
    </div>
  )
}

export default Tabs
