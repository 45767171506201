import React, { FC, useEffect, useMemo, useState } from 'react'
import UserOptionSelection from '@components/CoffeeMachineFinder/UserOptionSelection'
import Modal from '@components/Modal'
import useModal from '@hooks/useModal'
import { getCookie, PDF_COOKIE_NAME, setCookie } from '@utils/cookies'

import CoffeeMachine from '../CoffeeMachine/CoffeeMachine'
import { CoffeeMachineFinderModal, CoffeeMachineFinderTexts, CoffeeMachineProduct } from '../CoffeeMachineFinderTypes'
import CoffeeMachineModalContent from '../CoffeeMachineModalContent'
import ResultTabs from '../ResultTabs'
import ResultTeaserContent from '../ResultTeaserContent'

function getTabConfig(texts: CoffeeMachineFinderTexts, results: CoffeeMachineProduct[]) {
  return [texts.bestResult, texts.alternative1, texts.alternative2].slice(0, results.length)
}

export const CoffeeMachineFinderResultsSuccess: FC<{
  results: CoffeeMachineProduct[]
  texts: CoffeeMachineFinderTexts
  modal?: CoffeeMachineFinderModal
  selectedTab: number
  onTabSelect: (tabIndex: number) => void
  onRestart: () => void
}> = ({ results, texts, modal, selectedTab, onRestart, onTabSelect }) => {
  const tabsConfig = useMemo(() => getTabConfig(texts, results), [results])
  const [modalTimeoutId, setModalTimeoutId] = useState<number | null>(null)

  const { isShowing, toggleModal } = useModal()

  useEffect(() => {
    window.adobeDataLayer?.push({
      event: 'coffee machine finder',
      eventInfo: {
        reference: 'coffee machine finder',
        step: 'result',
        title: texts.resultsTitle,
        answer: results.map((item, index) => {
          return {
            sku: item.name,
            recommendationType: tabsConfig[index],
          }
        }),
      },
    })

    const breadcrumb = document.querySelector('.cmp-breadcrumb')
    breadcrumb?.classList.add('cmp-breadcrumb--champagne')

    return () => {
      breadcrumb?.classList.remove('cmp-breadcrumb--champagne')
    }
  })

  useEffect(() => {
    if (modal?.showModal && getCookie(PDF_COOKIE_NAME) !== 'true' && modalTimeoutId === null) {
      const timeoutId = window.setTimeout(() => {
        setCookie(PDF_COOKIE_NAME, 'true')
        toggleModal()
        setModalTimeoutId(null)
      }, modal.delay)
      setModalTimeoutId(timeoutId)
    }
  }, [modal, setModalTimeoutId])

  // avoid back navigation to not show the page from the top
  useEffect(() => {
    window.history.scrollRestoration = 'manual'
  }, [])

  useEffect(() => {
    return () => {
      if (modalTimeoutId) {
        window.clearTimeout(modalTimeoutId)
      }
    }
  }, [])

  return (
    <>
      <div className="coffee-machine-finder__results-background" />
      <div className="coffee-machine-finder__results-intro">
        <ResultTeaserContent {...texts} />
        <ResultTabs tabs={tabsConfig} selectedTab={selectedTab} onSelect={onTabSelect} />
      </div>
      {/* if animation is needed, render all products and hide the non-selected ones */}
      <CoffeeMachine machine={results[selectedTab]} onRestart={onRestart} texts={texts} />

      <UserOptionSelection texts={texts} />

      {modal && (
        <Modal
          closeButtonLabel={modal.closeButtonLabel}
          closeButtonAriaLabel={modal.closeButtonAriaLabel}
          ariaLabel={modal.ariaLabel}
          isShowing={isShowing}
          closeModal={toggleModal}
        >
          <CoffeeMachineModalContent
            ctaButtonLink={modal.ctaButtonLink.externalizedURL}
            ctaButtonLabel={modal.ctaButtonLabel}
            description={modal.description}
            headline={modal.headline}
            image={modal.image.imageUrl || modal.image.originalImageUrl}
            imageAlt={modal.image.altText}
            resizeImage={Boolean(modal.image.imageUrl)}
          />
        </Modal>
      )}
    </>
  )
}
