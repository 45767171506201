import React from 'react'

interface SvgProperties {
  viewBox?: string
  height?: string
  width?: string
  fill?: string
  ariaHidden?: boolean
  children: React.ReactNode
}

const Svg = ({
  viewBox = '0 0 24 24',
  height = '24',
  width = '24',
  fill = 'none',
  ariaHidden = true,
  children,
}: SvgProperties) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox={viewBox}
      height={height}
      width={width}
      fill={fill}
      aria-hidden={ariaHidden}
    >
      {children}
    </svg>
  )
}

export default Svg
