;(function () {
  'use strict'
  function onDocumentReady() {
    const data = JSON.parse(window.localStorage.getItem('PDF_CMF') || '{}')
    const leadGenerationFormContainer = document?.querySelector(
      '[data-component-name="lead-generation-form-container"]',
    )
    const MoreAboutRequestHiddenFieldElement = leadGenerationFormContainer?.querySelectorAll(
      'input[name="MoreAboutRequest"]',
    )

    if (data?.resultPageUrl && MoreAboutRequestHiddenFieldElement?.[0]) {
      MoreAboutRequestHiddenFieldElement[0].value = data.resultPageUrl
    }

    const emailField = leadGenerationFormContainer?.querySelector('input[name="email"]')

    if (emailField) {
      emailField.addEventListener('change', (event_) => {
        const value = event_.target.value
        data.email = value

        window.localStorage.setItem('PDF_CMF', JSON.stringify(data))
      })
    }
  }

  if (document.readyState !== 'loading') {
    onDocumentReady()
  } else {
    document.addEventListener('DOMContentLoaded', onDocumentReady)
  }
})()
