/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react'
import ResponsiveImage from '@components/ResponsiveImage'

interface NavigationProductProperties {
  title: string
  link: string
  image: {
    originalImageUrl: string | null
    imageUrl: string | null
    altText: string | null
  }
}
const NavigationProductItem: React.FC<NavigationProductProperties> = ({
  title,
  link,
  image,
}: NavigationProductProperties) => {
  return (
    <li className="cmp-navigation-product-item">
      <a href={link} className="cmp-navigation-product-item__link">
        <div className="cmp-navigation-product-item__left-side">
          <div className="cmp-navigation-product-item__icon">
            <ResponsiveImage
              src={image?.imageUrl ? image?.imageUrl : image?.originalImageUrl || ''}
              alt={image?.altText || ''}
            />
          </div>
          <div className="cmp-navigation-product-item__title">{title}</div>
        </div>
        <div className="cmp-navigation-product-item__cta-icon">
          <i className="ri-arrow-right-line"></i>
        </div>
      </a>
    </li>
  )
}

export default NavigationProductItem
