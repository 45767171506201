import { useEffect, useState } from 'react'

const breakpoint1 = 480
const breakpoint2 = 769
const breakpoint3 = 1025
const breakpoint4 = 1920

export function isMq1() {
  return matchMedia(`only screen and (max-width: ${breakpoint1 - 1}px)`).matches
}
export function isMq2() {
  return matchMedia(`only screen and (min-width: ${breakpoint1}px) and (max-width: ${breakpoint2 - 1}px)`).matches
}
export function isMq3() {
  return matchMedia(`only screen and (min-width: ${breakpoint2}px) and (max-width: ${breakpoint3 - 1}px)`).matches
}
export function isMq4() {
  return matchMedia(`only screen and (min-width: ${breakpoint3}px) and (max-width: ${breakpoint4 - 1}px)`).matches
}
export function isMq5() {
  return matchMedia(`only screen and (min-width: ${breakpoint4}px)`).matches
}

export function isMobile() {
  return isMq1() || isMq2()
}

export function getMediaQuery() {
  if (isMq1()) return 'mq1'
  if (isMq2()) return 'mq2'
  if (isMq3()) return 'mq3'
  if (isMq4()) return 'mq4'

  return 'mq5'
}

export function useWindowResize<T>(callback: () => T) {
  const [result, setResult] = useState(callback())

  useEffect(() => {
    function listener() {
      setResult(callback())
    }

    window.addEventListener('resize', listener)

    return () => {
      window.removeEventListener('resize', listener)
    }
  })

  useEffect(() => {
    setResult(callback())
  }, [])

  return result
}
