export function getQueryParameters<T extends Record<string, string | string[]>>() {
  const parameters = new URLSearchParams(location.search).entries() as unknown as [string, string][]

  const result = {}

  for (const [key, value] of parameters) {
    if (!result[key]) {
      result[key] = value
    } else if (typeof result[key] === 'string') {
      result[key] = [result[key], value]
    } else {
      result[key].push(value)
    }
  }

  return result as Partial<T>
}
