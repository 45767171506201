import React, { useRef, useState } from 'react'
import { registerComponent } from 'react-register-dom'
import DamVideo from '@components/DamVideo'
import { cls } from '@utils/classnames'
import { getBooleanFromValue } from '@utils/getBooleanFromValue'
import { isMobile, useWindowResize } from '@utils/mediaQueries'

import VideoCover from './VideoCover'

type StringOrBoolean = string | boolean

export type HeaderTag = 'h2' | 'h3' | 'h4'
interface VideoProperties {
  fromDam: StringOrBoolean
  id: string
  headline: string
  headlineHeadingLevel: HeaderTag
  title: string
  titleHeadingLevel: HeaderTag
  description: string
  videoUrl: string
  videoFromDamUrl: string
  thumbnailUri: string
  thumbnailAlt: string
  autoplay: string
  isEditMode: StringOrBoolean
  loopVideo: string
  hideVideoControls: string
  verticalVideoUrl?: string
}

const Video: React.FC<VideoProperties> = ({
  fromDam: isFromDam = 'false',
  id,
  headline,
  headlineHeadingLevel: HeadlineTag = 'h2',
  title,
  titleHeadingLevel: TitleTag = 'h3',
  description,
  videoUrl,
  videoFromDamUrl,
  thumbnailUri,
  thumbnailAlt,
  isEditMode = 'false',
  autoplay = 'false',
  loopVideo = 'false',
  hideVideoControls = 'false',
  verticalVideoUrl,
}: // eslint-disable-next-line sonarjs/cognitive-complexity
VideoProperties): JSX.Element => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(autoplay == 'true')
  const videoReference = useRef<HTMLVideoElement>(null)
  const videoParameters = videoUrl ? new URLSearchParams(videoUrl.split('?')[1]) : null
  const isEditModeBoolean = getBooleanFromValue(isEditMode)
  const isFromDamBoolean = getBooleanFromValue(isFromDam)
  const canLoadVideoFromDam = Boolean(isFromDamBoolean && videoFromDamUrl)
  const canLoadVideoFromSocialMedia = Boolean(!isFromDamBoolean && videoUrl)
  const isMobileScreen = useWindowResize(isMobile)

  if (!isEditModeBoolean) {
    videoParameters?.append('autoplay', '1')
  }
  videoParameters?.append('mute', '1')

  function handleVideoCoverOnClick() {
    const videoSource = isFromDamBoolean ? 'DAM' : videoUrl.split('.')[1]

    window.adobeDataLayer?.push({
      event: 'clicked video play',
      eventInfo: {
        reference: 'video player',
        playerType: videoSource,
        videoName: title,
      },
    })
    setIsVideoPlaying(true)
    videoReference?.current?.play()
  }

  return (
    <div
      className={cls({
        'cmp-video': true,
        'cmp-video--has-mobile-video': verticalVideoUrl,
      })}
    >
      {headline && <HeadlineTag className="cmp-video__headline">{headline}</HeadlineTag>}
      <div className="cmp-video__container">
        <div className="cmp-video__player">
          {(!isVideoPlaying && (
            <VideoCover
              id={`video-cover-${id}`}
              src={thumbnailUri}
              altText={thumbnailAlt}
              ariaLabel={title}
              onClick={handleVideoCoverOnClick}
            ></VideoCover>
          )) ||
            null}

          {canLoadVideoFromDam && (
            <DamVideo
              ref={videoReference}
              videoUrl={isMobileScreen && verticalVideoUrl ? verticalVideoUrl : videoFromDamUrl}
              isEditModeBoolean={isEditModeBoolean}
              loopVideo={loopVideo}
              isVideoPlaying={isVideoPlaying}
              hideVideoControls={hideVideoControls}
            />
          )}

          {canLoadVideoFromSocialMedia && (
            <iframe
              id={`video-iframe-${id}`}
              title="video"
              src={`${videoUrl}?${isVideoPlaying && videoParameters ? videoParameters.toString() : ''}`}
              allow="autoplay; encrypted-media"
              className="cmp-video__iframe"
              allowFullScreen
            ></iframe>
          )}
        </div>
        {title || description ? (
          <div className="cmp-video__content">
            {title && <TitleTag className="cmp-video__title">{title}</TitleTag>}
            {description && <p className="cmp-video__description">{description} </p>}
          </div>
        ) : null}
      </div>
    </div>
  )
}

registerComponent('Video', Video)

export default Video
