import React from 'react'
import ResponsiveImage from '@components/ResponsiveImage'

const StepperIcon: React.FC<{
  past: boolean
  current: boolean
  src: string
  title: string
  onStepClick: () => void
  /** not sure what to do here but intellisense complains otherwise,
   * probably a space press should do what a click does
   */
  onStepKeyDown?: React.KeyboardEventHandler<HTMLDivElement>
}> = ({ past, current, src, title, onStepClick, onStepKeyDown }) => {
  return (
    <div
      className={`coffee-machine-finder__step-icon ${past ? 'coffee-machine-finder__step-icon--past' : ''} ${
        current ? 'coffee-machine-finder__step-icon--current' : ''
      }`}
      onClick={onStepClick}
      role="button"
      onKeyDown={onStepKeyDown}
      tabIndex={0}
    >
      <div className="coffee-machine-finder__step-icon-container">
        <img
          src={`${src}?fit=crop,1&amp;wid=32&amp;hei=32`}
          alt={title}
          className="coffee-machine-finder__step-icon--image"
        />
      </div>
      <span className="coffee-machine-finder__step-icon--title">{title}</span>
    </div>
  )
}

export default StepperIcon
