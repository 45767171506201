import * as React from 'react'
import { useRef } from 'react'
import { useFocusRing, useListBox, useOption } from 'react-aria'
import { createPortal } from 'react-dom'
import { Item, useListState } from 'react-stately'
import { useButton } from '@react-aria/button'
import { useInteractionModality } from '@react-aria/interactions'
import { useTooltipTrigger } from '@react-aria/tooltip'
import { mergeProps } from '@react-aria/utils'
import { useTooltipTriggerState } from '@react-stately/tooltip'
import { cls } from '@utils/classnames'
import { isMobile } from '@utils/mediaQueries'

import WarningTooltipContent from '../../UI/WarningTooltipContent'
import { MultiSelectOption } from '../ProductExplorerTypes'

interface MultiSelectListBoxProperties {
  triggerLabel: string
  selectionMode: 'single' | 'multiple'
  options: MultiSelectOption[]
  selectedOptions: Set<string>
  resetLabel?: string
  applyLabel?: string
  onResetSelection: () => void
  onApplySelection: () => void
  onSelectedOptionChange: React.Dispatch<React.SetStateAction<Set<string>>>
}

const MultiSelectListBox: React.FC<MultiSelectListBoxProperties> = ({
  triggerLabel,
  selectionMode,
  options,
  selectedOptions,
  applyLabel,
  resetLabel,
  onSelectedOptionChange,
  onApplySelection,
  onResetSelection,
}) => {
  const isMobileScreen = isMobile()
  const infoMessage = options.find((option) => option.showInfoIcon === true)
  useInteractionModality()

  return (
    <div className="cmp-multi-select-listbox">
      <ListBox
        label={triggerLabel}
        selectionMode={selectionMode}
        selectedKeys={selectedOptions}
        onSelectionChange={onSelectedOptionChange}
      >
        {options.map((option) => (
          <Item textValue={option.label} key={option.value}>
            <ListBoxItem option={option} />
          </Item>
        ))}
      </ListBox>

      {isMobileScreen && infoMessage ? (
        <WarningTooltipContent
          title={infoMessage?.infoMessageTitle}
          description={infoMessage?.infoMessageDescription}
        />
      ) : null}

      {applyLabel && resetLabel ? (
        <div className="cmp-multi-select-listbox__action-container">
          <button
            className="cmp-multi-select-listbox__action cmp-multi-select-listbox__action--reset"
            onClick={onResetSelection}
          >
            {resetLabel}
          </button>
          <button
            className="cmp-multi-select-listbox__action cmp-multi-select-listbox__action--apply"
            onClick={onApplySelection}
          >
            {applyLabel}
          </button>
        </div>
      ) : null}
    </div>
  )
}
interface ListBoxItemProperties {
  option: MultiSelectOption
}

function ListBoxItem({ option, state }: ListBoxItemProperties | any) {
  const properties = {}
  const tooltipState = useTooltipTriggerState({ delay: 0 })
  const buttonReference = useRef<HTMLButtonElement>(null)

  const { triggerProps, tooltipProps } = useTooltipTrigger(properties, tooltipState, buttonReference)
  const isMobileScreen = isMobile()

  const { buttonProps } = useButton(
    {
      ...properties,
    },
    buttonReference,
  )

  const hasFocus = state.selectionManager.focusedKey === option.value

  React.useEffect(() => {
    if (!option?.showInfoIcon) {
      return
    }

    if (hasFocus) {
      //just gained focus
      tooltipState.open()
    } else {
      tooltipState.close()
    }
  }, [hasFocus])

  return (
    <>
      <div className="cmp-multi-select-listbox__item">
        <div className="cmp-multi-select-listbox__item-description">
          {option.imageUrl ? <img src={option.imageUrl} alt="" className="cmp-multi-select-listbox__image" /> : null}
          {option.label || (option.value && !option.imageUrl) ? (
            <p className="cmp-multi-select-list-box__item-description">{option.label ?? option.value}</p>
          ) : null}
        </div>
        <div className="cmp-multi-select-listbox__item-selection">
          <i className="ri-check-line" />
        </div>
        {option?.showInfoIcon ? (
          <div style={{ position: 'relative', marginLeft: '8px' }}>
            <button
              className="cmp-multi-select-listbox__item--info-button"
              ref={buttonReference}
              {...mergeProps(buttonProps, triggerProps)}
              aria-label={`${option.infoMessageTitle} ${option.infoMessageDescription}`}
              tabIndex={0}
            >
              <i className="ri-spam-2-line"></i>
            </button>
          </div>
        ) : null}
      </div>
      {!isMobileScreen && option?.showInfoIcon ? (
        <WarningToolTip
          state={tooltipState}
          option={option}
          tooltipProps={mergeProps(properties, tooltipProps)}
          buttonReference={buttonReference}
        />
      ) : null}
    </>
  )
}

function WarningToolTip({ state, option, tooltipProps, buttonReference }) {
  const buttonRect = buttonReference.current?.getBoundingClientRect()

  const tooltipLeft = buttonRect ? buttonRect.left : 0
  const tooltipTop = buttonRect ? buttonRect.top + buttonRect.height : 0

  return createPortal(
    <div
      style={{
        position: 'fixed',
        display: state.isOpen ? undefined : 'none',
        zIndex: '100001',
        left: `${tooltipLeft}px`,
        top: `${tooltipTop}px`,
        width: '345px',
        transform: `translateX(-50%)translateX(${(buttonRect?.width || 0) / 2}px)translateY(16px)`, // arrow
      }}
      {...tooltipProps}
    >
      <WarningTooltipContent title={option.infoMessageTitle} description={option.infoMessageDescription} />
    </div>,
    document.body,
  )
}

function ListBox(properties) {
  // Create state based on the incoming props
  const state = useListState(properties)

  // Get props for the listbox element
  const reference = React.useRef<HTMLUListElement>(null)
  const { listBoxProps, labelProps } = useListBox(properties, state, reference)

  return (
    <>
      <div {...labelProps}>{properties.label}</div>
      <ul {...listBoxProps} ref={reference} className="cmp-listbox">
        {[...state.collection].map((item) => (
          <Option key={item.key} item={item} state={state} />
        ))}
      </ul>
    </>
  )
}

function Option({ item, state }) {
  // Get props for the option element
  const reference = React.useRef<HTMLLIElement>(null)
  const { optionProps, isSelected, labelProps } = useOption({ key: item.key }, state, reference)

  // Determine whether we should show a keyboard
  // focus ring for accessibility
  const { isFocusVisible, focusProps } = useFocusRing()

  return (
    <li
      {...mergeProps(optionProps, focusProps)}
      ref={reference}
      className={cls({
        'multi-select-listbox-option': true,
        'multi-select-listbox-option--selected': isSelected,
      })}
      style={{
        outline: isFocusVisible ? '2px solid orange' : 'none',
      }}
    >
      {React.cloneElement(item.rendered, { ...labelProps, state })}
    </li>
  )
}

export default MultiSelectListBox
