import { FC, Key, useEffect, useMemo, useRef, useState } from 'react'
import Flickity from 'react-flickity-component'
import { registerComponent } from 'react-register-dom'
import ResponsiveImage from '@components/ResponsiveImage'
import { cls } from '@utils/classnames'
import { getBooleanFromValue } from '@utils/getBooleanFromValue'

import { HostpotsSlide } from './HotspotsSlide'
import { HotspotsSliderJsonData } from './HotspotsTypes'

interface Properties {
  jsonData: string
  isEditMode: string | boolean
}

const flickityOptions = {
  cellAlign: 'left',
  pageDots: false,
  draggable: true,
  contain: true,
}

function logHotspotInteraction({
  instanceId,
  slideIndex,
  slideTrackingName,
  hotspotTitle,
}: {
  instanceId: string
  slideIndex: number
  slideTrackingName: string
  hotspotTitle: string
}) {
  window.adobeDataLayer?.push({
    event: 'hotspot interactions',
    eventInfo: {
      reference: slideTrackingName,
      thumbnailPosition: slideIndex,
      title: hotspotTitle,
      componentId: instanceId,
    },
  })
}

// eslint-disable-next-line sonarjs/cognitive-complexity
const HotspotsSlider: FC<Properties> = ({ jsonData, isEditMode = 'false' }) => {
  const hotspots = useMemo(() => JSON.parse(jsonData) as HotspotsSliderJsonData, [jsonData])
  const [selectedIndex, setSelectedIndex] = useState<number>(0)
  const [clickedHotspotIndex, setClickedHotspotIndex] = useState<string | null>(null)
  const [loggedHotspotsInteraction, setLoggedHotspotsInteractions] = useState<Record<string, true>>({})
  const isEditModeBoolean = getBooleanFromValue(isEditMode)

  const slider = useRef<HTMLDivElement>(null)

  const flickityReference = useRef<Flickity | null>(null)

  function moveLeft() {
    flickityReference.current?.previous(true)
    setClickedHotspotIndex(null)
  }

  function moveRight() {
    flickityReference.current?.next(true)
    setClickedHotspotIndex(null)
  }

  function goToSlide(slideIndex: number) {
    flickityReference.current?.select(slideIndex)
    setClickedHotspotIndex(null)
  }

  useEffect(() => {
    function onChange() {
      setSelectedIndex(flickityReference.current?.selectedIndex || 0)
    }
    flickityReference.current?.on('change', onChange)

    return () => {
      if (flickityReference.current) {
        flickityReference.current.off('change', onChange)
      }
    }
  }, [])

  const logInteraction = (id) => {
    const instanceId = hotspots.id
    const slideIndex = flickityReference.current?.selectedIndex || 0
    const slideTrackingName = hotspots.hotspots[slideIndex].trackingName
    const hotspotTitle = id.slice(Math.max(0, id.indexOf('-') + 1)) || ''
    const hotspotId = `${instanceId}${slideTrackingName}${hotspotTitle}`

    if (!loggedHotspotsInteraction[hotspotId]) {
      logHotspotInteraction({
        instanceId,
        slideIndex: slideIndex + 1,
        slideTrackingName,
        hotspotTitle,
      })
      setLoggedHotspotsInteractions((old) => ({ ...old, [hotspotId]: true }))
    }
  }
  const toggleActiveHotspotIndex = (index) => {
    logInteraction(index)
    setClickedHotspotIndex((currentIndex) => (currentIndex === index ? null : index))
  }

  const handleCloseClick = () => {
    setClickedHotspotIndex(null)
  }

  const hotspotSlide = hotspots.hotspots.map((image, index: Key) => (
    <HostpotsSlide
      key={index}
      image={image}
      onHotspotClick={toggleActiveHotspotIndex}
      onHotspotClose={handleCloseClick}
      activeHotspot={clickedHotspotIndex}
      sliderRef={slider}
    />
  ))

  return (
    <div className="cmp-hotspots-slider__container">
      {isEditModeBoolean ? (
        <div className="cmp-hotspot-grid">
          <div className="cmp-hotspot-grid--left">
            <span className="cmp-hotspot-grid__percentage" style={{ top: '0' }}></span>
            <span className="cmp-hotspot-grid__percentage" style={{ top: '10%' }}>
              10
            </span>
            <span className="cmp-hotspot-grid__percentage" style={{ top: '20%' }}>
              20
            </span>
            <span className="cmp-hotspot-grid__percentage" style={{ top: '30%' }}>
              30
            </span>
            <span className="cmp-hotspot-grid__percentage" style={{ top: '40%' }}>
              40
            </span>
            <span className="cmp-hotspot-grid__percentage" style={{ top: '50%' }}>
              50
            </span>
            <span className="cmp-hotspot-grid__percentage" style={{ top: '60%' }}>
              60
            </span>
            <span className="cmp-hotspot-grid__percentage" style={{ top: '70%' }}>
              70
            </span>
            <span className="cmp-hotspot-grid__percentage" style={{ top: '80%' }}>
              80
            </span>
            <span className="cmp-hotspot-grid__percentage" style={{ top: '90%' }}>
              90
            </span>
            <span className="cmp-hotspot-grid__percentage" style={{ top: '100%' }}>
              100
            </span>
          </div>
          <div className="cmp-hotspot-grid--top">
            <span className="cmp-hotspot-grid__percentage" style={{ left: '0' }}>
              0
            </span>
            <span className="cmp-hotspot-grid__percentage" style={{ left: '10%' }}>
              10
            </span>
            <span className="cmp-hotspot-grid__percentage" style={{ left: '20%' }}>
              20
            </span>
            <span className="cmp-hotspot-grid__percentage" style={{ left: '30%' }}>
              30
            </span>
            <span className="cmp-hotspot-grid__percentage" style={{ left: '40%' }}>
              40
            </span>
            <span className="cmp-hotspot-grid__percentage" style={{ left: '50%' }}>
              50
            </span>
            <span className="cmp-hotspot-grid__percentage" style={{ left: '60%' }}>
              60
            </span>
            <span className="cmp-hotspot-grid__percentage" style={{ left: '70%' }}>
              70
            </span>
            <span className="cmp-hotspot-grid__percentage" style={{ left: '80%' }}>
              80
            </span>
            <span className="cmp-hotspot-grid__percentage" style={{ left: '90%' }}>
              90
            </span>
            <span className="cmp-hotspot-grid__percentage" style={{ left: '100%' }}>
              100
            </span>
          </div>
          <div className="cmp-hotspots-slider cmp-hotspots-slider--editMode" ref={slider}>
            <Flickity
              flickityRef={(reference) => (flickityReference.current = reference)}
              className="cmp-hotspots-slider__slider"
              elementType="div"
              options={flickityOptions}
            >
              {hotspotSlide}
            </Flickity>
            {hotspots.hotspots.length > 1 && (
              <>
                <button
                  className="cmp-hotspots-slider__button cmp-hotspots-slider__button--left"
                  onClick={moveLeft}
                  aria-label={hotspots.ariaLabelPreviousImage}
                >
                  <i className="ri-arrow-left-line" />
                </button>
                <button
                  className="cmp-hotspots-slider__button cmp-hotspots-slider__button--right"
                  onClick={moveRight}
                  aria-label={hotspots.ariaLabelNextImage}
                >
                  <i className="ri-arrow-right-line" />
                </button>
              </>
            )}
          </div>
        </div>
      ) : (
        <div className="cmp-hotspots-slider" ref={slider}>
          <Flickity
            flickityRef={(reference) => (flickityReference.current = reference)}
            className="cmp-hotspots-slider__slider"
            elementType="div"
            options={flickityOptions}
          >
            {hotspotSlide}
          </Flickity>
          {hotspots.hotspots.length > 1 && (
            <>
              <button
                className="cmp-hotspots-slider__button cmp-hotspots-slider__button--left"
                onClick={moveLeft}
                aria-label={hotspots.ariaLabelPreviousImage}
              >
                <i className="ri-arrow-left-line" />
              </button>
              <button
                className="cmp-hotspots-slider__button cmp-hotspots-slider__button--right"
                onClick={moveRight}
                aria-label={hotspots.ariaLabelNextImage}
              >
                <i className="ri-arrow-right-line" />
              </button>
            </>
          )}
        </div>
      )}
      <div className="cmp-hotspots-slider__hotspots-section">
        {hotspots.hotspots.length > 0 && (
          <div className="cmp-hotspots-slider__hotspots-thumbnails">
            {hotspots.hotspots.map((image, index) => (
              <button
                className={cls({
                  'cmp-hotspots-slider__thumbnail': true,
                  'cmp-hotspots-slider__thumbnail--is-selected': index === selectedIndex,
                  'cmp-hotspots-slider__thumbnail--is-hidden': hotspots.hotspots.length === 1,
                })}
                key={index}
                onClick={() => goToSlide(index)}
                aria-label={image.ariaLabelSelectImage}
              >
                <ResponsiveImage alt={image.image.altText || ''} src={image.image.originalImageUrl} />
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

registerComponent('HotspotsSlider', HotspotsSlider)

export default HotspotsSlider
