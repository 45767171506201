import React from 'react'
import SearchInputField from '@components/SearchInputField'

import LanguageSwitcherCountry, { CountryLanguageSwitcher } from './LanguageSwitcherCountry'

interface LanguageSwitcherProperties {
  config: CountryLanguageSwitcher
}
const LanguageSwitcher: React.FC<LanguageSwitcherProperties> = ({ config }: LanguageSwitcherProperties) => {
  const [searchFilter, setSearchFilter] = React.useState('')
  const displayedNodes = React.useMemo(() => {
    return config.items.filter((countryNode) => {
      const countryMatches = countryNode.title.toLowerCase().includes(searchFilter.toLowerCase())
      const anyLanguageMatches = countryNode.languages.some((languageNode) =>
        languageNode.title.toLowerCase().includes(searchFilter.toLowerCase()),
      )

      return countryMatches || anyLanguageMatches
    })
  }, [config.items, searchFilter])

  return (
    <div className="language-switcher">
      <p className="language-switcher__heading-label">{config.countrySwitchLabel}</p>
      {!config.hideSearch && (
        <div className="language-switcher__search-container">
          <SearchInputField placeholder={config.searchPlaceholder} value={searchFilter} onChange={setSearchFilter} />
        </div>
      )}
      <div className="language-switcher__country-list">
        {displayedNodes.map((countryNode) => (
          <LanguageSwitcherCountry
            countryNode={countryNode}
            countryPageLabel={config.countryPageLabel}
            key={countryNode.title}
          />
        ))}
      </div>
    </div>
  )
}

export default LanguageSwitcher
