import React, { useContext } from 'react'
import ResponsiveImage from '@components/ResponsiveImage'
import { cls } from '@utils/classnames'

import { HeaderContext, NavigationItemTeaser } from '../HeaderContext'

interface NavigationTeaserProperties {
  isHeaderOpened: boolean
  navigationTeaserData?: { teaser?: NavigationItemTeaser }
}

const navigationContentShownClass = 'cmp-navigation-teaser--content-shown'
const navigationContentHiddenClass = 'cmp-navigation-teaser--content-hidden'

const NavigationTeaser: React.FC<NavigationTeaserProperties> = ({
  isHeaderOpened,
  navigationTeaserData,
}: NavigationTeaserProperties): JSX.Element => {
  const [displayedTeaseData, setDisplayedTeaserData] = React.useState<NavigationItemTeaser | null>(null)
  const [displayState, setDisplayState] = React.useState(navigationContentHiddenClass)
  const { teaserContentReference, teaserContainerReference, waitForTransition } = useContext(HeaderContext)

  React.useEffect(() => {
    if (!displayedTeaseData && navigationTeaserData?.teaser) {
      setDisplayedTeaserData(navigationTeaserData.teaser)
      waitForTransition(() => {
        setDisplayState(navigationContentShownClass)
      }, teaserContainerReference)
    } else if (!navigationTeaserData?.teaser) {
      waitForTransition(() => {
        setDisplayedTeaserData(null)
        setDisplayState(navigationContentHiddenClass)
      }, teaserContentReference)
    } else if (navigationTeaserData.teaser.id !== displayedTeaseData?.id) {
      setDisplayState(navigationContentHiddenClass)
      waitForTransition(() => {
        setDisplayedTeaserData(navigationTeaserData.teaser || null)
        setDisplayState(navigationContentShownClass)
      }, teaserContentReference)
    }
  }, [navigationTeaserData, displayedTeaseData])

  return (
    <div
      className={cls({
        'cmp-navigation-teaser': true,
        'cmp-navigation-teaser--is-displayed': isHeaderOpened,
        'cmp-navigation-teaser--is-opened': navigationTeaserData?.teaser?.link,
      })}
    >
      <div className={`cmp-navigation-teaser__inner ${displayState} `} ref={teaserContainerReference}>
        <h2 className="cmp-navigation-teaser__illustration-label">{displayedTeaseData?.illustrationLabel}</h2>
        <div className="cmp-navigation-teaser__content" ref={teaserContentReference}>
          {displayedTeaseData?.teaserImage?.originalImageUrl && (
            <ResponsiveImage
              className="cmp-navigation-teaser__image"
              shouldLazyLoad="false"
              src={displayedTeaseData.teaserImage.originalImageUrl}
              alt={displayedTeaseData.teaserImage.altText}
            />
          )}
          <div className="cmp-navigation-teaser__content-text">
            <p className="cmp-navigation-teaser__content-description">{displayedTeaseData?.description}</p>
            {displayedTeaseData?.link && (
              <a className="cmp-navigation-teaser__content-link" href={displayedTeaseData.link}>
                {displayedTeaseData?.linkLabel}
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default NavigationTeaser
