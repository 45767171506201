import React from 'react'
import { useAutoGET } from '@hooks/fetch'
import { isMobile } from '@utils/mediaQueries'

import { ProductExplorerConfig } from './ProductExplorerTypes'

const MOBILE_PAGE_SIZE = 7
const DESKTOP_PAGE_SIZE = 20
const ACTIVE_FILTERS_SEARCH_PARAM_NAME = 'activeFilters'
const PAGE_SEARCH_PARAM_NAME = 'page'
const TEXT_SEARCH_SEARCH_PARAM_NAME = 'query'

type ProductExplorerAPIResult = {
  status: string
  filters: {
    id: string
    label: string
    options: {
      label: string
      value: string
    }[]
  }[]
  productList: {
    description: string
    id: string
    imageUrl: string
    name: string
    colorVariants: {
      name: string
      imageUrl: string
    }[]
  }[]
  totalElementCount: number
}

function useProductExplorerSearchEndpoint(url: string, queryParameters: Record<string, string[] | any>) {
  return useAutoGET<ProductExplorerAPIResult>(url, queryParameters)
}

function activeFiltersToQueryParametersString(newActiveFilters: Record<string, Set<string>>): string {
  return Object.entries(newActiveFilters)
    .map(([key, values]) => (values?.size > 0 ? `${key}:${[...values].join(',')}` : ''))
    .filter((activeFilter) => Boolean(activeFilter))
    .join(';')
}

function queryParameterToActiveFilters(filtersString: string | null): Record<string, Set<string>> {
  if (!filtersString) {
    return {}
  }

  return Object.fromEntries(
    filtersString
      .split(';')
      .map((filterString) => filterString.split(':')) // array of [key, value1,value2]
      .filter(([, value]) => Boolean(value))
      .map(([key, valuesString]) => [key, new Set(valuesString.split(','))]),
  )
}

function updateQueryParameter(newActiveFilters: Record<string, Set<string>>) {
  const parametersValue = activeFiltersToQueryParametersString(newActiveFilters)
  const currentPage = getCurrentPageFromQueryParameter()

  const parameters = new URLSearchParams(location.search)

  parameters.set(PAGE_SEARCH_PARAM_NAME, currentPage.toString())
  if (parametersValue.toString().length > 0) {
    parameters.set(ACTIVE_FILTERS_SEARCH_PARAM_NAME, parametersValue.toString())
  } else {
    parameters.delete(ACTIVE_FILTERS_SEARCH_PARAM_NAME)
  }

  const newUrl = `${window.location.protocol}//${window.location.host}${
    window.location.pathname
  }?${parameters.toString()}`
  window.history.replaceState({ path: newUrl }, '', newUrl)
}

function getCurrentPageFromQueryParameter() {
  const query = new URLSearchParams(location.search)
  const page = query.get(PAGE_SEARCH_PARAM_NAME)
  if (page && page.length > 0) {
    try {
      return Number(page)
    } catch {
      return 1
    }
  }

  return 1
}

function setPageParameterSearchQuery(newPage: number) {
  const query = new URLSearchParams(location.search)
  query.set(PAGE_SEARCH_PARAM_NAME, newPage.toString())

  const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${query.toString()}`

  window.history.replaceState({ path: newUrl }, '', newUrl)
}

function setTextSearchParameterSearchQuery(newTextSearch: string) {
  const query = new URLSearchParams(location.search)
  query.set(TEXT_SEARCH_SEARCH_PARAM_NAME, newTextSearch)

  const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${query.toString()}`

  window.history.replaceState({ path: newUrl }, '', newUrl)
}

function useActiveFiltersInUrl() {
  const urlParameters = new URLSearchParams(location.search)
  const filtersString = urlParameters.get(ACTIVE_FILTERS_SEARCH_PARAM_NAME)
  const currentlyActiveFilters = queryParameterToActiveFilters(filtersString)
  const [activeFilters, setActiveFilters] = React.useState(currentlyActiveFilters)

  const handleActiveFiltersChange: React.Dispatch<
    React.SetStateAction<{
      [k: string]: Set<string>
    }>
  > = React.useCallback(
    (newValue) => {
      if (typeof newValue === 'function') {
        setActiveFilters((old) => {
          const valueToApply = newValue(old)
          updateQueryParameter(valueToApply)

          return valueToApply
        })
      } else {
        updateQueryParameter(newValue)
        setActiveFilters(newValue)
      }
    },
    [setActiveFilters],
  )

  return [activeFilters, handleActiveFiltersChange] as const
}

function useTextSearchInUrl() {
  const urlParameters = new URLSearchParams(location.search)
  const textSearchInUrl = urlParameters.get(TEXT_SEARCH_SEARCH_PARAM_NAME) || ''

  const [textSearch, setTextSearch] = React.useState(textSearchInUrl)

  const handleTextSearchChange: React.Dispatch<React.SetStateAction<string>> = React.useCallback(
    (newValue) => {
      if (typeof newValue === 'function') {
        setTextSearch((old) => {
          const valueToApply = newValue(old)
          setTextSearchParameterSearchQuery(valueToApply)

          return valueToApply
        })
      } else {
        setTextSearchParameterSearchQuery(newValue)
        setTextSearch(newValue)
      }
    },
    [setTextSearch],
  )

  return [textSearch, handleTextSearchChange] as const
}

export function useProductExplorerLogic(config: ProductExplorerConfig) {
  const pageSize = isMobile() ? MOBILE_PAGE_SIZE : DESKTOP_PAGE_SIZE
  const [textSearch, setTextSearch] = useTextSearchInUrl()
  const [activeFilters, setActiveFilters] = useActiveFiltersInUrl()

  const [currentPage, setCurrentPage] = React.useState(getCurrentPageFromQueryParameter())

  const activeFiltersAsParametersString = React.useMemo(
    () => activeFiltersToQueryParametersString(activeFilters),
    [activeFilters],
  )

  const queryParameters = React.useMemo(
    () => ({
      ...config.searchEndpoint.defaultParams,
      [config.searchEndpoint.pageSizeParamName]: pageSize.toString(),
      [config.searchEndpoint.offsetParamName]: ((currentPage - 1) * pageSize).toString(),
      [config.searchEndpoint.activeFiltersParamName]: activeFiltersAsParametersString,
      [config.searchEndpoint.textSearchParamName]: textSearch,
    }),
    [
      config.searchEndpoint.defaultParams,
      config.searchEndpoint.textSearchParamName,
      config.searchEndpoint.pageSizeParamName,
      config.searchEndpoint.offsetParamName,
      textSearch,
      pageSize,
      currentPage,
      activeFiltersAsParametersString,
    ],
  )

  const [searchState] = useProductExplorerSearchEndpoint(config.searchEndpoint.url, queryParameters)

  const handleOptionsChange = React.useCallback(
    (filterId: string, newValue: Set<string>) => {
      setActiveFilters((old) => ({
        ...old,
        [filterId]: newValue,
      }))
    },
    [setActiveFilters],
  )

  const handleFilterReset = React.useCallback(() => {
    setActiveFilters({})
  }, [setActiveFilters])

  const updateCurrentPage = React.useCallback(
    (newPage: number) => {
      setPageParameterSearchQuery(newPage)
      setCurrentPage(newPage)
    },
    [setCurrentPage],
  )

  return {
    currentPage,
    pageSize,
    endpointState: searchState,
    activeFilters,
    textSearch,
    handleOptionsChange,
    handleFilterReset,
    handlePageChange: updateCurrentPage,
    setTextSearch,
  }
}
