import React from 'react'
import VideoPlayButton from '@components/Icons/VideoPlayButton'
import ResponsiveImage from '@components/ResponsiveImage'
interface VideoCoverProperties {
  id: string
  src: string
  altText: string
  ariaLabel: string
  onClick: () => void
}

const VideoCover: React.FC<VideoCoverProperties> = ({
  id,
  src,
  altText,
  ariaLabel,
  onClick,
}: VideoCoverProperties): JSX.Element => {
  return (
    <div className="cmp-video__cover" id={id}>
      {(src && <ResponsiveImage aria-label={ariaLabel} className="cmp-video__image" src={src} alt={altText} />) || null}
      <button className="cmp-video__button" onClick={onClick}>
        <VideoPlayButton />
      </button>
    </div>
  )
}

export default VideoCover
