import { useMemo, useState } from 'react'
import { registerComponent } from 'react-register-dom'
import ButtonLink from '@components/ButtonLink'
import DownloadList from '@components/DownloadList'
import NoResults from '@components/NoResults'
import Accordion from '@components/UI/Accordion'
import Dropdown from '@components/UI/Dropdown'
import ErrorWrapper from '@components/UI/ErrorWrapper'
import { cls } from '@utils/classnames'

interface Document {
  language: string
  productTag: string
  documentTypeTag: string
  documentTypeLabel: string
  documentTitle: string
  url: string
  size?: string
  type?: string
}

export interface DownloadPlannerData {
  documents: Document[]
  category: any
  language: any
  search: any
  shownItemCount: number
  showMoreLabel: string
  resetFilterLabel: string
  noResultsLabel: string
  noResultsDescription: string
  groupByType: boolean
}

interface DropdownItem {
  value: string
  label: string
}
const transformDocumentObject = (document) => {
  return document?.map((item) => {
    return {
      title: item.documentTitle,
      link: item.url,
      ariaLabel: item.documentTitle,
      size: item.size,
      type: item.type,
    }
  })
}

const PlannerData = ({ jsonData }: { jsonData: string }): JSX.Element => {
  const data = useMemo<DownloadPlannerData>(() => {
    return JSON.parse(jsonData)
  }, [jsonData])

  const [categoryFilter, setCategoryFilter] = useState<DropdownItem | null>(null)
  const [languageFilter, setLanguageFilter] = useState<DropdownItem | null>(null)
  const [numberOfShownItems, setNumberOfShownItems] = useState<number>(Number(data.shownItemCount))
  const [searchTerm, setSearchTerm] = useState<string>()
  const [openAccordion, setOpenAccordion] = useState<string>()
  const handleAccordionToggle = (key) => {
    openAccordion === key ? setOpenAccordion('') : setOpenAccordion(key)
  }

  const groupedLayout = data.groupByType

  const filteredDocuments = useMemo(() => {
    const filteredList = data.documents?.filter((document) => {
      return (
        (!languageFilter || document.language == languageFilter.value) &&
        (!categoryFilter || document.productTag == categoryFilter.value) &&
        (!searchTerm ||
          document.documentTypeLabel?.toLowerCase().includes(searchTerm.toLocaleLowerCase()) ||
          document.documentTitle?.toLowerCase().includes(searchTerm.toLocaleLowerCase()))
      )
    })

    if (!groupedLayout) {
      return filteredList
    }

    // eslint-disable-next-line unicorn/prefer-object-from-entries, unicorn/no-array-reduce
    const groupedList = filteredList.reduce<{
      [key: string]: {
        label: string
        documents: Document[]
      }
    }>((group, document) => {
      const { documentTypeTag, documentTypeLabel } = document
      group[documentTypeTag] = group[documentTypeTag] || {
        label: documentTypeLabel,
        documents: [],
      }
      group[documentTypeTag].documents.push(document)
      return group
    }, {})

    return groupedLayout ? groupedList : filteredList
  }, [categoryFilter, languageFilter, searchTerm, data, groupedLayout])

  const documentGroups = useMemo(() => Object.keys(filteredDocuments), [filteredDocuments])

  return (
    <ErrorWrapper>
      <div className="cmp-planner-data">
        <div className="cmp-planner-data__filters">
          <div className="cmp-planner-data__filter">
            <Dropdown
              label={data.category.label}
              placeholder={data.category.placeholder}
              options={data.category.options.map((option) => {
                return { ...option, label: option.categoryLabel }
              })}
              onChange={setCategoryFilter}
              value={categoryFilter}
              name={data.category.type}
              isClearable
            />
          </div>
          <div className="cmp-planner-data__filter">
            <Dropdown
              label={data.language.label}
              placeholder={data.language.placeholder}
              options={data.language.options}
              onChange={setLanguageFilter}
              value={languageFilter}
              name={data.language.type}
              isClearable
            />
          </div>
          <div className="cmp-planner-data__search-wrapper">
            <div className="cmp-planner-data__search">
              <label className="cmp-planner-data__search-label">{data.search.label}</label>
              <input
                className="cmp-planner-data__search-input-field"
                placeholder={data.search.placeHolder}
                value={searchTerm}
                onChange={(event) => setSearchTerm(event.target.value)}
              />
            </div>
          </div>
          <div className="cmp-planner-data__reset">
            <ButtonLink
              text={data.resetFilterLabel}
              icon="ri-arrow-go-back-fill"
              onClick={() => {
                setCategoryFilter(null)
                setLanguageFilter(null)
                setSearchTerm('')
              }}
            />
          </div>
        </div>

        <ul className="cmp-planner-data__results">
          {groupedLayout &&
            documentGroups.length > 0 &&
            documentGroups.map((key, index) => {
              if (index < numberOfShownItems) {
                const documentTree = filteredDocuments[key]

                return (
                  <li key={key} className="cmp-planner-data__result-item">
                    <div
                      role="button"
                      className="cmp-planner-data__document-type"
                      tabIndex={0}
                      onKeyPress={() => handleAccordionToggle(key)}
                      onClick={() => handleAccordionToggle(key)}
                    >
                      <p className="cmp-planner-data__document-type-label">{documentTree.label}</p>
                      <i
                        className={cls({
                          'ri-add-line': true,
                          'cmp-planner-data__document-type-icon': true,
                          'cmp-planner-data__document-type-icon--is-open': openAccordion === key,
                        })}
                      ></i>
                    </div>{' '}
                    <Accordion isOpen={openAccordion === key}>
                      <DownloadList items={transformDocumentObject(documentTree.documents)} />
                    </Accordion>
                  </li>
                )
              }
            })}
          {!groupedLayout && (
            <DownloadList
              items={transformDocumentObject(filteredDocuments).slice(0, numberOfShownItems)}
              noResultsTitle={data.noResultsLabel}
              noResultsDescription={data.noResultsDescription}
            />
          )}
          {groupedLayout && documentGroups.length === 0 && (
            <NoResults title={data.noResultsLabel} description={data.noResultsDescription} />
          )}
        </ul>
        {documentGroups.length > numberOfShownItems && (
          <div className="cmp-planner-data__pagination">
            <button
              className="cmp-planner-data__load-more"
              type="button"
              onClick={(event) => {
                event.preventDefault()
                setNumberOfShownItems((current) => Number(current) + Number(data.shownItemCount))
              }}
            >
              {data.showMoreLabel}
            </button>
          </div>
        )}
      </div>
    </ErrorWrapper>
  )
}
registerComponent('PlannerData', PlannerData)

export default PlannerData
