import React from 'react'
import CountUp from 'react-countup'
import { useInView } from 'react-intersection-observer'
import { registerComponent } from 'react-register-dom'
import { getBooleanFromValue } from '@utils/getBooleanFromValue'
import { getDecimalsCount, getStringNumber, getSymbol } from '@utils/stringOperations'

interface KeyFigureProperties {
  number: string
  isAnimated: string
  // TODO: this can be authored
  // animationDuration?: string
}

// TODO: check animation duration
const KeyFigure: React.FC<KeyFigureProperties> = ({
  number,
  isAnimated,
  // animationDuration,
}) => {
  // const seconds = Number(
  //   ((Number.parseInt(animationDuration ?? '1000') % 60_000) / 1000).toFixed(0),
  // )
  const { ref: referenceInView, inView } = useInView({
    threshold: 0,
    trackVisibility: true,
    delay: 100,
  })
  return number ? (
    <div className="cmp-key-figure__item-number-wrapper" ref={referenceInView}>
      {getBooleanFromValue(isAnimated) && inView ? (
        <CountUp
          end={Number(getStringNumber(number))}
          duration={1}
          decimal="."
          decimals={getDecimalsCount(getStringNumber(number))}
          suffix={getSymbol(number)}
          useEasing
        />
      ) : (
        number
      )}
    </div>
  ) : null
}

registerComponent('KeyFigure', KeyFigure)

export default KeyFigure
