function pushToAdobeDataLayer({ eventName, reference }: { eventName: string; reference: string }) {
  try {
    window?.adobeDataLayer?.push({
      event: eventName,
      eventInfo: {
        reference,
      },
    })
  } catch (error) {
    console.error('xxx pushToAdobeDataLayer', error)
  }
}

export const clickedFloaterButton = (applicationType: string) => {
  pushToAdobeDataLayer({
    eventName: 'floaterBarClicked',
    reference: applicationType,
  })
}

export const initFloaterButton = (applicationType: string) => {
  pushToAdobeDataLayer({
    eventName: 'floaterBarInit',
    reference: applicationType,
  })
}
