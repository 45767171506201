import React from 'react'
import { registerComponent } from 'react-register-dom'
import FormContent from '@components/Form/FormContent'
import { AEMLinkProperties, FormContentModel } from '@components/Form/FormTypes'
import Modal from '@components/Modal'
import useModal from '@hooks/useModal'

interface BlogFloatingButtonProperties {
  fieldConfig: FormContentModel
  isModal: boolean
  modalCloseLabel?: string
  modalCloseAriaLabel?: string
  buttonLink?: AEMLinkProperties
  endpointUrl: {
    formEndpointUrl: string
  }
  recaptchaElementId?: string
}

function pushOpenFloatingModalEvent() {
  if (window.adobeDataLayer) {
    window.adobeDataLayer.push({
      event: 'expanded floater',
      eventInfo: {
        reference: 'newsletter sign up',
      },
    })
  }
}

const BlogNewsletterFloatingButton: React.FC<BlogFloatingButtonProperties> = ({
  fieldConfig,
  endpointUrl,
  isModal,
  buttonLink,
  modalCloseLabel,
  modalCloseAriaLabel,
  recaptchaElementId,
}) => {
  const { isShowing, toggleModal } = useModal()

  function handleModalOpen() {
    toggleModal()
    pushOpenFloatingModalEvent()
  }

  if (isModal) {
    return (
      <>
        <div
          className="cmp-blog__floating-bar"
          onClick={handleModalOpen}
          onKeyDown={handleModalOpen}
          role="button"
          tabIndex={0}
        >
          <i className="ri-mail-line"></i>
        </div>
        {isShowing ? (
          <Modal
            closeButtonLabel={modalCloseLabel}
            ariaLabel={modalCloseAriaLabel}
            isShowing={isShowing}
            closeModal={toggleModal}
          >
            <FormContent
              className="cmp-blog-floating-button__modal-content"
              fieldConfig={fieldConfig}
              endpointUrl={endpointUrl}
              recaptchaElementId={recaptchaElementId}
            />
          </Modal>
        ) : null}
      </>
    )
  }
  return (
    <a
      className="cmp-blog__floating-bar"
      href={buttonLink?.externalizedURL}
      target={buttonLink?.isTargertBlank && '_blank'}
      rel={buttonLink?.htmlAttributes?.rel}
    >
      <i className="ri-mail-line"></i>
    </a>
  )
}
registerComponent('BlogNewsletterFloatingButton', BlogNewsletterFloatingButton)
export default BlogNewsletterFloatingButton
