import React, { useEffect, useRef } from 'react'
import ResponsiveImage from '@components/ResponsiveImage'

import { HeaderTag } from './SliderHeroTypes'

interface HeroContent {
  heroImage: { originalImageUrl: string; altText: string; srcSet?: string }
  heroPretitle: string
  heroTitle: string
  heroTitleHeadingLevel: HeaderTag
  heroDescription: string
  link: { externalizedURL: string; isTargetBlank: boolean }
  ctaLabel: string
  selectedIndex: number
  htmlAttributes?: { href: string; rel: string }
}

const SliderHeroContent: React.FC<HeroContent> = ({
  heroImage,
  heroPretitle,
  heroTitle,
  heroTitleHeadingLevel: HeaderTag = 'h3' as HeaderTag,
  heroDescription,
  link,
  ctaLabel,
  selectedIndex,
  htmlAttributes,
}: HeroContent): JSX.Element => {
  const animationReference = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    if (animationReference.current) {
      animationReference.current.style.animation = 'none'
      animationReference.current.offsetHeight
      animationReference.current.style.animation = ''
    }
  }, [selectedIndex])

  return (
    <>
      {heroImage?.originalImageUrl && (
        <div className="cmp-hero-slider__image">
          <ResponsiveImage
            src={heroImage.originalImageUrl}
            alt={heroImage.altText}
            fetchPriorityHigh={selectedIndex === 0}
            shouldLazyLoad={selectedIndex !== 0}
            srcSet={heroImage.srcSet}
          />
        </div>
      )}
      <div className="cmp-hero-slider__content" ref={animationReference}>
        {heroPretitle && <p className="cmp-hero-slider__content-pretitle">{heroPretitle}</p>}
        {heroTitle && <HeaderTag className="cmp-hero-slider__content-title">{heroTitle}</HeaderTag>}
        {heroDescription && <p className="cmp-hero-slider__content-description">{heroDescription}</p>}
        {link?.externalizedURL && ctaLabel && (
          <a
            href={link.externalizedURL}
            target={link.isTargetBlank ? '_blank' : ''}
            rel={htmlAttributes?.rel || ''}
            aria-label={ctaLabel && heroTitle ? ctaLabel + ': ' + heroTitle : ''}
            className="cmp-hero-slider__content-cta"
          >
            <span>{ctaLabel}</span>
          </a>
        )}
      </div>
    </>
  )
}

export default SliderHeroContent
