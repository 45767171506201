import * as React from 'react'

const WarningTooltipContent: React.FC<{
  title?: string
  description?: string
}> = ({ title, description }) => {
  return (
    <div className="cmp-warning-tooltip">
      <i className="ri-spam-2-line"></i>
      <div className="ccmp-warning-tooltip__container">
        <div className="cmp-warning-tooltip__title">{title}</div>
        <div className="cmp-warning-tooltip__description">{description}</div>
      </div>
    </div>
  )
}

export default WarningTooltipContent
