import React from 'react'

const NavigationAccordionIcon = ({ open, useArrow }: { open: boolean; useArrow: boolean }) => {
  return (
    <span className="cmp-navigation-accordion__icon">
      <i className={`ri-${useArrow ? 'arrow-right-line' : open ? 'subtract-line' : 'add-line'}`}></i>
    </span>
  )
}

export default NavigationAccordionIcon
