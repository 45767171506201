import React from 'react'
import DownloadList from '@components/DownloadList'
import { ColorVariant } from '@components/ProductExplorer/ProductExplorerTypes'
import ProductListItem from '@components/ProductExplorer/ProductListItem/ProductListItem'

import { SectionType } from '../GlobalSearchTypes'
import SearchResultItem from '../SearchResultItem'

export interface GlobalSearchResultsData {
  resultItems?: GlobalSearchResultsItem[]
  productList?: GlobalSearchResultsProduct[]
  totalElementCount: number
  numberOfResults: number
  hasMore?: boolean
}

export interface GlobalSearchResultsItem {
  link: string
  title: string
  description?: string
  image?: {
    originalImageUrl: string
    altText: string
  }
  ariaLabel: string
  formattedPublicationDate?: string
}

export interface GlobalSearchResultsProduct {
  name: string
  imageUrl?: string
  id: string
  description: string
  colorVariants: ColorVariant[]
  productVariantLabel?: string
  hasMultipleVariants?: boolean
}

interface GlobalSearchResultsProperties {
  type?: SectionType
  baseUrlTemplate?: string
  division?: string
  searchTerm: string
  data: GlobalSearchResultsData
  productVariantLabel: string
}
const GlobalSearchResults = ({
  data,
  type,
  baseUrlTemplate,
  division,
  searchTerm,
  productVariantLabel,
}: GlobalSearchResultsProperties): JSX.Element => {
  const triggerAnalytics = (position: number) => {
    try {
      window?.adobeDataLayer?.push({
        event: 'search result clicked',
        eventInfo: {
          reference: 'search',
          division: division,
          type: type,
          position: position,
          searchKeyword: searchTerm,
        },
      })
    } catch (error) {
      console.error('xxx pushToAdobeDataLayer', error)
    }
  }
  switch (type) {
    case 'DOCUMENTS':
      return <DownloadList items={data.resultItems} onDownload={triggerAnalytics} />
    case 'PCM_PRODUCTS':
      return (
        <ul className="cmp-global-search-results__products">
          {data.productList?.map((item, index) => (
            <ProductListItem
              key={item.id}
              title={item.name}
              ariaLabel={item.name}
              link={baseUrlTemplate?.replace(':productId', item.id) || ''}
              image={
                item?.imageUrl ||
                '/etc.clientlibs/franke-aem/clientlibs/clientlib-site/resources/images/placeholder_alta.png'
              }
              colors={item.colorVariants}
              onClick={() => triggerAnalytics(index)}
              productVariantLabel={productVariantLabel}
              hasMultipleVariants={item?.hasMultipleVariants}
            />
          ))}
        </ul>
      )
    case 'PAGES':
    case 'BLOG_ARTICLES':
    case 'PRODUCT_PAGES':
      return (
        <>
          {data.resultItems?.map((item, index) => (
            <SearchResultItem
              ariaLabel={item.ariaLabel}
              key={item.link}
              title={item.title}
              description={item.description}
              image={item.image}
              link={item.link}
              formatedPublicationDate={item.formattedPublicationDate}
              onClick={() => triggerAnalytics(index)}
            ></SearchResultItem>
          ))}
        </>
      )
    default:
      return <>Error, unknown search type</>
  }
}

export default GlobalSearchResults
