import React from 'react'
import AsyncCallResult from '@components/AsyncCallResult'
import { searchUrlParameters } from '@components/CoffeeMachineFinder/constants'
import LoadingIndicator from '@components/GlobalSearch/LoadingIndicator'
import { useGET } from '@hooks/fetch'
import { getQueryParameters } from '@utils/url'

import { CoffeeMachineFinderQueryParameters, syncCoffeeMachineFinderQueryParameter } from '../CoffeeMachineFinderHooks'
import {
  CoffeeMachineFinderModal,
  CoffeeMachineFinderTexts,
  CoffeeMachineProduct,
  QuestionnaireConfig,
} from '../CoffeeMachineFinderTypes'

import { CoffeeMachineFinderResultsSuccess } from './CoffeeMachineFinderResultsSuccess'

function getSelectedUserOptions(selectedOptions: { [optionId: string]: boolean }): string[] {
  return Object.keys(selectedOptions).filter((option) => selectedOptions[option])
}

const CoffeeMachineFinderResults: React.FC<{
  onRestart: () => void
  selectedAnswersOptions: { [optionId: string]: boolean }
  selectedWeightsOptions: { [optionId: string]: boolean }
  config: QuestionnaireConfig
  texts: CoffeeMachineFinderTexts
  modal?: CoffeeMachineFinderModal
  resultsEndpoint: { url: string; pdfExportUrl: string }
}> = ({ resultsEndpoint, selectedAnswersOptions, selectedWeightsOptions, texts, modal, config, onRestart }) => {
  const queryParameters = React.useMemo(
    () => ({
      [searchUrlParameters.ANSWERS]: getSelectedUserOptions(selectedAnswersOptions),
      [searchUrlParameters.WEIGHTS]: getSelectedUserOptions(selectedWeightsOptions),
    }),
    [config, selectedAnswersOptions, selectedWeightsOptions],
  )

  const [resultsState, call] = useGET<CoffeeMachineProduct[]>({
    url: resultsEndpoint.url,
    queryParameters,
  })

  React.useEffect(() => {
    if (Object.values(queryParameters).length > 0) {
      call()
    }
  }, [queryParameters, call])

  const { tab } = getQueryParameters<CoffeeMachineFinderQueryParameters>()
  const [selectedTab, setSelectedTab] = React.useState(Number.parseInt(tab || '0') || 0)

  function selectTab(tab: number) {
    syncCoffeeMachineFinderQueryParameter('tab', tab.toString())
    setSelectedTab(tab)
  }

  return (
    <div className="coffee-machine-finder__results">
      <AsyncCallResult
        state={resultsState}
        renderLoading={() => (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              width: '100vw',
              height: '50vh',
            }}
          >
            <LoadingIndicator />
          </div>
        )}
        renderSuccess={(results) => (
          <CoffeeMachineFinderResultsSuccess
            onRestart={onRestart}
            onTabSelect={selectTab}
            results={results}
            selectedTab={selectedTab}
            texts={texts}
            modal={modal}
          />
        )}
        renderError={(error, response) => (
          <>
            Error {response?.status} {JSON.stringify(error)}
          </>
        )}
      />
    </div>
  )
}

export default CoffeeMachineFinderResults
