import * as React from 'react'
import { cls } from '@utils/classnames'

const Accordion: React.FC<{
  children?: JSX.Element
  isOpen: boolean
  horizontal?: boolean
  animateOnCreation?: boolean
  onAnimationEnd?: React.TransitionEventHandler<HTMLDivElement>
}> = ({ isOpen, children, onAnimationEnd, horizontal, animateOnCreation }) => {
  const accordion = React.useRef<HTMLDivElement>(null)
  const [waitForAnimation, setWaitForAnimation] = React.useState(!animateOnCreation)
  const [accordionSize, setAccordionSize] = React.useState(0)

  React.useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        // this will be only 1 but clean code is beautiful :D
        setAccordionSize(
          horizontal ? entry.target.getBoundingClientRect().width : entry.target.getBoundingClientRect().height,
        )
      }
    })
    // NOTE: useEffect intrinsecally guarantees that refs created with useRef will be there (empty array as dependencies)
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    resizeObserver.observe(accordion!.current!)
    if (animateOnCreation) {
      setTimeout(() => {
        setWaitForAnimation(true)
      }, 1)
    }

    return () => {
      resizeObserver.disconnect()
    }
  }, [])

  function raiseAnimationEnd(event: React.TransitionEvent<HTMLDivElement>) {
    if ((event.target as HTMLDivElement).classList.contains('cmp-react-accordion') && onAnimationEnd) {
      onAnimationEnd(event)
    }
  }

  return (
    <div
      className={cls({
        'cmp-react-accordion': true,
        'cmp-react-accordion--is-open': isOpen && waitForAnimation,
        'cmp-react-accordion--is-horizontal': horizontal,
      })}
      style={{
        maxHeight: horizontal ? undefined : isOpen ? accordionSize : 0,
        maxWidth: horizontal ? (isOpen ? accordionSize : 0) : undefined,
        overflow: isOpen ? undefined : 'hidden',
      }}
      onTransitionEnd={raiseAnimationEnd}
    >
      <div ref={accordion}>{children}</div>
    </div>
  )
}

export default Accordion
