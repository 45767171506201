export function getLocalStorageData(keyName: string, defaultValue: unknown) {
  let currentValue: unknown

  try {
    currentValue = JSON.parse(localStorage.getItem(keyName) || String(defaultValue))
  } catch {
    currentValue = defaultValue
  }

  return currentValue
}
