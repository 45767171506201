import React, { useEffect } from 'react'
import { registerComponent } from 'react-register-dom'
import NoResults from '@components/NoResults'
import ErrorWrapper from '@components/UI/ErrorWrapper'

import { useProductExplorerLogic } from './ProductExplorerHooks'
import ProductExplorerLayout from './ProductExplorerLayout'
import { ProductExplorerConfig, ProductSearchResults } from './ProductExplorerTypes'

interface ProductExplorerProperties {
  jsonData: string
}

const ProductExplorer: React.FC<ProductExplorerProperties> = ({ jsonData }) => {
  const config: ProductExplorerConfig = React.useMemo(() => JSON.parse(jsonData), [jsonData])

  const {
    currentPage,
    endpointState,
    activeFilters,
    textSearch,
    handlePageChange,
    handleOptionsChange,
    handleFilterReset,
    setTextSearch,
    pageSize,
  } = useProductExplorerLogic(config)

  const hasData = endpointState.status === 'SUCCESS'
  const hasFilters = hasData || (endpointState.status === 'PENDING' && endpointState.data)
  const hasErrors = endpointState.status === 'ERROR'

  useEffect(() => {
    if (endpointState.status === 'SUCCESS' && window.adobeDataLayer) {
      window.adobeDataLayer.push({
        event: 'products loaded',
        products: {
          filterApplied: Object.keys(activeFilters)
            .map((activeFilterId) => {
              const filterObject = endpointState.data.filters.find((filter) => filter.id == activeFilterId)
              if ([...activeFilters[activeFilterId]].length > 0) {
                return [...activeFilters[activeFilterId]]
                  .map((activeFilterValue) => {
                    return filterObject
                      ? `${filterObject.label}:${
                          filterObject.options.find((option) => option.value == activeFilterValue)?.label
                        }`
                      : 'n/a'
                  })
                  .join(',')
              }
            })
            .join(','),
          productsDisplayed: endpointState.data.productList.length > 0 ? endpointState.data.productList.length : 'zero',
          pageType: 'plp',
          list: [
            ...endpointState.data.productList.map((item, index) => ({
              position: index,
              id: item.id,
              name: item.name,
              family: '',
              pagination: currentPage,
              displayedAs: '',
            })),
          ],
        },
      })
    }
  }, [endpointState])

  if (hasErrors) {
    return (
      <NoResults title={config.texts.errorApiTitle} description={config.texts.errorApiDescription} isError hasSpacing />
    )
  }

  return (
    <ErrorWrapper>
      <ProductExplorerLayout
        texts={config.texts}
        currentPage={currentPage}
        activeFilters={activeFilters}
        filtersConfig={hasFilters ? (endpointState.data as ProductSearchResults).filters : undefined}
        maxDisplayedElementCount={7}
        totalProductItems={hasData ? endpointState.data.totalElementCount : undefined}
        productList={hasData ? endpointState.data.productList : []}
        onOptionsChange={handleOptionsChange}
        onPageChange={handlePageChange}
        onResetAllFilters={handleFilterReset}
        onTextSearchChange={setTextSearch}
        textSearch={textSearch}
        textSearchLabel={config.texts.textSearchLabel}
        pageSize={pageSize}
        productBaseUrl={config.baseProductUrl}
        isPending={endpointState.status === 'PENDING' || endpointState.status === 'NEVER_EXECUTED'}
      />
    </ErrorWrapper>
  )
}

registerComponent('ProductExplorer', ProductExplorer)

export default ProductExplorer
