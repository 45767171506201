/* eslint-disable sonarjs/cognitive-complexity, unicorn/consistent-function-scoping */

;(function () {
  'use strict'

  function onDocumentReady() {
    const galleries = document.querySelectorAll('.cmp-image-gallery')

    for (const gallery of galleries) {
      const slides = gallery.querySelectorAll('.cmp-image-gallery__slide')
      const sliderContainer = gallery.querySelector('.cmp-image-gallery__slides')
      const previousButton = gallery.querySelector('.cmp-image-gallery__navigation-button--previous')
      const nextButton = gallery.querySelector('.cmp-image-gallery__navigation-button--next')
      let currentSlide = 0

      function updateSlidePosition() {
        const slideWidth = slides[currentSlide].getBoundingClientRect().width
        const slideMarginRight = Number.parseFloat(window.getComputedStyle(slides[currentSlide]).marginRight) || 0
        const offset = -currentSlide * slideWidth - currentSlide * slideMarginRight
        sliderContainer.style.transform = `translateX(${offset}px)`
      }

      function updateActiveClass() {
        for (const [index, slide] of slides.entries()) {
          slide.classList.toggle('cmp-image-gallery__slide--active', index === currentSlide)
          slide.setAttribute('aria-hidden', index !== currentSlide)
          slide.tabIndex = index === currentSlide ? 0 : -1
        }
        sliderContainer.style.height = `${slides[currentSlide].clientHeight}px`
      }

      function goToSlide(index) {
        currentSlide = index
        updateSlidePosition()
        updateActiveClass()
        updateNavigationState()
      }

      function updateNavigationState() {
        previousButton.disabled = currentSlide === 0
        nextButton.disabled = currentSlide === slides.length - 1
      }

      function handleKeyDown(event) {
        if (event.key === 'ArrowLeft') {
          goToSlide(Math.max(0, currentSlide - 1))
        } else if (event.key === 'ArrowRight') {
          goToSlide(Math.min(slides.length - 1, currentSlide + 1))
        }
      }

      previousButton.addEventListener('click', () => {
        goToSlide(Math.max(0, currentSlide - 1))
      })

      nextButton.addEventListener('click', () => {
        goToSlide(Math.min(slides.length - 1, currentSlide + 1))
      })

      for (const [index, slide] of slides.entries()) {
        slide.addEventListener('click', () => {
          goToSlide(index)
        })
      }

      document.addEventListener('keydown', handleKeyDown)

      updateActiveClass()
      updateNavigationState()
    }
  }

  if (document.readyState !== 'loading') {
    onDocumentReady()
  } else {
    document.addEventListener('DOMContentLoaded', onDocumentReady)
  }
})()
