/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext } from 'react'
import { HeaderContext } from '@components/Header/HeaderContext'
import {
  NavigationProductCategoriesList,
  NavigationSubItemTeaser,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from '@components/Header/Navigation/Navigation.d.ts'

import NavigationProductList from '../NavigationProductList'
import NavigationProductTeaser from '../NavigationProductTeaser'
interface NavigationProductWrapperProperties {
  interactiveIconGridData: NavigationProductCategoriesList
  backButtonLabel: string
  teaserData: NavigationSubItemTeaser
}

function extractTeaserData(teaserData: NavigationSubItemTeaser): NavigationSubItemTeaser {
  return {
    navigationTeaserLink: teaserData?.navigationTeaserLink,
    navigationTeaserTitle: teaserData?.navigationTeaserTitle,
    navigationTeaserLinkLabel: teaserData?.navigationTeaserLinkLabel,
    navigationTeaserImage: {
      originalImageUrl: teaserData?.navigationTeaserImage?.originalImageUrl || '',
      imageUrl: teaserData?.navigationTeaserImage?.imageUrl || '',
      altText: teaserData?.navigationTeaserImage?.altText || '',
    },
  }
}

const NavigationProductWrapper: React.FC<NavigationProductWrapperProperties> = ({
  interactiveIconGridData,
  backButtonLabel,
  teaserData,
}) => {
  const { setTopNavigationData, setIsSubNavigationOpenOnMobile } = useContext(HeaderContext)

  function handleClick() {
    setTopNavigationData(null)
    const subNavElement = document.querySelector('.cmp-navigation-wrapper')
    subNavElement?.classList.remove('cmp-navigation-wrapper--is-opened')
    setIsSubNavigationOpenOnMobile(false)
  }

  return !backButtonLabel && !interactiveIconGridData && !teaserData ? null : (
    <div className="cmp-navigation-product-wrapper">
      <div tabIndex={0} role="button" className="cmp-navigation-product-wrapper__back-button" onClick={handleClick}>
        <i className="ri-arrow-left-line cmp-navigation-product-wrapper__icon"></i>
        <span className="cmp-navigation-product-wrapper__back-button-text">{backButtonLabel}</span>
      </div>

      <NavigationProductTeaser {...extractTeaserData(teaserData)} />
      <NavigationProductList
        items={interactiveIconGridData?.[':items']}
        itemsOrder={interactiveIconGridData?.[':itemsOrder']}
      />
    </div>
  )
}

export default NavigationProductWrapper
