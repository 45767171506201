import React from 'react'

import DateTimeDisplay from './DateTimeDisplay'

interface CounterProperties {
  days: number
  hours: number
  minutes: number
  seconds: number
  daysLabel: string
  hoursLabel: string
  minutesLabel: string
  secondsLabel: string
}
const Counter: React.FC<CounterProperties> = ({
  days,
  hours,
  minutes,
  seconds,
  daysLabel,
  hoursLabel,
  minutesLabel,
  secondsLabel,
}: CounterProperties) => {
  return (
    <div className="cmp-countdown-timer__wrapper">
      <DateTimeDisplay value={days} type={daysLabel} />

      <DateTimeDisplay value={hours} type={hoursLabel} />

      <DateTimeDisplay value={minutes} type={minutesLabel} />

      <DateTimeDisplay value={seconds} type={secondsLabel} />
    </div>
  )
}

export default Counter
