import React, { useRef } from 'react'
import LoadingIndicator from '@components/GlobalSearch/LoadingIndicator'
import ProductList from '@components/ProductExplorer/ProductList'

import PageCounter from '../PageCounter'
import ProductExplorerFilterList from '../ProductExplorerFilterList'
import { FilterConfig, ProductExplorerTexts, ProductSnapshot } from '../ProductExplorerTypes'

interface ProductExplorerLayoutProperties {
  totalProductItems?: number
  pageSize: number
  currentPage: number
  productList: ProductSnapshot[]
  maxDisplayedElementCount: number
  onPageChange: (nextPage: number) => void
  filtersConfig?: FilterConfig[]
  activeFilters: Record<string, Set<string>>
  productBaseUrl: string
  texts: ProductExplorerTexts
  isPending: boolean
  onOptionsChange: (filterId: string, newValue: Set<string>) => void
  onResetAllFilters: () => void
  onTextSearchChange: React.Dispatch<React.SetStateAction<string>>
  textSearch: string
  textSearchLabel?: string
}

function productsResultText(text: string, totalProductItems?: number) {
  let totalProductsFoundText = ''
  if (text?.includes('{number}')) {
    totalProductsFoundText = text.replace(
      '{number}',
      `<span class="cmp-product-explorer-layout__product-count">${totalProductItems?.toString() || 'n/a'}</span>`,
    )
  }
  return (
    <p
      className="cmp-product-explorer-layout__total-count"
      dangerouslySetInnerHTML={{ __html: totalProductsFoundText }}
    ></p>
  )
}

const ProductExplorerLayout: React.FC<ProductExplorerLayoutProperties> = ({
  productList,
  currentPage,
  pageSize,
  totalProductItems,
  maxDisplayedElementCount,
  activeFilters,
  filtersConfig,
  productBaseUrl,
  texts,
  isPending,
  onPageChange,
  onOptionsChange,
  onResetAllFilters,
  onTextSearchChange,
  textSearch,
  textSearchLabel,
}: ProductExplorerLayoutProperties) => {
  const explorerReference = useRef(null)

  return (
    <div className="cmp-product-explorer-layout" ref={explorerReference}>
      <h1 className="cmp-product-explorer-layout__title">{texts.sectionTitle}</h1>
      {texts?.sectionLeadText && <h2 className="cmp-product-explorer-layout__lead-text">{texts?.sectionLeadText}</h2>}
      {isPending && !filtersConfig ? (
        <LoadingIndicator />
      ) : (
        <>
          <div className="cmp-product-explorer-layout__header">
            <ProductExplorerFilterList
              activeFilters={activeFilters}
              filtersConfig={filtersConfig || []}
              applyLabel={texts.applyFilter}
              resetLabel={texts.resetFilter}
              onOptionsChange={onOptionsChange}
              onTextSearchChange={onTextSearchChange}
              textSearch={textSearch}
              textSearchLabel={textSearchLabel}
            />
            <button
              className="cmp-product-explorer-layout__reset-all"
              aria-label={texts.resetFiltersAria}
              onClick={onResetAllFilters}
            >
              <i className="ri-arrow-go-back-line" />
              {texts.resetFilters}
            </button>
          </div>
          {isPending ? (
            <LoadingIndicator />
          ) : (
            <>
              {(totalProductItems || 0) > 0 ? (
                productsResultText(texts.singularProductFound, totalProductItems)
              ) : (
                <span className="cmp-product-explorer-layout__total-count-label">
                  <div>{texts.noProductsFoundTitle}</div>
                  <div>{texts.noProductsFoundDescription}</div>
                </span>
              )}
              {(totalProductItems || 0) > 1 ? productsResultText(texts.pluralProductsFound, totalProductItems) : null}

              <ProductList products={productList} productBaseUrl={productBaseUrl} texts={texts} />
            </>
          )}
          <PageCounter
            currentPage={currentPage}
            maxDisplayedElementCount={maxDisplayedElementCount}
            pageSize={pageSize}
            totalElements={totalProductItems || 0}
            onPageChange={onPageChange}
            reference={explorerReference}
          />
        </>
      )}
    </div>
  )
}

export default ProductExplorerLayout
