// eslint-disable-next-line sonarjs/cognitive-complexity
;(function () {
  'use strict'

  function onDocumentReady() {
    const showHideValueSelector = '.cmp-form-options select'
    const selects = document.querySelectorAll(showHideValueSelector)
    if (!Boolean(selects) || selects.length <= 0) {
      return
    }

    function applyShowHide(select) {
      const option = select[select.selectedIndex]
      const showHideValue = option.dataset.showHideValue
      if (Boolean(showHideValue)) {
        const elementsToShow = document.querySelectorAll("[data-show-hide-target='" + showHideValue + "']")
        for (const elementToShow of elementsToShow) {
          elementToShow.removeAttribute('hidden')
        }
        const elementsToHide = document.querySelectorAll(
          "[data-show-hide-target]:not([data-show-hide-target='" + showHideValue + "']",
        )
        for (const elementToHide of elementsToHide) {
          elementToHide.setAttribute('hidden', 'true')
          // reset select to first option always
          elementToHide.parentElement.selectedIndex = 0
        }
      }
    }

    for (const select of selects) {
      select.addEventListener('change', () => {
        applyShowHide(select)
      })
    }
  }

  if (document.readyState !== 'loading') {
    onDocumentReady()
  } else {
    document.addEventListener('DOMContentLoaded', onDocumentReady)
  }
})()
