import React, { useState } from 'react'
import { registerComponent } from 'react-register-dom'
import AsyncCallResult from '@components/AsyncCallResult'
import { AEMLinkProperties } from '@components/Form/FormTypes'
import LoadingIndicator from '@components/GlobalSearch/LoadingIndicator'
import NoResults from '@components/NoResults'
import { StoreEntityCSV } from '@components/StoreLocatorCSV/StoreEntityCSV'
import ErrorWrapper from '@components/UI/ErrorWrapper'
import { useAutoGET } from '@hooks/fetch'

interface StoreLocatorJsonData {
  id: string
  salesPartnerLabel: string
  servicePartnerLabel: string
  showroomLabel: string
  appointmentLabel?: string
  dealersDataEndpointUrl: string
  componentTitle?: string
  searchLabel?: string
  searchPlaceholder?: string
  noResultsLabel?: string
  errorMessageTitle: string
  errorMessageDescription: string
  fallbackLink: AEMLinkProperties
  fallbackLinkLabel: string
}

type StoreLocator = {
  zipCode: string
  dealers: Dealer[]
}

type Dealer = {
  category: string
  name: string
  street: string
  location: string
  phone: string
  email: string
}

interface DealersEndpointResponse {
  zipcodeDealers: StoreLocator[]
  categoryLabelsMap: Record<string, string>
}

const StoreLocatorCSV: React.FC<{ jsonData }> = ({ jsonData }) => {
  const storeLocatorData = JSON.parse(jsonData) as StoreLocatorJsonData
  const [searchTerm, setSearchTerm] = useState('')

  const [dealersState] = useAutoGET<DealersEndpointResponse>(storeLocatorData.dealersDataEndpointUrl)
  const [activeZip, setActiveZip] = useState<StoreLocator | null>(null)
  const [displayNoResults, setDisplayResultsLabel] = useState(false)

  const handleSearch = (event) => {
    event.preventDefault()
    if (dealersState.status === 'SUCCESS') {
      setActiveZip(dealersState.data.zipcodeDealers.find((dealer) => dealer.zipCode === searchTerm) || null)
      setDisplayResultsLabel(!!searchTerm)
    }
  }

  return (
    <div className="cmp-store-locator__container">
      <ErrorWrapper>
        <AsyncCallResult
          state={dealersState}
          renderLoading={() => <LoadingIndicator />}
          renderSuccess={(data) => (
            <div className="cmp-store-locator">
              {storeLocatorData.componentTitle && (
                <h2 className="cmp-store-locator__title">{storeLocatorData.componentTitle}</h2>
              )}
              <div className="cmp-store-locator__search">
                <label className="cmp-store-locator__search-label">{storeLocatorData.searchLabel}</label>
                <button onClick={handleSearch} className="cmp-store-locator__search-button">
                  <i className="ri-arrow-right-line" />
                </button>
                <input
                  type="text"
                  placeholder={storeLocatorData.searchPlaceholder}
                  value={searchTerm}
                  className="cmp-store-locator__search-input"
                  onChange={(event) => setSearchTerm(event.target.value)}
                  onKeyDown={(event) => (event.key === 'Enter' ? handleSearch(event) : '')}
                />
              </div>
              <div className="cmp-store-locator__results">
                {activeZip
                  ? activeZip.dealers.map((dealer: Dealer) => (
                      <StoreEntityCSV
                        title={storeLocatorData[data.categoryLabelsMap[dealer.category] || ''] || ''}
                        name={dealer.name}
                        street={dealer.street}
                        location={dealer.location}
                        phone={dealer.phone}
                        email={dealer.email}
                        appointmentLabel={dealer.category === 'category_3' ? storeLocatorData.appointmentLabel : ''}
                        fallbackLink={storeLocatorData.fallbackLink}
                        fallbackLinkLabel={storeLocatorData.fallbackLinkLabel}
                      />
                    ))
                  : displayNoResults && <p>{storeLocatorData.noResultsLabel}</p>}
              </div>
            </div>
          )}
          renderError={() => {
            return (
              <NoResults
                title={storeLocatorData.errorMessageTitle}
                description={storeLocatorData.errorMessageDescription}
                isError
                hasSpacing
              />
            )
          }}
        />
      </ErrorWrapper>
    </div>
  )
}

registerComponent('StoreLocatorCSV', StoreLocatorCSV)

export default StoreLocatorCSV
