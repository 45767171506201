import React from 'react'
import { registerComponent } from 'react-register-dom'
import useModal from '@hooks/useModal'
import { cls } from '@utils/classnames'
import { isMobile, useWindowResize } from '@utils/mediaQueries'

import { ProductImage } from '../PdpTypes'

import { ProductCarouselZoomModal } from './ProductCarouselZoomModal'

interface ProductCarouselConfig {
  galleryImages: ProductImage[]
  galleryAccessibilityLabelButtonUp: string
  galleryAccessibilityLabelButtonDown: string
  galleryMissingImageAltText: string
  galleryModalLabel: string
  galleryZoomButtonLabel: string
}

interface ProductCarouselProperties {
  jsonData: string
}

const MIN_SWIPE_DISTANCE = 50

const ProductCarousel: React.FC<ProductCarouselProperties> = ({ jsonData }) => {
  const config: ProductCarouselConfig = React.useMemo(() => {
    return JSON.parse(jsonData)
  }, [jsonData])

  const thumbnailContainerReference = React.useRef<HTMLDivElement>(null)
  const [selectedImageIndex, setSelectedImageIndex] = React.useState(0)
  const [showTopScroller, setShowTopScroller] = React.useState(false)
  const [showBottomScroller, setShowBottomScroller] = React.useState(false)
  const [touchStart, setTouchStart] = React.useState<number | null>(null)
  const [touchEnd, setTouchEnd] = React.useState<number | null>(null)
  const [zoomOut, setZoomOut] = React.useState(true)
  const hasNext = selectedImageIndex < config?.galleryImages?.length - 1
  const hasPrevious = selectedImageIndex > 0
  const isMobileScreen = useWindowResize(isMobile)
  const { isShowing, toggleModal } = useModal()

  function handleNextClick() {
    setZoomOut(true)
    if (hasNext) {
      setSelectedImageIndex(selectedImageIndex + 1)
    } else {
      setSelectedImageIndex(0)
    }
  }

  function handlePreviousClick() {
    setZoomOut(true)
    if (hasPrevious) {
      setSelectedImageIndex(selectedImageIndex - 1)
    } else {
      setSelectedImageIndex(config?.galleryImages?.length - 1)
    }
  }

  const onTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
    setZoomOut(!zoomOut)
    setTouchEnd(null)
    setTouchStart(event.targetTouches[0].clientX)
  }

  const onTouchMove = (event: React.TouchEvent<HTMLDivElement>) => setTouchEnd(event.targetTouches[0].clientX)

  const onTouchEnd = () => {
    setZoomOut(!zoomOut)
    if (!touchStart || !touchEnd) return
    const distance = touchStart - touchEnd
    const isLeftSwipe = distance > MIN_SWIPE_DISTANCE
    const isRightSwipe = distance < -MIN_SWIPE_DISTANCE
    if (isLeftSwipe) {
      handleNextClick()
    }
    if (isRightSwipe) {
      handlePreviousClick()
    }
  }

  const handleThumbnailKeyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>, index: number) => {
      if (event.key === ' ') {
        setSelectedImageIndex(index)
      }
    },
    [setSelectedImageIndex],
  )

  const setScrollersVisibility = React.useCallback(
    (scrollableContainer: HTMLDivElement) => {
      if (isMobileScreen) {
        const width = Math.round(scrollableContainer.getBoundingClientRect().width)
        const maxScroll = scrollableContainer.scrollWidth - width
        const currentScroll = scrollableContainer.scrollLeft
        setShowTopScroller(currentScroll > 0)
        setShowBottomScroller(currentScroll < maxScroll)
      } else {
        const height = Math.round(scrollableContainer.getBoundingClientRect().height)
        const maxScroll = scrollableContainer.scrollHeight - height
        const currentScroll = scrollableContainer.scrollTop
        setShowTopScroller(currentScroll > 0)
        setShowBottomScroller(currentScroll < maxScroll)
      }
    },
    [setShowTopScroller, setShowBottomScroller],
  )

  React.useEffect(() => {
    if (thumbnailContainerReference.current) {
      const height = thumbnailContainerReference.current.getBoundingClientRect().height
      const width = thumbnailContainerReference.current.getBoundingClientRect().width
      const scrollHeight = thumbnailContainerReference.current.scrollHeight
      const scrollWidth = thumbnailContainerReference.current.scrollWidth

      const isScrollOnMobile = scrollHeight > height && !isMobileScreen
      const isScrollOnDesktop = scrollWidth > width && isMobileScreen

      if (isScrollOnDesktop || isScrollOnMobile) {
        setScrollersVisibility(thumbnailContainerReference.current)
      }
    }
  }, [thumbnailContainerReference.current, setScrollersVisibility])

  function scrollToTop() {
    if (thumbnailContainerReference.current) {
      thumbnailContainerReference.current.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    }
  }
  function scrollToBottom() {
    if (thumbnailContainerReference.current) {
      const maxTopScroll = thumbnailContainerReference.current.scrollHeight
      const maxLeftSCroll = thumbnailContainerReference.current.scrollWidth
      thumbnailContainerReference.current.scrollTo({
        [isMobileScreen ? 'left' : 'top']: isMobileScreen ? maxLeftSCroll : maxTopScroll,
        behavior: 'smooth',
      })
    }
  }

  return (
    <div className="cmp-product-carousel">
      {config.galleryImages?.length > 0 && !isMobileScreen ? (
        <div className="cmp-product-carousel__thumbnail-layout">
          <div
            className="cmp-product-carousel__thumbnail-container"
            ref={thumbnailContainerReference}
            onScroll={(event) => setScrollersVisibility(event.currentTarget)}
          >
            {config.galleryImages.map((image, index) => (
              <div
                key={index}
                role="button"
                tabIndex={0}
                onKeyDown={(event) => handleThumbnailKeyDown(event, index)}
                onClick={() => setSelectedImageIndex(index)}
                className={cls({
                  'cmp-product-carousel__thumbnail': true,
                  'cmp-product-carousel__thumbnail--is-selected': selectedImageIndex === index,
                })}
              >
                <img
                  className="cmp-product-carousel__thumbnail-image"
                  alt={image.thumbnail.altText}
                  src={image.thumbnail.url}
                />
              </div>
            ))}
          </div>

          {showBottomScroller ? (
            <button
              className="cmp-product-carousel__scroller cmp-product-carousel__scroller--bottom"
              aria-label={config.galleryAccessibilityLabelButtonDown}
              onClick={scrollToBottom}
            >
              <i className="ri-arrow-down-line" />
              <i className="ri-arrow-right-line" />
            </button>
          ) : null}
          {showTopScroller ? (
            <button
              className="cmp-product-carousel__scroller cmp-product-carousel__scroller--top"
              aria-label={config.galleryAccessibilityLabelButtonUp}
              onClick={scrollToTop}
            >
              <i className="ri-arrow-up-line" />
              <i className="ri-arrow-left-line" />
            </button>
          ) : null}
        </div>
      ) : null}

      <ProductCarouselZoomModal
        isOpen={isShowing}
        modalLabel={config.galleryModalLabel}
        onModalClose={toggleModal}
        imageList={config.galleryImages}
        galleryAccessibilityLabelButtonDown={config.galleryAccessibilityLabelButtonDown}
        galleryAccessibilityLabelButtonUp={config.galleryAccessibilityLabelButtonUp}
        setSelectedImageIndex={setSelectedImageIndex}
        selectedImageIndex={selectedImageIndex}
      />

      <div className="cmp-product-carousel__selected-image-layout">
        {config.galleryImages?.length > 0 ? (
          config.galleryImages.map((image, index) => (
            <>
              <div
                className="cmp-product-carousel__selected-image-container"
                key={index}
                onTouchStart={onTouchStart}
                onTouchMove={onTouchMove}
                onTouchEnd={onTouchEnd}
              >
                <button
                  className="cmp-product-carousel__zoom-mode-button"
                  onClick={toggleModal}
                  aria-label={config.galleryZoomButtonLabel}
                >
                  <img
                    className={cls({
                      'cmp-product-carousel__selected-image': true,
                      'cmp-product-carousel__selected-image--is-selected': index === selectedImageIndex,
                    })}
                    src={image.primary.url}
                    alt={image.primary.altText}
                  />
                </button>
                <button
                  className="cmp-product-carousel__zoom"
                  aria-label={config.galleryZoomButtonLabel}
                  onClick={toggleModal}
                >
                  <i className="ri-fullscreen-line"></i>
                </button>
              </div>
            </>
          ))
        ) : (
          <div className="cmp-product-carousel__selected-image-container">
            <img
              className="cmp-product-carousel__selected-image cmp-product-carousel__selected-image--is-selected"
              src="/etc.clientlibs/franke-aem/clientlibs/clientlib-site/resources/images/placeholder_alta.png"
              alt={config.galleryMissingImageAltText}
            />
          </div>
        )}
      </div>

      {isMobileScreen && config?.galleryImages?.length > 1 ? (
        <>
          <button
            onClick={handleNextClick}
            className="cmp-product-carousel__scroller cmp-product-carousel__scroller--bottom"
            aria-label={config.galleryAccessibilityLabelButtonDown}
          >
            <i className="ri-arrow-down-line" />
            <i className="ri-arrow-right-line" />
          </button>
          <button
            onClick={handlePreviousClick}
            className="cmp-product-carousel__scroller cmp-product-carousel__scroller--top"
            aria-label={config.galleryAccessibilityLabelButtonUp}
          >
            <i className="ri-arrow-up-line" />
            <i className="ri-arrow-left-line" />
          </button>
          <div className="cmp-product-carousel__number-slides">
            {selectedImageIndex + 1} / {config.galleryImages?.length}
          </div>
        </>
      ) : null}
    </div>
  )
}

export default ProductCarousel

registerComponent('ProductCarousel', ProductCarousel)
