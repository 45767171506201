import { useMemo, useState } from 'react'
import { registerComponent } from 'react-register-dom'
import Accordion from '@components/UI/Accordion'
import Toast from '@components/UI/Toast'
import { cls } from '@utils/classnames'
import { isMq1, useWindowResize } from '@utils/mediaQueries'

type SocialIcon = 'facebook' | 'twitter' | 'linkedin' | 'whatsapp' | 'copy'

export interface ShareLinksProperties {
  id: string
  leadText?: string
  shareButtonAriaLabel?: string
  closeShareButtonAriaLabel?: string
  enabledSocialIcons: SocialIcon[]
  pagePublishUrl: string
  copiedToClipboardText: string
  shareLinkRelAttribute?: string
}

const socialIconToIconClassNameMapping: Record<SocialIcon, string> = {
  facebook: 'ri-facebook-circle-line',
  copy: 'ri-file-copy-line',
  linkedin: 'ri-linkedin-box-line',
  twitter: 'ri-twitter-line',
  whatsapp: 'ri-whatsapp-line',
}

const getLinkForSocialShareByIcon: Record<SocialIcon, (publishUrl: string) => string> = {
  facebook: (publishUrl: string) => {
    return `http://www.facebook.com/share.php?${encodeURIComponent(publishUrl)}`
  },
  copy: (publishUrl: string) => publishUrl,
  linkedin: (publishUrl: string) => {
    return `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(publishUrl)}`
  },
  twitter: (publishUrl: string) => {
    return `https://twitter.com/intent/tweet?text=${encodeURIComponent(publishUrl)}`
  },
  whatsapp: (publishUrl: string) => `https://wa.me?${encodeURIComponent(publishUrl)}`,
}

function pushShareEvent(platform: SocialIcon, url: string) {
  if (window.adobeDataLayer) {
    window.adobeDataLayer.push({
      event: 'clicked share',
      eventInfo: {
        reference: 'share',
        platform: platform,
        requestUrl: url,
      },
    })
  }
}

const ShareLinks: React.FC<{
  jsonData: string
}> = ({ jsonData }) => {
  const [notificationVisible, setNotificationVisible] = useState(false)
  const config = useMemo(() => JSON.parse(jsonData) as ShareLinksProperties, [jsonData])
  const [isAccordionOpen, setIsAccordionOpen] = useState(false)
  const forceAccordionOpen = !useWindowResize(isMq1)

  function toggleAccordion() {
    setIsAccordionOpen((open) => !open)
  }

  function copyShareLinkToClipboard() {
    navigator.clipboard.writeText(config.pagePublishUrl)
    pushShareEvent('copy', config.pagePublishUrl)
    setNotificationVisible(true)
  }

  function dismissNotification() {
    setNotificationVisible(false)
  }

  return (
    <div className="cmp-share-links">
      <p className="cmp-share-links__lead-text">{(forceAccordionOpen || !isAccordionOpen) && config.leadText}</p>
      <div className="cmp-share-links__icons-section">
        <Accordion isOpen={isAccordionOpen || forceAccordionOpen} horizontal={true}>
          <div className="cmp-share-links__icon-list">
            {config.enabledSocialIcons.map((icon) =>
              icon === 'copy' ? (
                <button
                  className="cmp-share-links__open-icon-list-icon-button"
                  onClick={copyShareLinkToClipboard}
                  key={icon}
                >
                  <i className={socialIconToIconClassNameMapping[icon]} />
                  <Toast isVisible={notificationVisible} onClose={dismissNotification} type="success">
                    {config.copiedToClipboardText}
                  </Toast>
                </button>
              ) : (
                <a
                  className="cmp-share-links__icon-link"
                  key={icon}
                  target="_blank"
                  onClick={() => pushShareEvent(icon, getLinkForSocialShareByIcon[icon](config.pagePublishUrl))}
                  href={getLinkForSocialShareByIcon[icon](config.pagePublishUrl)}
                  rel={config.shareLinkRelAttribute || ''}
                >
                  <i className={socialIconToIconClassNameMapping[icon]} />
                </a>
              ),
            )}
          </div>
        </Accordion>
        <button
          className="cmp-share-links__open-icon-list-button"
          onClick={toggleAccordion}
          aria-label={isAccordionOpen ? config.closeShareButtonAriaLabel : config.shareButtonAriaLabel}
        >
          <i
            className={cls({
              'ri-share-line': !isAccordionOpen,
              'ri-close-line': isAccordionOpen,
            })}
          />
        </button>
      </div>
    </div>
  )
}

registerComponent('ShareLinks', ShareLinks)

export default ShareLinks
