import React from 'react'
import { registerComponent } from 'react-register-dom'
import { useKeyPress } from '@hooks/useKeyPress'

const mediaInfoList = {
  'small-s': 'Small Viewport S (MQ1)',
  'small-m': 'Small Viewport M (MQ2)',
  'medium-l': 'Medium Viewport L (MQ3)',
  'large-xl': 'Large Viewport XL (MQ4)',
  'x-large': 'X-Large Viewport XXL (MQ5)',
}

const MediaInfo: React.FC = () => {
  const { isToggled } = useKeyPress()

  if (!isToggled) {
    return null
  }

  return (
    <>
      {Object.entries(mediaInfoList).map(([key, value]) => {
        return (
          <div className={`media-info media-info--${key}`} key={key}>
            {value}
          </div>
        )
      })}
    </>
  )
}

registerComponent('MediaInfo', MediaInfo)

export default MediaInfo
