import React from 'react'
interface CountdownTeaserButton {
  externalizedURL: string
  isTargetBlack: boolean
  htmlAttributes?: { href: string; rel: string }
}
interface CountdownTeaserProperties {
  title: string
  leadText: string
  description: string
  ctaLabel: string
  ctaLink: CountdownTeaserButton
}
const CountdownTeaser: React.FC<CountdownTeaserProperties> = ({
  title,
  leadText,
  description,
  ctaLabel,
  ctaLink,
}: CountdownTeaserProperties) => {
  return (
    <div className="cmp-countdown-teaser">
      <h4 className="cmp-countdown-teaser__title">{title}</h4>
      <div className="cmp-countdown-teaser__lead-text">{leadText}</div>
      <div className="cmp-countdown-teaser__description">{description}</div>
      <a
        className="cmp-countdown-teaser__button"
        href={ctaLink.externalizedURL}
        target={ctaLink.isTargetBlack ? '_blank' : ''}
        rel={ctaLink.htmlAttributes?.rel}
      >
        {ctaLabel}
      </a>
    </div>
  )
}

export default CountdownTeaser
